import {Stack} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import Banner from "components/Banner";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getStadiumLegendaryMatch} from "firestore/specificGetters/stadiumLegendaryMatches";
import {
	ContentElementPageCacheFirebaseDocumentUnion
} from "models/firestore/pageCache/article/ContentElementPageCacheFirebaseDocument";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export function ClubStadiumLegendaryMatch() {
	const {key} = useParams();

	const [title, setTitle] = useState<string>("");
	const [content, setContent] = useState<ContentElementPageCacheFirebaseDocumentUnion[]>([]);
	const [imageUrl, setImageUrl] = useState<string>("");
	const [image720x540url, setImage720x540url] = useState("");

	const isMobile = useIsMobileContext()

	const getDetails = () => {
		if (!key) return;

		getStadiumLegendaryMatch(key).then((payload) => {
			setTitle(payload.title);
			setContent(payload.content);
			setImageUrl(payload.image1440x680url);
			setImage720x540url(payload.image720x540url ?? "");
		})
	}

	useEffect(() => {
		getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key])

	return (
		<Stack>
			{(isMobile && image720x540url) ?
				<Banner title={title} imageUrl={image720x540url} desktopHeight={680}
				        sx={{aspectRatio: "720/540"}}/>
				:
				<Banner title={title} imageUrl={imageUrl} desktopHeight={680}/>
			}
			<ContentContainer content={content}/>
		</Stack>
	);
}
