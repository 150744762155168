import {Box} from "@mui/material";
import {ButtonTabNavigatorButtons, IButtonTabNavigatorTab} from "@nitra/nkmb-card-components";
import React from "react";

const tabs: IButtonTabNavigatorTab[] = [
	{label: "Info", path: "info"},
	{label: "Zgodovina", path: "stadiumHistory"},
	{label: "Legendarne tekme", path: "legendaryMatches"},
]

export function ClubStadiumTabNavigatorButtons() {
	return (
		<Box sx={{px: 2}}>
			<ButtonTabNavigatorButtons
				tabs={tabs}
				replaceLastPath={true}
			/>
		</Box>
	)
}
