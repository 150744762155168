import {Box, Divider, Grid, Stack} from "@mui/material";

import {useBreakpointContext} from "@nitra/nkmb-card-components";
import {
	NkmbIconDolzina,
	NkmbIconFaul,
	NkmbIconGol,
	NkmbIconGradnja,
	NkmbIconKapaciteta,
	NkmbIconLokacija,
	NkmbIconLv,
	NkmbIconReflektorji
} from "@nitra/nkmb-theme";
import {CardContainerTitle} from "components/CardContainerTitle";
import {InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {ClubStadiumTabNavigatorButtons} from "views/club/stadium/ClubStadiumTabNavigatorButtons";
import {ClubBanner} from "../../ClubBanner";
import {ClubStadiumInfoEmblem} from "./ClubStadiumInfoEmblem";
import {StadiumEntrances} from "./StadiumEntrances";
import {StadiumPrePurchaseTickets} from "./StadiumPrePurchaseTickets";
import {StadiumSchema} from "./StadiumSchema";
import {StadiumTicketBuySpots} from "./StadiumTicketBuySpots";

export function ClubStadiumInfo() {
	const breakpoint = useBreakpointContext()

	const [isMobile, setIsMobile] = React.useState(false)

	React.useEffect(() => {
		setIsMobile(breakpoint === "xs" || breakpoint === "sm")
	}, [breakpoint])

	return (
		<Stack>
			<ClubBanner
				url={"/images/club/banners/stadium-info.png"}
				mainText={"Ljudski vrt"}
				subText={"srce mojega mesta"}
			/>
			<ClubStadiumTabNavigatorButtons/>
			<InnerWidthContainer>
				<Stack sx={{width: "100%", pt: isMobile ? 2 : 0}}>
					<CardContainerTitle title={"Osnovni podatki"}/>
					<Grid container spacing={2} justifyContent={"space-between"}>
						<ClubStadiumInfoEmblem
							emblem={NkmbIconLv}
							upperText={"srce mojega mesta"}
							mainText={"Ljudski vrt"}
							lowerText={"kratko LV"}
						/>
						<ClubStadiumInfoEmblem emblem={NkmbIconLokacija} upperText={"naslov"}
						                       mainText={"Mladinska ulica 29"}
						                       lowerText={"2000 Maribor"}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconKapaciteta} upperText={"kapaciteta"} mainText={"11.709"}
						                       lowerText={"sedežev "}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconDolzina} upperText={"dimenzije igrišča"}
						                       mainText={"105 x 68"}
						                       lowerText={"metrov"}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconFaul} upperText={"prva tekma"} mainText={"25.6.1961"}
						                       lowerText={"Maribor - Mladost Zabok 3:2"}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconGol} upperText={"prvi zadetek"} mainText={"Oto Blaznik"}
						                       lowerText={"25.06.1961"}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconGradnja} upperText={"zgrajen"} mainText={"1961"}
						                       lowerText={"obnovljen 2021"}/>
						<ClubStadiumInfoEmblem emblem={NkmbIconReflektorji} upperText={"svetlost reflektorjev"}
						                       mainText={"1873"}
						                       lowerText={"lux"}
						                       isLast={true}
						/>
					</Grid>
					<CardContainerTitle sx={isMobile ? {pt: 8} : {}} title={"Shema LV"}/>
					<Grid container sx={{width: "100%"}} spacing={2}>
						<Grid item xs={12} md={6}>
							<StadiumSchema/>
						</Grid>
						<Grid item xs={12} md={6} lg={3} sx={isMobile ? {mt: 9} : {}}>
							<StadiumTicketBuySpots/>
							{isMobile && <Box sx={{py: 2}}><Divider/></Box>}
							{!isMobile && <StadiumPrePurchaseTickets/>}
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<StadiumEntrances/>
							{isMobile && <Box sx={{py: 2}}><Divider/></Box>}
							{isMobile && <StadiumPrePurchaseTickets/>}
						</Grid>
					</Grid>
				</Stack>
			</InnerWidthContainer>
		</Stack>
	)
}
