import {Stack, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

export function DesktopNextArticle(props: {
	data: {
		title: string,
		image600x360url: string,
		firestoreId: string
	}
}) {
	const {title, image600x360url, firestoreId} = props.data;

	const location = useLocation()

	return (
		<Link
			to={(location.pathname.indexOf("footballSchool") !== -1) ? `/footballSchool/news/${firestoreId}` : `../${firestoreId}`}
			style={{
				textDecoration: 'none',
				color: 'black'
			}}
		>
			<Stack direction={"row"} spacing={4} justifyContent={"center"}>
				<div
					style={{
						width: 288,
						height: 140,
						borderRadius: "8px",
						backgroundImage: `url(${image600x360url})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat'
					}}
				/>
				<Stack direction={"column"} spacing={2}>
					<Typography variant={"gumbL"}>Naslednji članek</Typography>
					<Typography variant={"h4"}>{title}</Typography>
				</Stack>
			</Stack>
		</Link>
	)
}