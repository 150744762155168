import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {Stack, Typography, useTheme} from "@mui/material";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function ClubEmblemDescriptionRow(props: {
	url: string,
	title: string,
	text: string,
}) {
	const {url, title, text} = props

	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()
	const theme = useTheme();

	if (isMobile) {
		return (
			<Stack direction={"column"} spacing={2}>
				<Stack direction={"row"} alignItems={"center"} spacing={4}>
					<img src={url} alt={title} style={{width: "auto", height: "60px"}}/>
					<Typography variant={"h5"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{title}</Typography>
				</Stack>
				<Typography>{text}</Typography>
			</Stack>
		)
	}

	return (
		<Stack direction={"row"}>
			<img src={url} alt={title} style={{width: "100px", height: "100px"}}/>
			<Stack direction={"column"} sx={{pl: 4}}>
				<Typography variant={"h4"}>{title}</Typography>
				<Typography>{text}</Typography>
			</Stack>
		</Stack>
	)
}
