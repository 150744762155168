import {ButtonTabNavigatorButtons, useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {TeamNavigationBarDesktop} from "./TeamNavigationBarDesktop";

export function TeamNavigationBar() {
	const isMobile = useIsMobileContext();

	const {pathname} = useLocation()

	const [currentTab, setCurrentTab] = React.useState<"players" | "staff">("players")

	useEffect(() => {
		if (pathname.includes("players")) setCurrentTab("players")
		if (pathname.includes("staff")) setCurrentTab("staff")
	}, [pathname])

	return isMobile ?
		<InnerWidthContainer sx={{pt: 2}}>
			<ButtonTabNavigatorButtons
				tabs={[{label: "Igralski kader", path: "players"}, {label: "Strokovni štab", path: "staff"}]}
			/>
		</InnerWidthContainer>
		:
		(<TeamNavigationBarDesktop currentTab={currentTab}/>)

}
