import {Box} from "@mui/material";
import {MatchListElementFirestoreDocument} from "@nitra/nkmb-card-components";
import {MatchVsColumn} from "components/Competitions/MatchVsColumn";
import {ClubLogoAndNameColumn} from "./ClubLogoAndNameColumn";


export function MatchContainerCenterColumn(props: {
	match: MatchListElementFirestoreDocument
}) {
	const {match} = props;
	const {homeTeamName, homeTeamLogoUrl} = match;
	const {awayTeamName, awayTeamLogoUrl} = match;

	return <Box sx={{
		maxWidth: "450px",
		width: "100%",
		height: "96px",
		gridTemplateColumns: "1fr 65px 1fr",
		display: "grid",
		alignItems: "center",
		justifyContent: "center",
	}}>
		<ClubLogoAndNameColumn
			clubName={homeTeamName}
			clubLogoUrl={homeTeamLogoUrl}
		/>
		<MatchVsColumn match={match}/>
		<ClubLogoAndNameColumn
			clubName={awayTeamName}
			clubLogoUrl={awayTeamLogoUrl}
		/>
	</Box>;
}
