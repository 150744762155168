import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function StadiumEntrance(props: {
	name: string,
	location: string,
	description: string,
}) {
	const {name, location, description} = props

	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Grid item xs={12}>
			<Stack direction={"row"} spacing={2} alignItems={"center"}>
				<Box sx={{width: 32, height: 32, borderRadius: "4px", backgroundColor: "primary.main"}}>
					<Typography variant={"h4"} sx={{
						color: "white", textAlign: "center", pt: "2px",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{name}</Typography>
				</Box>
				<Stack>
					<Typography variant={"bodyMBold"}>{location}</Typography>
					<Typography variant={"bodyM"}>{description}</Typography>
				</Stack>
			</Stack>
		</Grid>
	)
}
