import {Box, Button, Typography} from "@mui/material";

export const LoginDrawerComponent = () => {
	return (
		<Box sx={{p: 2}}>
			<Button variant="contained" color="primary" sx={{mx: 1, borderRadius: 16}}>
				<Typography variant="button">
					Prijava
				</Typography>
			</Button>
			<Button variant="outlined" color="primary" sx={{mx: 1, borderRadius: 16}}>
				<Typography variant="button">
					Registracija
				</Typography>
			</Button>
		</Box>
	)
}
