import {
	ArticleMatchCardFirestoreDocument,
	ArticleMatchGoalFirestoreDocument,
	ArticleMatchSubstitutionFirestoreDocument
} from "../../../../models";
import {
	NkmbIconCardRed,
	NkmbIconCardYellow,
	NkmbIconGolAg,
	NkmbIconPenal,
	NkmbIconPenalty1,
	NkmbIconRumRdBarva,
	NkmbIconSubstitutionInOut
} from "@nitra/nkmb-theme";
import {Box, Stack, Typography} from "@mui/material";

export function ArticleMatchEventLine(props: {
	event: ArticleMatchGoalFirestoreDocument | ArticleMatchCardFirestoreDocument | ArticleMatchSubstitutionFirestoreDocument
}) {
	const {event} = props

	// detect if event is goal, card or substitution
	if (event.hasOwnProperty("isYellow")) {
		const card = event as ArticleMatchCardFirestoreDocument

		const getIcon = () => {
			if (card.isRed) {
				return <NkmbIconCardRed/>
			} else if (card.isSecondYellow) {
				return <NkmbIconRumRdBarva/>
			} else {
				return <NkmbIconCardYellow/>
			}
		}

		return (
			<Stack direction={"row"} key={event.key} alignItems={"center"}>
				<Typography sx={{pr: "4px", width: "16px"}} variant={"bodyS"}>{card.minute}'</Typography>
				{getIcon()}
				<Typography sx={{pl: 1}} variant={"bodyS"}>{card.playerLastName}</Typography>
			</Stack>
		)
	} else if (event.hasOwnProperty("playerInKey")) {
		const substitution = event as ArticleMatchSubstitutionFirestoreDocument
		return (
			<Stack direction={"row"} key={event.key} alignItems={"center"} sx={{py: "3px"}}>
				<Typography sx={{pr: "4px", width: "16px"}} variant={"bodyS"}>{substitution.minute}'</Typography>
				<NkmbIconSubstitutionInOut/>
				<Stack sx={{pl: 1}}>
					<Typography variant={"bodyS"} sx={{color: "#676767"}}>{substitution.playerOutLastName}</Typography>
					<Typography variant={"bodyS"}>{substitution.playerInLastName}</Typography>
				</Stack>
			</Stack>
		)
	} else {
		const goal = event as ArticleMatchGoalFirestoreDocument

		const getIcon = () => {
			if (goal.isAutoGoal) {
				return <NkmbIconGolAg/>
			} else if (goal.isPenalty || goal.isAfterMatchPenalty) {
				return <NkmbIconPenalty1/>
			} else {
				return <NkmbIconPenal/>
			}
		}

		return (
			<Stack direction={"row"} key={event.key} alignItems={"center"}>
				{goal.isAfterMatchPenalty ?
					<Box sx={{pr: "px", width: "16px"}}/>
					:
					<Typography sx={{pr: "4px", width: "16px"}} variant={"bodyS"}>{goal.minute}'</Typography>
				}
				{getIcon()}
				<Typography sx={{pl: 1}} variant={"bodyS"}>{goal.playerLastName}</Typography>
			</Stack>
		)
	}
}