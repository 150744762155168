import {EStandingsMomentum} from "./EStandingsMomentum";
import {ArrowDropDown, ArrowDropUp, ArrowRight} from "@mui/icons-material";
import React from "react";

export function getIconFromMomentum(momentum: EStandingsMomentum) {
	switch (momentum) {
		case EStandingsMomentum.UP:
			return <ArrowDropUp/>
		case EStandingsMomentum.DOWN:
			return <ArrowDropDown/>
		case EStandingsMomentum.NEUTRAL:
			return <ArrowRight/>
	}
}
