import {Button, SxProps, Typography, useTheme} from "@mui/material";
import React from "react";

export function MobileStatisticsBlackBarButton(props: {
	label: string
	selected: boolean
	onClick: () => void
	sx?: SxProps
}) {
	const {label, selected, onClick} = props

	const theme = useTheme();

	return (
		<Button variant={"text"}
		        onClick={onClick}
		        sx={{
			        color: selected ? theme.palette.background.default : "#676767",
			        backgroundColor: theme.palette.text.primary,
			        borderRadius: 0,
			        minWidth: 0,
			        '&:hover': {
				        backgroundColor: theme.palette.text.primary,
			        },
			        display: "flex",
			        flexAlign: "flex-end",
			        ...props.sx,
		        }}>
			<Typography variant={"gumbS"}>{label}</Typography>
		</Button>
	)
}
