import {Stack, Typography} from "@mui/material";
import {ECompetitionType} from "@nitra/nkmb-card-components";
import {useCompetitionViewContext} from "views/competitions/common/CompetitionContext";

export function ScoreAssistCompetitionLogo() {
	const {scoreAssistsSelectedCompetition} = useCompetitionViewContext()

	const getLogo = (matchType: ECompetitionType) => {
		switch (matchType) {
			case ECompetitionType.FIRST_LEAGUE:
				return <img src={"/images/competition_logos/2022/SNL-lezeci.png"} alt={"SNL"} style={{height: "60px"}}/>
			case ECompetitionType.CUP:
				return <img src={"/images/competition_logos/2022/Pokal-lezeci.png"} alt={"Pokal"}
				            style={{height: "60px"}}/>
			case ECompetitionType.CONFERENCE_LEAGUE:
			case ECompetitionType.EUROPA_LEAGUE:
			case ECompetitionType.CHAMPIONS_LEAGUE:
				return <Typography variant={"h6"}>Europske tekme</Typography>
			case ECompetitionType.FRIENDLY:
				return <Typography variant={"h6"}>Pripravljalne tekme</Typography>
		}
	}

	return (
		<Stack direction={"row"}
		       justifyContent={"space-around"}
		       alignItems={"center"}
		       sx={{height: "64px"}}
		>
			{getLogo(scoreAssistsSelectedCompetition)}
		</Stack>
	);

}
