import {List, useTheme} from "@mui/material";
import {ClubStatisticsMatchFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {
	MobileClubStatisticsExpendableListItemButton
} from "views/club/statistics/mobile/club/MobileClubStatisticsExpendableListItemButton";

export function MobileClubStatisticsMatchList(props: {
	matches: ClubStatisticsMatchFirestoreDocument[],
	season: number
}) {
	const {matches, season} = props;
	const [selectedKey, setSelectedKey] = useState("")

	const theme = useTheme();

	useEffect(() => {
		if (matches.length > 0) {
			setSelectedKey(matches[0].key)
		} else {
			setSelectedKey("")
		}
	}, [matches]);

	return (
		<List sx={{backgroundColor: theme.palette.background.paper}}>
			{matches.map(match =>
				<MobileClubStatisticsExpendableListItemButton
					key={match.key}
					_key={match.key}

					match={match}
					isOpen={selectedKey === match.key}
					calledCallback={(key) => setSelectedKey(key)}

					season={season}
				/>
			)}
		</List>
	)
}
