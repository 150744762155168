import {getFirstTeam} from "firestore/BaseFirestore";
import {Group, Person} from "models";
import React, {useEffect} from "react";
import {PersonsGrid} from "./peopleGrid/PersonsGrid";

export function TeamSquadView() {
	const [groups, setGroups] = React.useState<Group<Person>[]>([])

	useEffect(() => {
		getFirstTeam().then(
			(data) => {
				setGroups([
					{
						name: "Golmani",
						items: data.goalkeepers.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))
					},
					{
						name: "Obrambni igralci",
						items: data.defenders.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))
					},
					{
						name: "Vezni igralci",
						items: data.midfielders.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))
					},
					{
						name: "Napadalci",
						items: data.forwards.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))
					},
				])
			})
	}, [])

	return (
		<PersonsGrid groups={groups}/>
	)
}
