import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";

export function MoreCookies() {
	const isMobile = useIsMobileContext()


	const columns = [{
		field: "source",
		headerName: "Vir",
		width: 100
	}, {
		field: "name",
		headerName: "Ime",
		flex: 3,
		renderCell: (params: any) => {
			return <Typography sx={{textWrap: "auto"}}>{params.value}</Typography>
		}
	}, {
		field: "description",
		headerName: "Opis",
		flex: 3,
		renderCell: (params: any) => {
			return <Typography sx={{textWrap: "auto"}}>{params.value}</Typography>
		}
	}, {
		field: "duration",
		headerName: "Trajanje",
		width: 100
	}]

	const rows = [{
		key: 1,
		source: "Google",
		name: "__utma ... __utmz",
		description: "Piškotke nastavi orodje Google Analytics. Omogoča pregled statistike spletnega mesta.",
		duration: "do 2 leti"
	}, {
		key: 2,
		source: "Google",
		name: "__utma ... __utmz, SC, _ga, PREF, SNID, NID, GoogleAccountsLocale_session, AnalyticsUserLocale, GAPS",
		description: "Piškotki omogočajo prikaz ključnih funkcionalnosti Google zemljevidov. Uporabljajo se izključno na straneh, kjer so uporabljeni zemljevidi.",
		duration: "do 2 leti"
	}, {
		key: 3,
		source: "Google",
		name: "HSID, SSID, APISID, SAPISID, VISITOR_INFO1_LIVE, demographics, use_hitbox, YSC, LOGIN_INFO, SID, PREF",
		description: "Piškotki omogočajo prikazovanje ključnih funkcionalnosti Youtube videoposnetkov. Uporabljajo se izključno na straneh, kjer se prikazujejo Youtube videoposnetki.",
		duration: "do 10 let"
	}, {
		key: 4,
		source: "Facebook",
		name: "c_user, csm, datr, fr, lu, s, xs",
		description: "Piškotek nastavi vtičnik za Facebook. Omogoča všečkanje in deljenje vsebine",
		duration: "do 2 leti"
	}]

	return (
		<Stack sx={{pt: 5}}>
			<Typography variant={isMobile ? "podnaslovS" : "podnaslovL"}>Politika piškotkov</Typography>
			<Typography sx={{pt: 2}}>
				Piškoti so majhne datoteke, ki jih naložimo na vaš računalnik. Nekateri so nujno potrebni za delovanje
				strani,
				nekateri izboljšajo uporabniško izkušnjo. Piškotki so lahko kratkotrajni ali dolgotrajni.
				Izbrišejo se po koncu poteka veljavnosti. Lahko jih izbrišemo tudi ročno.
			</Typography>
			<Typography sx={{pt: 2}}>
				Naša spletna stran uporablja piškotke. To so majhne datoteke, ki jih ob obisku naložimo na vaš
				računalnik oziroma mobilno napravo. Tako lahko prepoznamo, ko se vrnete na spletno stran ter
				omogočimo njeno boljše delovanje in uporabo.
			</Typography>
			<Typography sx={{pt: 2}}>
				Piškotki ne vsebujejo osebnih podatkov oziroma informacij, ki bi vas lahko identificirale.
			</Typography>
			<DataGrid columns={columns} rows={rows} pageSize={5} getRowId={(row) => row.key} autoHeight={true}
			          getRowHeight={() => "auto"}/>

			<Typography sx={{pt: 2}}>Izjavljamo, da lastnih piškotkov ne bomo posredovali tretjim osebam.</Typography>
			<Typography sx={{pt: 5}} variant={isMobile ? "podnaslovS" : "podnaslovL"}>Izklop piškotkov za vse spletne
				strani</Typography>


			<Typography sx={{pt: 2}}>
				Piškotke lahko popolnoma izbrišete oziroma onemogočite z nastavitvami v vašem brskalniku. Navodila se
				nahajajo v sekciji pomoč vašega brskalnika. V primeru, da boste piškotke popolnoma izključili, vam morda
				kakšna izmed funkcionalnosti na spletni strani ne bo delovala.
			</Typography>

		</Stack>
	)
}