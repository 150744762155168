import {Button, Typography} from "@mui/material";
import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {useEffect} from "react";
import useCompetitionFilterButtonsContext from "./CompetitionFilterButtonsContext";

export function CompetitionFilterButton(props: {
	competition: ECompetitionType,
	id: string,
}) {
	const {competition} = props
	const {filteredCompetitions, addCompetition, removeCompetition} = useCompetitionFilterButtonsContext()
	const isMobile = useIsMobileContext()
	const [isSelected, setIsSelected] = React.useState(false)


	useEffect(() => {
		setIsSelected(filteredCompetitions.includes(competition))
	}, [competition, filteredCompetitions]);

	function handleClick() {
		if (isSelected) {
			removeCompetition(competition)
		} else {
			addCompetition(competition)
		}
	}

	function ECompetitionTypeToShort(competition: ECompetitionType) {
		switch (competition) {
			case ECompetitionType.FIRST_LEAGUE:
				return isMobile ? "SNL" : "prva liga"
			case ECompetitionType.CUP:
				return isMobile ? "POKAL" : "pokal slovenije"
			case ECompetitionType.ANY_UEFA:
				return isMobile ? "EVROPA" : "evropske tekme"
			case ECompetitionType.FRIENDLY:
				return isMobile ? "PRIPRAVE" : "pripravljalne tekme"
		}
	}

	return isMobile ? (
		<Typography
			onClick={handleClick}
			variant={"bodySBold"}
			id={props.id}
			sx={{color: isSelected ? "#FFF" : "#676767"}}>{ECompetitionTypeToShort(competition)}</Typography>
	) : (
		<Button
			id={props.id}
			variant={isSelected ? "contained" : "outlined"}
			sx={{borderRadius: 16, p: "4px 8px"}}
			onClick={handleClick}
		>
			<Typography variant={"podnapis"}>{ECompetitionTypeToShort(competition)}</Typography>
		</Button>
	)
}