import {NewsSearchContextProvider} from "./search/NewsSearchContext";
import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";
import {getAdvancedNewsFeed} from "../../firestore/BaseFirestore";
import React from "react";
import NewsFeed from "./NewsFeed";

export function NewsFeedContainer() {
	return (
		<NewsSearchContextProvider<ArticleListFirestoreDocument>
			getFunction={getAdvancedNewsFeed}
			sortFunction={(a, b) => b.createdAt.getTime() - a.createdAt.getTime()}
			idAccessor={(element) => element.firestoreId}>
			<NewsFeed/>
		</NewsSearchContextProvider>
	)
}