import {Stack} from "@mui/material";
import {EPlayerSidebarStatType, PlayerScoreStatFirestoreDocument} from "@nitra/nkmb-card-components";
import {useEffect, useState} from "react";

import {ScoreAssistTableContentRow} from "views/competitions/playerStatSidebar/ScoreAssistTableContentRow";
import {useCompetitionViewContext} from "../common/CompetitionContext";
import {ScoreAssistHeaderRow} from "./ScoreAssistHeaderRow";

export function ScoreAssistTable(props: {
	type: EPlayerSidebarStatType,
}) {
	const {type} = props;

	const {filteredScoreStats, filteredAssistStats} = useCompetitionViewContext()
	const [displayedPlayers, setDisplayedPlayers] = useState<PlayerScoreStatFirestoreDocument[]>([])

	useEffect(() => {
		if (type === EPlayerSidebarStatType.GOAL) {
			if (filteredScoreStats.length > 8) {
				setDisplayedPlayers(filteredScoreStats.slice(0, 8))
			} else {
				setDisplayedPlayers(filteredScoreStats)
			}
		} else if (type === EPlayerSidebarStatType.ASSIST) {
			if (filteredAssistStats.length > 8) {
				setDisplayedPlayers(filteredAssistStats.slice(0, 8))
			} else {
				setDisplayedPlayers(filteredAssistStats)
			}
		} else {
			setDisplayedPlayers([])
		}
	}, [type, filteredScoreStats, filteredAssistStats]);

	return (
		<Stack direction={"column"} sx={{width: "100%"}}>
			<ScoreAssistHeaderRow type={type}/>
			{
				displayedPlayers.map((player, index) =>
					<ScoreAssistTableContentRow
						key={index}
						type={type}
						player={player}
						index={index}
					/>
				)
			}
		</Stack>
	)
}
