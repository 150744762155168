import {alpha, Typography, useTheme} from "@mui/material";
import {DataGrid, GridColumns, GridRenderCellParams} from "@mui/x-data-grid";
import typography from "@nitra/nkmb-theme/typography";
import {ClubStatisticsSeasonStandingFirestoreDocument} from "models";
import React from "react";

const columns: GridColumns<ClubStatisticsSeasonStandingFirestoreDocument> = [
	{
		field: "place", headerName: "#",
		align: "center",
		headerAlign: "center",
		width: 50,
		renderCell: (params: { row: ClubStatisticsSeasonStandingFirestoreDocument }) =>
			<Typography sx={{color: "#676767"}} variant={"bodyM"}>{params.row.place}</Typography>
	},
	{
		field: "clubName",
		headerName: "Klub",
		flex: 1,
		renderCell: (params: { row: ClubStatisticsSeasonStandingFirestoreDocument }) =>
			<Typography variant={"bodyMBold"}>
				{params.row.clubName.toUpperCase()}
			</Typography>
	},
	{
		field: "played",
		align: "center",
		headerAlign: "center",
		headerName: "T",
		width: 50,
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			sx={{color: "#676767"}}
			variant={"bodySBold"}>{params.value}</Typography>
	},
	{
		field: "wins",
		align: "center",
		headerAlign: "center",
		headerName: "Z",
		width: 50,

		renderCell: (params: GridRenderCellParams<number>) => <Typography sx={{color: "#676767"}}
		                                                                  variant={"bodySBold"}>{params.value}</Typography>
	},
	{
		field: "losses",
		align: "center",
		headerAlign: "center",
		headerName: "P",
		width: 50,

		renderCell: (params: GridRenderCellParams<number>) => <Typography sx={{color: "#676767"}}
		                                                                  variant={"bodySBold"}>{params.value}</Typography>
	},
	{
		field: "points", headerName: "T",
		align: "center",
		headerAlign: "center",
		width: 50,
		renderCell: (params: GridRenderCellParams<number>) => <Typography sx={{color: "#676767"}}
		                                                                  variant={"bodySBold"}>{params.value}</Typography>
	},
];

export function MobileClubStatisticsStandings(props: { standings: ClubStatisticsSeasonStandingFirestoreDocument[] }) {
	const {standings} = props;
	const theme = useTheme();

	return (
		<DataGrid columns={columns}
		          rows={standings}
		          getRowId={(row) => row.clubName}
		          hideFooterPagination={true}
		          hideFooter={true}
		          autoHeight={true}
		          disableColumnMenu={true}
		          rowHeight={40}
		          headerHeight={40}
		          sx={{
			          '& .MuiDataGrid-columnSeparator': {
				          display: "none"
			          },
			          '& .MuiDataGrid-columnHeaders': {
				          backgroundColor: alpha(theme.palette.text.primary, 0.07)
			          },
			          '& .MuiDataGrid-columnHeaderTitle': {
				          ...typography.bodySBold,
			          },
			          backgroundColor: theme.palette.background.default,
			          borderRadius: 1,
		          }}
		/>
	)
}
