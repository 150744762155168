import {functionConfig} from "../firebaseConfig";
import ArticleFullDto from "../../models/dtos/ArticleFullDto";

export async function getNewsFromOldId(id: string): Promise<ArticleFullDto> {
	return fetch(functionConfig.url + "getNewsFromOldId?oldId=" + id)
		.then((response) => {
			return response.json()
		})
		.then((data) => {
				return {
					...data,
					createdAt: new Date(data.createdAt),
					updatedAt: new Date(data.updatedAt)
				} as ArticleFullDto
			}
		);
}