import {Typography} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import * as React from "react";

export function HighlightsFeed() {
	return (
		<InnerWidthContainer>
			<Typography>
				Highlights feed
			</Typography>
		</InnerWidthContainer>
	)
}
