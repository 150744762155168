import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import {DoubleVideoCard, ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import React from "react";
import {INNER_MAX_WIDTH, INNER_PADDING} from "../../App";
import Banner from "../../components/Banner";
import {VipButton} from "./VipButton";
import {VipElementWithScreenWideBackground} from "./VipElementWithScreenWideBackground";
import {VipPhotoGallery} from "./VipPhotoGallery";
import {VipTexts} from "./VipTexts";

function VipSkybox() {
	const theme = useTheme();

	const {isIos} = useIosContext()
	const isMobile = useIsMobileContext()

	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{
				display: "flex",
				flexGrow: 1,
				width: "100%"
			}}>
			<Banner
				title={VipTexts.skybox.banner.title}
				subtitle={VipTexts.skybox.banner.subtitle}
				imageUrl={isMobile ? "/images/hospitality/skybox/mobile/banner.png" : "/images/hospitality/skybox/skybox_banner.png"}
				desktopHeight={isMobile ? 1080 : 570}
			/>
			<Grid
				sx={{
					width: "100%",
					maxWidth: INNER_MAX_WIDTH,
					px: INNER_PADDING,
					mx: "auto",
					pt: {xs: 2, md: 4},
					flexGrow: 1,
					mt: 0,
					color: theme.palette.background.paper,
				}}
				spacing={2}
				container
			>
				<Stack
					sx={{
						flexDirection: {
							md: "column",
							lg: "row"
						},
						alignItems: "center",
						width: "100%",
						px: 2,
					}}>
					<Box
						sx={{
							maxWidth: "600px",
							width: "100%",
							mr: 8,
							mb: 4,
							ml: {
								xs: 8,
								md: 0,
							}
						}}>
						<DoubleVideoCard youTubeId={"mW-3r_zcFJo"}/>
					</Box>
					<Stack spacing={0} sx={{maxWidth: "600px"}}>
						<Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>{VipTexts.skybox.title1}</Typography>
						{VipTexts.skybox.body1.split("⦁").map((line, index) => line.trim() &&
                            <Typography key={index} variant={"body1"} sx={{mt: 0}}>⦁ {line}</Typography>
						)}
					</Stack>
				</Stack>
				<Grid item xs={12} sx={{mt: 4}}>
					<Typography variant={"h3"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{VipTexts.skybox.title2}</Typography>
					{VipTexts.skybox.body2.split("⦁").map((line, index) => line.trim() &&
                        <Typography key={index} sx={{mt: 2}} variant={"body1"}>{line}</Typography>
					)}
				</Grid>
				<Grid item sx={{mt: 4}} container>
					<VipButton
						label={"hospitality@nkmaribor.com"}
						to={"mailto:hospitality@nkmaribor.com"}
					/>
					<ShareArea sx={{ml: 2}} shareUrl={"/"} brightText={true}/>
				</Grid>
				<VipPhotoGallery imageUrls={
					isMobile ?
						VipTexts.skybox.mobileImageUrls : VipTexts.skybox.imageUrls}/>
				<VipElementWithScreenWideBackground {...VipTexts.basic.vps}/>
			</Grid>
		</Stack>
	)
}

export default VipSkybox;
