import {Box, Stack, useTheme} from "@mui/material";
import React from "react";
import {BoxWithFullWidthBackground} from "../BoxWithFullWidthBackground";
import {INavigationBarTab} from "./INavigationBarTab";

import {NavigationBarDesktopButton} from "./NavigationBarDesktopButton";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {INNER_MAX_WIDTH} from "../../App";

export function NavigationBarDesktop(props: {
	tabs: INavigationBarTab[],
	currentTab: string
}) {
	const {tabs, currentTab} = props

	const theme = useTheme();
	const isMobile = useIsMobileContext();

	return (
		<Box sx={{
			maxWidth: {
				xs: "100%",
				md: INNER_MAX_WIDTH
			},
			pl: {
				xs: 0,
				md: "90px",
			}
		}}>
			<BoxWithFullWidthBackground backgroundColor={theme.palette.text.primary}>
				<Stack direction={"row"} sx={{
					overflowX: 'auto',
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					'&::-webkit-scrollbar': {width: 0, height: 0},
					height: isMobile ? 50 : 40,
				}}>
					{tabs.map((tab, index) =>
						<NavigationBarDesktopButton
							key={index}
							label={tab.label}
							selected={currentTab === tab.url}
							to={tab.url}
						/>)}
				</Stack>
			</BoxWithFullWidthBackground>
		</Box>
	)
}
