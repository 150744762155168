import {Box, Button, Grid, Stack, Typography, useTheme} from "@mui/material";
import {
	CalendarCardWithLogic,
	TimeManagementContextProvider,
	toWeekdayDayAndTime,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import useTicketData from "../../components/ContextProviders/TicketDataContext";
import {NkmbArrowBasicLeft, NkmbArrowBasicRight} from "@nitra/nkmb-theme";
import {MatchTicketCard} from "./MatchTicketCard";
import {ETicketLevel} from "../../models/enums/ETicketLevel";
import {ETicketType} from "../../models/enums/ETicketType";
import {getSeasonMatches} from "../../firestore/BaseFirestore";
import React from "react";

export function NormalTicketContainer() {
	const theme = useTheme();
	const isMobile = useIsMobileContext();
	const {isIos} = useIosContext()
	const {
		selectedMatch,
		canSelectNextMatch,
		canSelectPreviousMatch,
		selectNextMatch,
		selectPreviousMatch
	} = useTicketData()

	if (selectedMatch === null || selectedMatch === undefined) {
		return (
			<></>
		)
	}

	return (
		<Stack
			direction={isMobile ? "column" : "row"}
			sx={{
				minHeight: "440px",
				backgroundColor: theme.palette.primary.main,
				borderRadius: "20px",
				boxShadow: "0px 0px 8px 0px #0000001F",
				position: "relative",
				display: "flex",
				mt: 5,
				maxWidth: "1440px",
			}}>
			<Box sx={{
				position: "absolute",
				bottom: "0",
				left: "0",
				right: "0",
				height: "50%",
				borderBottomLeftRadius: "20px",
				borderBottomRightRadius: "20px",
				background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(18,18,18,0.8) 100%)",
			}}/>
			<Stack sx={{p: isMobile ? 3 : 5, maxWidth: "240px", zIndex: 1}}>
				<Typography
					variant={isMobile ? "h3" : "h2"}
					sx={{
						color: theme.palette.background.default,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						pb: 2,
						mb: "auto"
					}}>
					{"Dnevne vstopnice"}
				</Typography>
				{selectedMatch.date &&
                    <Typography
                        variant={isMobile ? "podnaslovXS" : "podnaslovS"}
                        sx={{
							color: theme.palette.background.default,
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
							// mt: "auto",
						}}>
						{toWeekdayDayAndTime(selectedMatch.date)}
                    </Typography>
				}
				<Typography
					variant={isMobile ? "h5" : "h4"}
					sx={{
						color: theme.palette.background.default,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						pb: isMobile ? 2 : 0,
					}}>
					{selectedMatch.title}
				</Typography>
				{canSelectNextMatch && <Button
                    variant={"contained"}
                    onClick={selectNextMatch}
                    sx={{
						color: theme.palette.background.default,
						borderRadius: "24px",
						mt: 3,
						mr: "auto"
					}}>Naslednja <NkmbArrowBasicRight/> </Button>}
				{canSelectPreviousMatch && <Button
                    variant={"contained"}
                    onClick={selectPreviousMatch}
                    sx={{
						color: theme.palette.background.default,
						borderRadius: "24px",
						mt: 3,
						mr: "auto"
					}}><NkmbArrowBasicLeft/> Prejšnja </Button>}
			</Stack>
			<Grid container spacing={3} sx={{
				py: isMobile ? 1 : 5,
				px: isMobile ? 1 : 5,
				zIndex: 1,
				flexDirection: isMobile ? "column" : "row",
				alignItems: isMobile ? "stretch" : "unset"
			}}>
				{selectedMatch && selectedMatch.date && selectedMatch.normalTicketLink &&
                    <Grid item>
                        <MatchTicketCard
                            extraText={toWeekdayDayAndTime(selectedMatch.date)}
                            data={{
								actionLink: selectedMatch.normalTicketLink,
								ticketLevel: ETicketLevel.NORMAL,
								ticketType: ETicketType.MATCH_TICKET
							}}/>
                    </Grid>
				}
				{
					selectedMatch && selectedMatch.date && selectedMatch.vipTicketLink &&
                    <Grid item>
                        <MatchTicketCard

                            extraText={toWeekdayDayAndTime(selectedMatch.date)}
                            data={{
								actionLink: selectedMatch.vipTicketLink,
								ticketLevel: ETicketLevel.VPS,
								ticketType: ETicketType.MATCH_TICKET
							}}/>
                    </Grid>
				}
				{!isMobile &&
                    <Grid item>
                        <Box sx={{}}>
                            <TimeManagementContextProvider
                                getterFunction={getSeasonMatches}
                                limitToCurrentSeason={true}
                            >
                                <CalendarCardWithLogic/>
                            </TimeManagementContextProvider>
                        </Box>
                    </Grid>
				}
			</Grid>
		</Stack>
	)
}