import {Box, Stack} from "@mui/material";
import {CardContainerTitle} from "components/CardContainerTitle";
import usePlayerDetailsContext from "./PlayerDetailsContext";
import {PlayerProfileMatchCard} from "./PlayerProfileMatchCard";
import {PlayerProfileStatCardContainer} from "./PlayerProfileStatCardContainer";
import {
	DesktopSingleWithTextNewsCard,
	MobileLargeOnlyImageNewsCard,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {EPlayerPosition} from "../../../models";
import {ContentContainer} from "../../../components/ContentRender/ContentContainer";

export function PlayerProfileTab() {
	const data = usePlayerDetailsContext()
	const isMobile = useIsMobileContext()

	if (!data) {
		return (
			<Stack>
				loading
			</Stack>
		)
	}

	return (
		<Box sx={{pt: 5, position: "relative"}}>
			<PlayerProfileStatCardContainer data={data}/>
			{data.content &&
                <ContentContainer content={data.content} sx={!isMobile ? {
					maxWidth: "unset",
				} : {}}/>
			}
			{data.firstMatch &&
                <Box sx={{mt: 5}}>
                    <CardContainerTitle title={"Prva tekma"}/>
                    <PlayerProfileMatchCard data={data.firstMatch}
                                            isGoalKeeper={data.playerPosition === EPlayerPosition.GOALKEEPER}/>
                </Box>
			}
			{data.lastMatch &&
                <Box sx={{mt: 5}}>
                    <CardContainerTitle title={"Zadnja tekma"}/>
                    <PlayerProfileMatchCard data={data.lastMatch}
                                            isGoalKeeper={data.playerPosition === EPlayerPosition.GOALKEEPER}/>
                </Box>
			}
			{data.relatedArticles && data.relatedArticles.length > 0 &&
                <Stack>
                    <CardContainerTitle title={"Podobne novice"}/>
                    <Stack direction={isMobile ? "column" : "row"} spacing={2}>
						{data.relatedArticles?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
							.map((article) => isMobile ?
								<MobileLargeOnlyImageNewsCard data={article}/> :
								<DesktopSingleWithTextNewsCard data={article}/>
							)}
                    </Stack>
                </Stack>
			}
		</Box>
	)
}
