import {alpha, Stack, Typography, useTheme} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import typography from "@nitra/nkmb-theme/typography";
import ClubStandingsFirestoreDocument from "models/firestore/common/ClubStandingsFirestoreDocument";
import React from "react";
import {getIconFromMomentum} from "./GetIconFromMomentum";

/**
 * DataGrid containing the standings of the league for mobile view
 */
export function CurrentStandingsMobileGrid(props: { clubs: ClubStandingsFirestoreDocument[] }) {
	const {clubs} = props;
	const theme = useTheme();

	const columns = [
		{
			field: "momentum",
			headerName: "",
			minWidth: 24,
			maxWidth: 24,
			padding: 0,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) => getIconFromMomentum(params.row.momentum),
		},
		{
			field: "place",
			headerName: "#",
			minWidth: 10,
			maxWidth: 30,
			padding: 0, renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<Typography variant={"bodyM"}>{params.row.place}</Typography>
		},
		{
			field: "clubName",
			headerName: "Klub",
			flex: 1,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<Stack direction={"row"} alignItems={"center"} spacing={1}>
					<img src={params.row.metaData.emblemUrl}
					     alt={params.row.metaData.shortName}
					     style={{
						     width: 40,
						     height: 40,
					     }}/>
					<Typography variant={"bodyMBold"}>
						{params.row.metaData.shortName.toUpperCase()}
					</Typography>
				</Stack>
		},
		{
			field: "played",
			headerName: "T",
			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodySBold"}>{params.value}</Typography>
		},
		{
			field: "wins",
			headerName: "Z",

			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodySBold"}>{params.value}</Typography>
		},
		{
			field: "draws",
			headerName: "N",

			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodySBold"}>{params.value}</Typography>
		},
		{
			field: "losses",
			headerName: "P",

			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodySBold"}>{params.value}</Typography>
		},
		{
			field: "goalDifference",
			headerName: "+/-",

			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<Typography
					variant={"bodySBold"}>{params.row.goalsFor - params.row.goalsAgainst}</Typography>
		},
		{
			field: "points",
			headerName: "T",

			minWidth: 30,
			maxWidth: 30,
			padding: 0,
			renderCell: (params: GridRenderCellParams<number>) =>
				<Typography variant={"bodySBold"}>{params.value}</Typography>
		},
	]

	return (
		<DataGrid columns={columns}
		          rows={clubs}
		          getRowId={(row) => row.metaData.key}
		          hideFooterPagination={true}
		          hideFooter={true}
		          autoHeight={true}
		          disableColumnMenu={true}
		          sx={{
			          '& .MuiDataGrid-columnSeparator': {
				          display: "none"
			          },
			          '& .MuiDataGrid-columnHeaders': {
				          backgroundColor: theme.palette.background.paper,
			          },
			          '& .MuiDataGrid-columnHeaderTitle': {
				          ...typography.bodySBold,
				          color: "#676767",
			          },
			          '& .MuiDataGrid-row:nth-of-type(1)': {
				          backgroundColor: alpha(theme.palette.text.primary, 0.07),
			          },
			          '& .MuiDataGrid-row:nth-of-type(2), .MuiDataGrid-row:nth-of-type(3)': {
				          backgroundColor: alpha(theme.palette.text.primary, 0.03),
			          },
			          '& .MuiDataGrid-row:nth-last-of-type(2)': {
				          backgroundColor: alpha(theme.palette.text.primary, 0.03),
			          },
			          '& .MuiDataGrid-row:nth-last-of-type(1)': {
				          backgroundColor: alpha(theme.palette.text.primary, 0.07),
			          },
			          backgroundColor: theme.palette.background.default,
			          borderRadius: 0,
			          width: "100%",
		          }}
		/>
	)
}
