import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {DesktopStatistics} from "views/club/statistics/desktop/DesktopStatistics";
import {MobileStatistics} from "views/club/statistics/mobile/MobileStatistics";
import {Route, Routes} from "react-router-dom";
import {ClubStatisticsPlayerPage} from "./ClubStatisticsPlayerPage";

export function ClubStatistics() {
	const isMobile = useIsMobileContext()

	function Statistics() {
		if (isMobile) {
			return <MobileStatistics/>
		} else {
			return <DesktopStatistics/>
		}
	}

	return (
		<Routes>
			<Route path="player/:key" element={<ClubStatisticsPlayerPage/>}/>
			<Route path="*" element={<Statistics/>}/>
		</Routes>
	)
}

