export const firebaseConfig = {
	apiKey: "AIzaSyBXSmIaiqnt1LBERG0FijNlGjpQRZ_I420",
	authDomain: "dev-handa-nkmb.firebaseapp.com",
	projectId: "dev-handa-nkmb",
	storageBucket: "dev-handa-nkmb.appspot.com",
	messagingSenderId: "227037639662",
	appId: "1:227037639662:web:fd3d634d4c3f41db3cd4ff",
	measurementId: "G-Y94FQ16XRP"
};

export const functionConfig = {
	url: "https://europe-west3-dev-handa-nkmb.cloudfunctions.net/"
}