import {Box, Grid, Stack} from "@mui/material";
import {
	ArticleListFirestoreDocument,
	DoubleVideoCard,
	DynamicNewsCard,
	useBreakpointContext,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {CardContainerTitle} from "../../components/CardContainerTitle";
import {VideoFirestoreListDocument} from "../../models";

export function LandingNewsContainer(props: {
	items: ArticleListFirestoreDocument[],
	video?: VideoFirestoreListDocument,
}) {
	const {items, video} = props;
	const isMobile = useIsMobileContext();
	const breakpoint = useBreakpointContext()

	return (
		<InnerWidthContainer>
			<Box sx={{
				pb: 5,
			}}>{
				isMobile ?
					<CardContainerTitle
						title={"Zadnje novice"}
						to={"/news"}
						sx={{
							ml: breakpoint === "md" ? 1.5 : "unset",
							py: 0,
						}}
					/>
					:
					<Stack direction={"row"} sx={{pb: 0,}}>
						<CardContainerTitle
							title={"Zadnje novice"}
							to={"/news"}
							sx={{
								ml: breakpoint === "md" ? 1.5 : "unset",
								py: 0,
							}}
						/>
					</Stack>
			}
				<Grid
					container
					spacing={3}
					sx={{
						justifyContent: isMobile ? null : "center",
						'& > :last-child': {
							mr: "unset",
						},
					}}
				>
					{items.map((item, index) =>
						<Grid
							key={item.firestoreId}
							item
							xs={isMobile ? 12 : false}
						>
							<DynamicNewsCard
								key={item.firestoreId}
								data={item}
								index={index}
								isOnLanding={true}/>
						</Grid>
					)}
					{video &&
                        <Grid
                            key={video.key}
                            item
                            xs={isMobile ? 12 : false}
                        >
                            <DoubleVideoCard
                                sx={{height: isMobile ? "unset" : "198px", width: isMobile ? "100%" : "288px"}}
                                youTubeId={video.youtubeId}/>
                        </Grid>}
				</Grid>
			</Box>
		</InnerWidthContainer>
	)
}
