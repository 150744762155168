import {PlayerStatTab} from "../../team/playerDetailsPage/PlayerStatTab";
import {InnerWidthContainer} from "../../../components";
import {PlayerStatsContextProvider} from "../../team/playerDetailsPage/PlayerStatsContext";
import {
	CompetitionFilterButtonsContextProvider
} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";
import {PlayerDetailsContextProvider} from "../../team/playerDetailsPage/PlayerDetailsContext";
import {Stack} from "@mui/material";
import {PlayerProfileBanner} from "../../team/playerDetailsPage/banner/PlayerProfileBanner";
import React from "react";

export function ClubStatisticsPlayerPage() {
	return (
		<PlayerDetailsContextProvider>
			<CompetitionFilterButtonsContextProvider allowMultipleSelection={false}>
				<PlayerStatsContextProvider>
					<InnerWidthContainer>
						<Stack>
							<PlayerProfileBanner/>
							<PlayerStatTab/>
						</Stack>
					</InnerWidthContainer>
				</PlayerStatsContextProvider>
			</CompetitionFilterButtonsContextProvider>
		</PlayerDetailsContextProvider>
	)
}