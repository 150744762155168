import {TextWidthContainer} from "../../components";
import {Stack, Typography} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";

export function FootballSchoolPrimarySchoolsBody() {
	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack>
				<Typography variant={"bodyXL"} sx={{pt: 4}}>
					Nogometna šola NK Maribor v sodelovanju z mariborskimi osnovnimi šolami vsako leto organizira
					otroško
					nogometno šolo. Gre za interesno dejavnost, ki je pomemben temelj za popularizacijo našega kluba in
					športa na sploh. Na triindvajsetih osnovnih šolah, v katerih sodeluje okoli 850 otrok, se vadba
					izvaja
					enkrat do trikrat tedensko.
				</Typography>
				<Typography variant={"bodyXL"}>
					<br/>
					Program je namenjen dečkom in deklicam prve triade in sicer starih od šest do osem let. Vadba poteka
					pod
					vodstvom in nadzorom strokovno usposobljenega vaditelja. Cilj pedagoško-strokovnega procesa je
					spoznavanje pomena športa oziroma natančneje nogometa. Otroci se bodo v sklopu vadbe učili
					ustreznega
					športnega vedenja in osnovnih elementov nogometne igre, izboljšali pa bodo tudi svoje motorične
					sposobnosti.
					<br/><br/>
					Nudimo možnost obiska šolarjev na stadiona Ljudski vrt ali obisk znanega nogometaša NK Maribor na
					vaši
					šoli. Otroke želimo navdušiti za nogometno igro, spodbujati k gibanju in zdravemu načinu življenja.
					Želimo jim tudi kar najboljše predstaviti nogomet in naš klub.
				</Typography>

				<Typography variant={"bodyXL"} sx={{pt: 5}}>Osnovne šole, ki so vključene v projekt ONŠ:</Typography>
				<Typography variant={"bodyXL"}>OŠ Prežihov Voranc</Typography>
				<Typography variant={"bodyXL"}>OŠ Draga Kobala Brezje</Typography>
				<Typography variant={"bodyXL"}>OŠ Kamnica</Typography>
				<Typography variant={"bodyXL"}>OŠ Draga Kobala</Typography>
				<Typography variant={"bodyXL"}>OŠ Janko Padežnik</Typography>
				<Typography variant={"bodyXL"}>OŠ Leona Štuklja</Typography>
				<Typography variant={"bodyXL"}>OŠ Angela Besednjaka</Typography>
				<Typography variant={"bodyXL"}>OŠ Slava Klavora</Typography>
				<Typography variant={"bodyXL"}>OŠ Borci za severno mejo</Typography>
				<Typography variant={"bodyXL"}>OŠ Bojan Ilich</Typography>
				<Typography variant={"bodyXL"}>OŠ Franca Rozmana Staneta</Typography>
				<Typography variant={"bodyXL"}>OŠ Franceta Prešerna</Typography>
				<Typography variant={"bodyXL"}>OŠ Bratov Polančičev</Typography>
				<Typography variant={"bodyXL"}>OŠ Tone Čufar</Typography>
				<Typography variant={"bodyXL"}>OŠ Maksa Durjave</Typography>
				<Typography variant={"bodyXL"}>OŠ Ludvika Pliberška</Typography>
				<Typography variant={"bodyXL"}>OŠ Franca Lešnika Vuka Slivnica</Typography>
				<Typography variant={"bodyXL"}>OŠ Dušana Flisa Hoče</Typography>
				<Typography variant={"bodyXL"}>OŠ Martina Konšaka</Typography>
				<Typography variant={"bodyXL"}>OŠ Tabor I</Typography>
				<Typography variant={"bodyXL"}>OŠ Rada Robiča Limbuš</Typography>
				<Typography variant={"bodyXL"}>OŠ Malečnik</Typography>
				<Typography variant={"bodyXL"}>OŠ Duplek</Typography>

				<Typography variant={"bodyXL"} sx={{pt: 2}}>
					Vodja otroške nogometne šole: Primož Kocuvan
				</Typography>
				<Stack sx={{justifyContent: "space-between"}}>
					<Stack direction={"row"}>
						<CallIcon/>
						<Typography sx={{pl: 1}}>031 285 665</Typography>
						<Typography sx={{pl: 1}}>(PON/PET med 12. in 14. uro)</Typography>
					</Stack>

					<Stack direction={"row"}>
						<EmailIcon/>
						<Typography sx={{pl: 1}}>ons@nkmaribor.com</Typography>
					</Stack>
				</Stack>
			</Stack>
		</TextWidthContainer>
	)
}