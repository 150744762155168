import {Stack, SxProps} from "@mui/material";
import {EmbedContent} from "components/ContentRender/Embed";
import {InnerWidthContainer} from "components/widthControlElements";
import {
	ContentElementPageCacheFirebaseDocumentUnion,
	EContentType,
	EmbedContentElementPageCacheFirebaseDocument,
	ImageContentElementPageCacheFirebaseDocument,
	TextContentElementPageCacheFirebaseDocument,
	VideoContentElementPageCacheFirebaseDocument
} from "models";
import {ImageContent} from "./ImageContent";
import {TextContent} from "./TextContent";
import {VideoContent} from "./VideoContent";

export function ContentContainer(props: {
	content: ContentElementPageCacheFirebaseDocumentUnion[],
	sx?: SxProps,
}) {
	const {content} = props;

	return (
		<InnerWidthContainer>
			<Stack justifyContent={"center"} alignItems={"center"} spacing={4}
			       sx={{
				       width: "100%",
				       maxWidth: 735,
				       mx: "auto",
				       my: 0,
				       pt: 4,
				       pb: 6,
				       ...props.sx
			       }}>
				{content.map((element, index) => {
					if (element.type === EContentType.TEXT) {
						return <TextContent key={index}
						                    element={element as TextContentElementPageCacheFirebaseDocument}/>
					} else if (element.type === EContentType.IMAGE) {
						return <ImageContent key={index}
						                     element={element as ImageContentElementPageCacheFirebaseDocument}/>
					} else if (element.type === EContentType.VIDEO) {
						return <VideoContent key={index}
						                     element={element as VideoContentElementPageCacheFirebaseDocument}/>
					} else if (element.type === EContentType.EMBED) {
						return <EmbedContent key={index}
						                     element={element as EmbedContentElementPageCacheFirebaseDocument}/>
					}
					return <></>
				})}
			</Stack>
		</InnerWidthContainer>
	)
}

