import {VideoFirestoreListDocument} from "../../../models";
import {alpha, Box, Stack, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

export function DesktopVideoList(props: {
	videos: VideoFirestoreListDocument[],
	selectedVideo: VideoFirestoreListDocument | null,
	setSelectedVideo: (video: VideoFirestoreListDocument) => void,
}) {
	const {videos, setSelectedVideo, selectedVideo} = props;
	const theme = useTheme()
	const isMobile = useIsMobileContext();
	return (
		<Stack
			sx={{
				height: "840px",
				overflow: 'auto',
				'::-webkit-scrollbar': {
					width: '8px',
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: alpha(theme.palette.background.default, 0.2),
				},
				'::-webkit-scrollbar-track': {
					backgroundColor: theme.palette.text.primary,
				},
			}}>
			{videos.map((video) =>
				<Stack key={video.key} direction={"row"}
				       alignItems={"center"}
				       sx={{
					       p: 1,
					       height: "120px",
					       backgroundColor: selectedVideo?.youtubeId === video.youtubeId ? "#000" : theme.palette.text.primary,
					       "&:hover": {
						       backgroundColor: theme.palette.grey[800]
					       },
				       }} onClick={() => setSelectedVideo(video)}>
					<Box sx={{
						width: "107px",
						minWidth: "107px",
						height: "83px",
						overflow: "hidden",
						borderRadius: 3,
					}}>
						<Box sx={{
							width: "147x",
							minWidth: "147px",
							height: "83px",
							backgroundImage: `url(https://img.youtube.com/vi/${video.youtubeId}/0.jpg)`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							transform: 'translateX(-20px)',
						}}>

						</Box>
					</Box>
					<Stack sx={{pl: 3}} spacing={1}>
						<Typography variant={isMobile ? "bodyL" : "bodyMobile"} sx={{
							maxHeight: "74px",
							textOverflow: "ellipsis",
							overflow: "hidden",
							lineHeight: isMobile ? "18px" : "unset",
							textDecoration: isMobile ? "underline" : "unset",
						}}>{video.title}</Typography>
						<Typography variant={isMobile ? "bodyS" : "bodyXL"}
						            sx={{
							            lineHeight: isMobile ? "unset" : "22px",
							            color: alpha(theme.palette.primary.contrastText, 0.3),
							            textDecoration: isMobile ? "underline" : "unset",
						            }}
						>
							{video.publishedAt?.toLocaleDateString(["sl-sl"], {
								year: "numeric",
								month: "numeric",
								day: "numeric",
								weekday: "long",
								hour: "numeric",
								minute: "numeric"
							})}
						</Typography>
					</Stack>
				</Stack>
			)}
		</Stack>
	)

}