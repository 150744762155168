import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";
import {TicketPageCacheFirestoreDocument} from "models/firestore/ticket/TicketPageCacheFirestoreDocument";
import {MatchTicketFirestoreDocument} from "../../models";

export async function getTicketsPage(): Promise<TicketPageCacheFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'tickets'));

	if (!snapshot.exists()) throw new Error('Tickets page not found');

	return {
		matchTickets: snapshot.data().matchTickets.map((item: any) => ({
			...item,
			date: item.date?.toDate()
		} as MatchTicketFirestoreDocument)),
		seasonNormalTicketLink: snapshot.data().seasonNormalTicketLink,
		seasonVipTicketLink: snapshot.data().seasonVipTicketLink,
	} as TicketPageCacheFirestoreDocument;
}
