import React, {PropsWithChildren, SVGProps} from "react";
import {Stack, Typography} from "@mui/material";

export function IconAndTextLineDesktop(props: PropsWithChildren<{
	icon: (props: SVGProps<SVGSVGElement>) => any,
	text: string
}>) {
	return (
		<Stack direction={"row"} alignItems={"center"} spacing={1}>
			{props.icon({height: "24px", width: "24px", color: "#676767"})}
			<Typography sx={{color: "#181818"}} variant={"bodyL"}>{props.text}</Typography>
		</Stack>
	);
}