import {Stack} from "@mui/material";
import React from "react";

import {Facebook, Instagram, TikTok, Twitter, Youtube} from "../Socials";

export function MenuDrawerSocials() {
	return (
		<Stack direction={"row"}
		       justifyContent={"space-between"}
		       alignItems={"center"}
		>
			<Facebook/>
			<Instagram/>
			<Twitter/>
			<Youtube/>
			<TikTok/>
		</Stack>
	);
}
