import {NationFlagRound} from "@nitra/nkmb-theme";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBannerNationalFlagDesktop() {
	const player = usePlayerDetailsContext()

	return (
		<NationFlagRound
			countryCode={player!.nationality}
			sx={{
				position: "absolute",
				bottom: "0px",
				right: "0px",
			}}
			svgProps={{
				height: "200px",
				width: "200px",
			}}
		/>
	)
}
