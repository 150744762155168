import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {CompetitionCalendar} from "./calendar/CompetitionCalendar";
import {CompetitionContext} from "./common/CompetitionContext";
import {CompetitionsLayout} from "./CompetitionsLayout";
import {CompetitionsMatchList} from "./matches/CompetitionsMatchList";
import {CurrentStandings} from "./prvaLiga/CurrentStandings";
import {CompetitionStatistics} from "./statistics/CompetitionStatistics";


export function CompetitionsRouter() {
	return (
		<CompetitionContext>
			<CompetitionsLayout>
				<Routes>
					<Route path="/" element={<Navigate to={"matches"} replace/>}/>
					<Route path="matches" element={<CompetitionsMatchList/>}/>
					<Route path="ledger" element={<CurrentStandings/>}/>
					<Route path="calendar" element={<CompetitionCalendar/>}/>
					<Route path="statistics/*" element={<CompetitionStatistics/>}/>
				</Routes>
			</CompetitionsLayout>
		</CompetitionContext>
	)
}

