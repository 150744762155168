import {Stack, Typography} from "@mui/material";
import React from "react";

export function MobileClubStatisticsExpandableListBasicDataLine(props: {
	label: string,
	value: string
}) {
	const {label, value} = props;
	return (
		<Stack direction={"row"} sx={{color: "#676767", p: 0, height: "30px", borderBottom: "1px solid #D9D9D9"}}
		       alignItems={"center"}>
			<Typography variant={"bodyMBold"} sx={{width: 109, pl: 3}}>{label}</Typography>
			<Typography variant={"bodyM"}>{value}</Typography>
		</Stack>
	)
}
