import React, {PropsWithChildren, useEffect, useState} from "react";
import {INavigationTarget} from "./NavigationTargets";
import {Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemText, SxProps} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export const NavigationItem = (props: PropsWithChildren<{
	item: INavigationTarget;
	index: number;
	closeDrawer: () => void;
	isDrawerOpen: boolean;
	openCollapseIndex: number;
	setOpenCollapseIndex: (index: number) => void;
	sx?: SxProps;
	isChild?: boolean,
}>) => {
	const {item, index, closeDrawer, isDrawerOpen, openCollapseIndex, setOpenCollapseIndex, isChild} = props;

	const navigate = useNavigate()

	const hasChildren = item.children && item.children.length > 0;

	const [openCollapseChildIndex, setOpenCollapseChildIndex] = React.useState(-1);

	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		setIsOpen(openCollapseIndex === index)
	}, [openCollapseIndex, index])

	const handleNavigate = () => {
		closeDrawer()
		if (item.url.startsWith("https")) {
			window.open(item.url, "_blank")
		} else {
			navigate(item.url)
		}

	}

	const handleToggleChild = () => {
		if (openCollapseIndex === index) {
			setOpenCollapseIndex(-1)
		} else {
			setOpenCollapseIndex(index)
		}
	}

	return (
		<Box sx={props.sx}>
			<ListItem key={index} disablePadding>
				<ListItemButton sx={{textAlign: 'left'}} onClick={handleNavigate}>
					<ListItemText primary={item.title}
					              primaryTypographyProps={{variant: !!isChild ? "podnaslovXSmale" : "meni"}}
					              sx={{textDecoration: isOpen ? "underline" : "none"}}
					/>
				</ListItemButton>
				{hasChildren &&
                    <IconButton onClick={handleToggleChild}>
						{isOpen ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
				}
			</ListItem>
			{hasChildren &&
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List sx={{pl: 2}}>
						{item.children?.filter((child) => child.showInMobile)
							.map((child, index) => (
								<NavigationItem
									key={index}
									item={child} index={index} closeDrawer={closeDrawer}
									isDrawerOpen={isDrawerOpen}
									openCollapseIndex={openCollapseChildIndex}
									setOpenCollapseIndex={setOpenCollapseChildIndex}
									isChild={true}
								/>
							))}
                    </List>
                </Collapse>
			}
		</Box>
	)
}
