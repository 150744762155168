import {VideoFirestoreListDocument} from "../../../models";
import {Box, Stack, Typography} from "@mui/material";
import {ShareArea} from "@nitra/nkmb-card-components";
import * as React from "react";

export function DesktopVideoMetaSegment(props: {
	video: VideoFirestoreListDocument
}) {
	const {title, description, publishedAt, videoUrl} = props.video

	return (
		<Stack sx={{pr: 5}}>
			<Typography variant={"h4"}>{title}</Typography>
			<Typography variant={"bodyMobile"} style={{fontWeight: 300}}>{description}</Typography>
			<Stack sx={{pt: 5,}} direction={"row"} alignItems={"center"}>
				<Typography variant={"bodyS"} sx={{}}>
					{publishedAt.toLocaleDateString(["sl-sl"], {
						year: "numeric",
						month: "long",
						day: "numeric",
						weekday: "long",
						hour: "numeric",
						minute: "numeric"
					})
					}
				</Typography>
				<ShareArea shareUrl={videoUrl} brightText={true}/>
			</Stack>
			<Box sx={{
				mt: 1,
				mb: 2,
				height: "1px",
				backgroundColor: "#676767",
			}}/>
		</Stack>
	)


}