import {
	BreakpointContextProvider,
	CurrentSeasonContextProvider,
	DevelopmentContextProvider,
	FooterColorModeContextProvider,
	IosContextProvider,
	IsMobileContextProvider,
	PaperBackgroundContextProvider,
	SeasonContextProvider,
	SeasonContextSettingsProvider,
	UrlParamsProvider,
	ViewportWidthContextProvider
} from "@nitra/nkmb-card-components";
import {PropsWithChildren} from "react";
import {AppBarHeightContextProvider} from "./AppBarHeightContext";
import {TicketDataContextProvider} from "./TicketDataContext";


export function ContextProviders(props: PropsWithChildren<{}>) {
	return (
		<BreakpointContextProvider>
			<IsMobileContextProvider>
				<PaperBackgroundContextProvider>
					<FooterColorModeContextProvider>
						<DevelopmentContextProvider>
							<ViewportWidthContextProvider>
								<CurrentSeasonContextProvider>
									<AppBarHeightContextProvider>
										<IosContextProvider>
											<TicketDataContextProvider>
												<UrlParamsProvider>
													<SeasonContextSettingsProvider>
														<SeasonContextProvider>
															{props.children}
														</SeasonContextProvider>
													</SeasonContextSettingsProvider>
												</UrlParamsProvider>
											</TicketDataContextProvider>
										</IosContextProvider>
									</AppBarHeightContextProvider>
								</CurrentSeasonContextProvider>
							</ViewportWidthContextProvider>
						</DevelopmentContextProvider>
					</FooterColorModeContextProvider>
				</PaperBackgroundContextProvider>
			</IsMobileContextProvider>
		</BreakpointContextProvider>
	);
}
