import {Box, Stack, Typography} from "@mui/material";
import {MatchListElementFirestoreDocument, useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {useCompetitionViewContext} from "views/competitions/common/CompetitionContext";
import {SingleMatchContainer} from "./SingleMatchContainer";
import {SingleMatchMobileContainer} from "./SingleMatchMobileContainer";


function MatchContainerWithTitle(props: {
	matches: MatchListElementFirestoreDocument[]
}) {
	const {matches} = props;
	const {firstRef} = useCompetitionViewContext();

	const isMobile = useIsMobileContext();

	const isFirstMonth = matches[0].date.getMonth() === 5;
	const title = matches[0].date.toLocaleString(["sl-sl"], {month: 'long'})

	return (
		<Stack spacing={isMobile ? 1 : 3} ref={isFirstMonth ? firstRef : null} sx={{
			pb: 2,
			mt: !isMobile && matches[0].date.getMonth() === 5 ? 0 : 3
		}}>
			<Box sx={{
				position: "sticky",
				top: 0,
				width: "100%",
				backgroundColor: "background.paper",
				height: 40,
			}}>
				<Typography variant={"podnaslovXS"} sx={{
					pl: isMobile ? 2 : "unset", pointerEvents: "none"
				}}>{title}</Typography>
			</Box>
			{isMobile ?
				matches.map(match =>
					<SingleMatchMobileContainer key={match.key} match={match}/>
				) : matches.map(match =>
					<SingleMatchContainer key={match.key} match={match}/>
				)
			}
		</Stack>
	)
}

export default MatchContainerWithTitle;

