import {getPlayerProfile} from "firestore/BaseFirestore";
import {PlayerProfileFirebaseDocument} from "models";
import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const PlayerDetailsContext = createContext<PlayerProfileFirebaseDocument | undefined>(undefined);

export function PlayerDetailsContextProvider(props: PropsWithChildren) {
	const {key} = useParams()

	const [data, setData] = useState<PlayerProfileFirebaseDocument | undefined>(undefined);

	function getData(_key: string) {
		getPlayerProfile(_key).then((data) => setData(data))
	}

	useEffect(() => {
		if (key === undefined) return
		getData(key)
	}, [key])

	return (
		<PlayerDetailsContext.Provider value={data}>
			{props.children}
		</PlayerDetailsContext.Provider>
	)
}

export default function usePlayerDetailsContext() {
	const context = useContext(PlayerDetailsContext);
	if (context === null) {
		throw new Error('usePlayerDetailsContext must be used within an PlayerDetailsContext');
	}
	return context;
}
