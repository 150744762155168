import {CompetitionStatisticsStreakFirestoreDocument} from "../../../models";
import {TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

export function CompetitionStatisticsStreakRow(props: {
	label: string,
	data?: CompetitionStatisticsStreakFirestoreDocument,
}) {
	const {label} = props
	const isMobile = useIsMobileContext()

	if (!props.data) {
		return (
			<TableRow>
				<TableCell align={"right"} sx={{pr: 1, py: 1}}>
					<Typography variant={"bodySBold"} sx={isMobile ? {color: "#676767"} : {}}>
						{label}
					</Typography>
				</TableCell>
				<TableCell sx={{pl: 1, py: 1}}>
					<Typography variant={"bodySBold"}>
						0
					</Typography>
				</TableCell>
			</TableRow>
		)
	}

	const {fromRound, toRound, duration} = props.data

	return (
		<TableRow>
			<TableCell align={"right"} sx={{pr: 1, py: 1}}>
				<Typography variant={"bodySBold"} sx={isMobile ? {color: "#676767"} : {}}>
					{label}
				</Typography>
			</TableCell>
			<TableCell sx={{pl: 1, py: 1}}>
				<Typography variant={"bodySBold"} sx={isMobile ? {color: "#676767"} : {}}>
					{duration} (od {fromRound}. do {toRound}. kola)
				</Typography>
			</TableCell>
		</TableRow>
	)
}