import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";
import {SponsorPageCacheFirestoreDocument} from "models";


export async function getSponsorsPage(): Promise<SponsorPageCacheFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'sponsors'));

	if (!snapshot.exists()) throw new Error('Sponsors page not found');

	return {
		...snapshot.data(),
	} as SponsorPageCacheFirestoreDocument;
}
