import {Stack, useTheme} from "@mui/material";
import {PropsWithChildren} from "react";

export function ScoreAssistTableRow(props: PropsWithChildren<{
	nonDefaultBackground?: boolean
}>) {
	const {nonDefaultBackground} = props;
	const theme = useTheme();

	return (
		<Stack
			direction={"row"}
			sx={{
				backgroundColor: nonDefaultBackground ? theme.palette.background.paper : theme.palette.background.default,
				height: "32px",
				px: 1,
			}}
			spacing={1}
			alignContent={"center"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			{props.children}
		</Stack>
	)
}
