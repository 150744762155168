export interface INavigationTarget {
	title: string;
	url: string;
	children?: INavigationTarget[];
	showInMobile: boolean;
	showInDesktop: boolean;
}

export const navigationTargets: INavigationTarget[] = [
	{
		title: "Domov",
		url: "/",
		showInMobile: true,
		showInDesktop: false,
	},
	{
		title: "Novice",
		url: "/news",
		children: [
			{
				title: "Članki", url: "/news",
				showInMobile: true,
				showInDesktop: true
			},
			{
				title: "Intervjuji", url: "/news",
				showInMobile: false,
				showInDesktop: false
			},
			{
				title: "Poročila s tekem", url: "/news",
				showInMobile: false,
				showInDesktop: false
			},
			{
				title: "Video", url: "/news/video",
				showInMobile: true,
				showInDesktop: true
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Vstopnice",
		url: "/tickets",
		// children: [
		// 	{
		// 		title: "Dnevne vstopnice", url: "/tickets",
		// 		showInMobile: true,
		// 		showInDesktop: false
		// 	},
		// 	{
		// 		title: "Sezonske vstopnice", url: "/tickets",
		// 		showInMobile: true,
		// 		showInDesktop: false
		// 	},
		//
		// ],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Tekmovanja",
		url: "/competitions",
		children: [
			{
				title: "Tekme", url: "/competitions/matches",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Lestvice", url: "/competitions/ledger",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Koledar sezone", url: "/competitions/calendar",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Statistika", url: "/competitions/statistics",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Ljudski vrt 5*",
		url: "/hospitality",
		children: [
			{
				title: "VPS", url: "/hospitality/vps",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Zasebna loža", url: "/hospitality/skybox",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Ekipa",
		url: "/team",
		children: [
			{
				title: "Prva ekipa", url: "/team/players",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Strokovni štab", url: "/team/staff",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "NK Maribor",
		url: "/club",
		children: [
			{
				title: "Zgodovina", url: "/club/history",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Lovorike", url: "/club/trophies",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Ljudski vrt", url: "/club/stadium",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Navijači", url: "/club/fans",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Simboli", url: "/club/symbols",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Statistika", url: "/club/statistics",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Nogometna šola",
		url: "/footballSchool",
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "Več",
		url: "/more",
		children: [
			{
				title: "Kontakt", url: "/more/contact",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Sponzorji", url: "/more/sponsors",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "Osebna izkaznica", url: "/more/about",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
]
