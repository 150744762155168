import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowFlower() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/flower.png"}
			title={"1. Vijolica"}
			text={"Odločitev za osnovno, vijoličasto barvo dresov izhaja iz zamisli prvega kapetana kluba Ota Blaznika, ki je videl nastopati nogometaše Fiorentine. Preostale barve so bile tako pogosto uporabljane za športno opremo, da so začetniki NK Maribor želeli nekaj drugačnega. Vijolica predstavlja barvo kluba v naravnem okolju in največji odmik oz. kontrast od zelene."}
		/>
	)
}
