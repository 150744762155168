import {Box, Container, Paper} from "@mui/material";

import {useIsMobileContext, usePaperBackgroundContext} from "@nitra/nkmb-card-components";
import {PropsWithChildren, useEffect} from "react";
import {useLocation} from "react-router-dom";
import NkAppBar from "./AppBar/NkAppBar";
import Footer from "./Footer/Footer";
import useAppBarHeightContext from "./ContextProviders/AppBarHeightContext";


const CommonLayout = (props: PropsWithChildren) => {
	const {children} = props;

	const {pathname} = useLocation()
	const isMobile = useIsMobileContext();

	const {paperBackgroundColor} = usePaperBackgroundContext();

	useEffect(() => {
		window.scrollTo(0, 0) // TODO what the fuck?
	}, [pathname])

	const {heightWithBlackBarWithPx} = useAppBarHeightContext();

	return (
		<Box sx={{overflow: "hidden"}}> {/* This is to prevent the background image from overflowing */}
			<NkAppBar/>
			<Container
				maxWidth={"xl"}
				sx={{
					px: {xs: 0, md: 0},
					pt: heightWithBlackBarWithPx,
				}}>
				<Paper
					sx={{
						borderRadius: 0,
						backgroundColor: paperBackgroundColor,
						boxShadow: "none",
						pb: isMobile ? 9 : 10,
					}}>
					{children}
				</Paper>
				<Footer/>
			</Container>
		</Box>
	);
}

export default CommonLayout
