import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";

export function documentSnapshotToNewsArticleCardDtoMapper(payload: any): ArticleListFirestoreDocument[] {
	return payload.map((news: any) => {
		return {
			...news,
			createdAt: news.createdAt.toDate(),
			updatedAt: news.updatedAt.toDate(),
		}
	})
}

