import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {Stack, Typography, useTheme} from "@mui/material";
import {IconAndTextLineDesktop} from "../more/contact/IconAndTextLineDesktop";
import {NkmbIconMail, NkmbIconTel} from "@nitra/nkmb-theme";
import {MoreAboutPerson, MoreAboutSubGroup} from "../more/MoreAbout";
import React from "react";
import {TextWidthContainer} from "components";

export function FootballSchoolContact() {
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack>
				<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/620 97 05"}/>
				<IconAndTextLineDesktop icon={NkmbIconTel} text={"nogometna.sola@nkmaribor.com"}/>

				<Typography variant={"h5"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
					pt: 5
				}}>Strokovne službe</Typography>

				<MoreAboutSubGroup title={"Direktor nogometne šole"}>
					<MoreAboutPerson name={"Sebastijan Harc"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Športni direktor nogometne šole"}>
					<MoreAboutPerson name={"Boštjan Kreft"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Vodja razvoja trenerjev in igralcev"}>
					<MoreAboutPerson name={"Marko Roškar"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Strokovni vodja"}>
					<MoreAboutPerson name={"Radovan Karanović"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Vodja skavtske službe"}>
					<MoreAboutPerson name={"Danijel Širec"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Vodja kondicijske priprave"}>
					<MoreAboutPerson name={"Tomaž Zorec"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Koordinator ekip do U13"}>
					<MoreAboutPerson name={"Anže Brumen"}/>
				</MoreAboutSubGroup>
			</Stack>
		</TextWidthContainer>
	)
}
