import {Box, Button, Typography, useTheme} from "@mui/material";
import React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Link} from "react-router-dom";

export function NavigationBarDesktopButton(props: {
	label: string
	selected: boolean
	to: string,
}) {
	const {label, selected} = props
	const isMobile = useIsMobileContext()
	const theme = useTheme()

	return (
		<Box>
			<Button variant={"text"}
			        id={`test-${props.to}`}
			        component={Link}
			        to={props.to}
			        sx={{
				        color: selected ? theme.palette.text.primary : theme.palette.background.paper,
				        backgroundColor: selected ? theme.palette.background.paper : theme.palette.text.primary,
				        borderRadius: 0,
				        '&:hover': {
					        backgroundColor: selected ? theme.palette.background.paper : theme.palette.text.primary,
				        },
				        py: 1,
				        px: 2,
				        width: "100%",
				        whiteSpace: "nowrap",
				        height: "100%",
			        }}>
				<Typography variant={isMobile ? "gumbS" : "gumbL"}>{label}</Typography>
			</Button>
		</Box>
	)
}
