import {Box, Stack} from "@mui/material";
import {NkmbSearchBar} from "./NkmbSearchBar";
import {NkmbDropdown} from "./NkmbDropdown";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import useNewsSearchContext from "./search/NewsSearchContext";

const months = [{value: -1, label: "Mesec"},
	{value: 1, label: "Januar"},
	{value: 2, label: "Februar"},
	{value: 3, label: "Marec"},
	{value: 4, label: "April"},
	{value: 5, label: "Maj"},
	{value: 6, label: "Junij"},
	{value: 7, label: "Julij"},
	{value: 8, label: "Avgust"},
	{value: 9, label: "September"},
	{value: 10, label: "Oktober"},
	{value: 11, label: "November"},
	{value: 12, label: "December"}]

export function NewsSearchLine<T>(props: {
	isOnDarkBackground?: boolean,
	initialYear: number
}) {
	const {isOnDarkBackground, initialYear} = props
	const {month, setMonth, year, setYear, search, setSearch, callGetFunction} = useNewsSearchContext<T>()

	const isMobile = useIsMobileContext();

	function generateYears(initialYear: number) : {value: number, label: string}[] {
		return [{value: -1, label: "Leto"},
			...Array.from({length: new Date().getFullYear() - initialYear + 1}, (_, i) => i + initialYear).map((year) => ({
				value: year,
				label: year.toString()
			}))
				.sort((a, b) => parseInt(b.label) - parseInt(a.label))

		]
	}

	return (
		<Box sx={{pt: 1}}>
			{isMobile &&
                <NkmbSearchBar search={search} setSearch={setSearch} callSearch={() => callGetFunction(true)}/>}
			<Stack direction={"row"} spacing={2} alignItems={"center"}>
				<NkmbDropdown
					options={generateYears(initialYear)}
					onSelect={setYear}
					selected={year}
					isOnDarkBackground={isOnDarkBackground}/>
				<NkmbDropdown
					options={months}
					onSelect={setMonth}
					selected={month}
					isOnDarkBackground={isOnDarkBackground}/>
				{!isMobile &&
                    <NkmbSearchBar search={search} setSearch={setSearch} callSearch={() => callGetFunction(true)}/>}
			</Stack>
		</Box>
	)
}

