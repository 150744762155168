import {Stack} from "@mui/material";
import {getSelection} from "firestore";
import {FootballSchoolPlayerListFirestoreDocument, FootballSchoolStaffListFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ClubBanner} from "views/club/ClubBanner";
import {FootballSchoolNavigator} from "views/footballSchool/FootballSchoolNavigator";
import {FootballSchoolPersonGrid} from "views/footballSchool/FootballSchoolPersonGrid";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "../../components/widthControlElements";

export function FootballSchoolSelection() {
	const {key} = useParams();
	const isMobile = useIsMobileContext()

	const [coachList, setCoachList] = useState<FootballSchoolStaffListFirestoreDocument[] | undefined>(undefined);
	const [playerList, setPlayerList] = useState<FootballSchoolPlayerListFirestoreDocument[] | undefined>(undefined);
	const [image1440x680url, setImage1440x680url] = React.useState("/images/football-school-banner.png");

	useEffect(() => {
		if (key) {
			getSelection(key.replace("-", ""))
				.then((payload) => {
					setCoachList(payload.coaches);
					setPlayerList(payload.players);
					setImage1440x680url(payload.image1440x680url)
				})
		}
	}, [key]);

	return (
		<Stack>
			<ClubBanner
				url={image1440x680url}
				mainText={key ?? "Nogometna šola NK Maribor"}
				extraComponent={isMobile ? <FootballSchoolNavigator/> : undefined}
				alignText={"stretch"}
			/>
			{!isMobile &&
                <InnerWidthContainer>
                    <FootballSchoolNavigator/>
                </InnerWidthContainer>
			}
			<FootballSchoolPersonGrid
				coaches={coachList}
				players={playerList}
			/>
		</Stack>

	)
}

