import {Box, SxProps, useTheme} from "@mui/material";
import {INNER_MAX_WIDTH, INNER_PADDING} from "App";
import {PropsWithChildren} from "react";

/**
 * Contianer for cards and similar, to account for padding from the paper.
 * @param props
 * @constructor
 */
export function InnerWidthContainer(props: PropsWithChildren<{
	sx?: SxProps,
	innerSx?: SxProps,
}>) {
	const theme = useTheme();
	const breakpointXL = theme.breakpoints.values.xl

	return (
		<Box sx={{
			width: breakpointXL,
			maxWidth: "100%",
			...props.sx,
		}}>
			<Box sx={{
				maxWidth: {
					xs: "100%",
					sm: "624px",
					md: "912px",
					lg: INNER_MAX_WIDTH,
					xl: INNER_MAX_WIDTH,
				},
				px: INNER_PADDING,
				// backgroundColor: "darkGrey",
				mx: "auto",
				...props.innerSx
			}}>
				{props.children}
			</Box>
		</Box>
	);
}
