import {Stack, Typography} from "@mui/material";
import {TextWidthContainer} from "../../components";
import React from "react";

export function FootballSchoolBasicText() {
	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack spacing={5}>
				<Typography variant={"bodyXL"}>
					NK Maribor s svojo bogato zgodovino poskuša v nogometni šoli nadaljevati z razvojem uspešnih
					nogometašev. Prav uspehi, ki jih dosega naše člansko moštvo in ekipe nogometne šole ter tudi
					posamezniki, vzgojeni pri nas, nam dajejo vedno znova zagon za še bolj kakovostno in zagnano delo.
					Hkrati pa med delovanjem šole ne pozabljamo na njen širši socialni pomen: otroci se pri nas ne učijo
					le nogometnih veščin, ampak jih strokovni delavci pripravljajo tudi na nadaljnje življenje.
					Spoznavajo pomen discipline, skupinskega dela, medsebojnega sodelovanja, omogočena pa so jim tudi
					potovanja po Sloveniji in Evropi.
				</Typography>
				<Typography variant={"bodyXL"}>
					Po tekmovalni plati je nogometna šola NK Maribor najuspešnejša v Sloveniji. Mladi nogometaši so
					razdeljeni v deset starostnih selekcij od U-8 do U19, v katerih je registriranih več kot 210
					igralcev. Od ustanovitve šole v sedanji obliki (leta 1990) so naše vitrine napolnile številne
					trofeje,
					namenjene zmagovalcu državnega prvenstva in pokalnega tekmovanja. Le mariborskim upom je doslej
					uspelo v eni
					sezoni osvojiti naslove prvaka v kategorijah U-13, U-15, U-17 in U-19. Z dosežki in s sodelovanjem
					na številnih mednarodnih turnirjih so prispevali velik delež k prepoznavnosti imena NK Maribor v
					tujini.
				</Typography>
				<Typography variant={"bodyXL"}>
					Vsak dan stremimo k temu, da smo boljši in uspešnejši, ampak zavedamo se, da brez trdega dela,
					inovativnosti in sledenju smernic sodobnega nogometa ne gre. Prav zato želimo organizirati nogometno
					šolo, tako da bomo vzor drugim šolam v naši regiji, Sloveniji in tem delu Evrope.
				</Typography>
				<Typography variant={"bodyXL"}>
					Vse dodatne informacije bomo zajeli in sproti dopolnjevali na spletni strani, za ostala vprašanja
					smo
					vam z veseljem na voljo. Po telefonu ali elektronski pošti.
				</Typography>
			</Stack>
		</TextWidthContainer>
	)
}