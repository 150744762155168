import {alpha, Box, Divider, Grid, Stack, SvgIcon, Typography, useTheme} from "@mui/material";
import {
	CardWithShadow,
	getCompetitionNameFromType,
	toNumericDate,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {
	NkmbIconCardRed,
	NkmbIconCardYellow,
	NkmbIconGol,
	NkmbIconKarton,
	NkmbIconPodaje,
	NkmbIconPolcasKonec,
	NkmbIconRumRdBarva
} from "@nitra/nkmb-theme";
import {MatchContainerCenterColumn} from "components/Competitions/MatchContainerCenterColumn";
import {MatchContainerTimeAndDate} from "components/Competitions/MatchContainerTimeAndDate";
import {PlayerProfileMatchFirebaseDocument} from "models";
import React, {SVGProps} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function PlayerProfileMatchCard(props: {
	data: PlayerProfileMatchFirebaseDocument,
	isGoalKeeper?: boolean,
}) {
	const {data, isGoalKeeper} = props;

	const isMobile = useIsMobileContext()
	const theme = useTheme()

	return isMobile ? (
		<Box sx={{
			height: "112px",
			ml: -2,
			mr: -2,
			backgroundColor: theme.palette.background.default
		}}>
			<Stack sx={{pl: 2, pt: 1}}>
				<Stack direction={"row"}>
					<Typography variant={"bodyS"}>
						{toNumericDate(data.common.date)}
					</Typography>
					<Typography variant={"bodyS"}
					            sx={{
						            color: alpha(theme.palette.text.primary, 0.65),
						            pl: 5
					            }}
					>
						{getCompetitionNameFromType(data.common.competition)}
					</Typography>
				</Stack>
				<Stack direction={"row"} sx={{height: "77px", mb: "11px"}}
				       alignItems={"flex-end"}
				       justifyContent={"space-around"}
				>
					<img
						alt={data.common.homeTeamName}
						src={data.common.homeTeamLogoUrl}
						style={{
							width: 40,
							height: 40,
						}}/>
					<img
						alt={data.common.awayTeamName}
						src={data.common.awayTeamLogoUrl}
						style={{
							width: 40,
							height: 40,
						}}/>
					<Box
						sx={{
							height: "40px",
							width: "1px",
							backgroundColor: alpha("#676767", 0.1),
						}}
					/>
					<Typography variant={"podnaslovS"} sx={{color: "#676767", pb: 1}}>{data.common.score}</Typography>
					<Box
						sx={{
							height: "40px",
							width: "1px",
							backgroundColor: alpha("#676767", 0.1),
						}}
					/>
					<Stack alignItems={"center"} sx={{pb: 1}}>
						<SvgIcon
							component={NkmbIconPolcasKonec}
							sx={{width: 36, height: 36, opacity: 0.3,}}
							viewBox={"0 0 100 100"}
						/>
						<Typography variant={"podnaslovS"}
						            sx={{color: "#676767"}}>{data.stats.minutesPlayed}</Typography>
					</Stack>
					<Box
						sx={{
							height: "40px",
							width: "1px",
							backgroundColor: alpha("#676767", 0.1),
						}}
					/>
					{!isGoalKeeper && <>
                        <Stack alignItems={"center"} sx={{pb: 1}}>
                            <SvgIcon
                                component={NkmbIconGol}
                                sx={{width: 36, height: 36, opacity: 0.3,}}
                                viewBox={"0 0 100 100"}
                            />
                            <Typography variant={"podnaslovS"}
                                        sx={{color: "#676767"}}>{data.stats.goals}</Typography>
                        </Stack>
                        <Box
                            sx={{
								height: "40px",
								width: "1px",
								backgroundColor: alpha("#676767", 0.1),
							}}
                        />
                        <Stack alignItems={"center"} sx={{pb: 1}}>
                            <SvgIcon
                                component={NkmbIconPodaje}
                                sx={{width: 36, height: 36, opacity: 0.3,}}
                                viewBox={"0 0 100 100"}
                            />
                            <Typography variant={"podnaslovS"}
                                        sx={{color: "#676767"}}>{data.stats.assists}</Typography>
                        </Stack>
                        <Box
                            sx={{
								height: "40px",
								width: "1px",
								backgroundColor: alpha("#676767", 0.1),
							}}
                        />
                    </>}
					<Stack alignItems={"center"} sx={{pb: 1}}>{
						data.stats.gotRedCard ? (
							<SvgIcon
								component={NkmbIconCardRed}
								sx={{width: 36, height: 36, opacity: 1,}}
								viewBox={"0 0 100 100"}
							/>
						) : data.stats.gotYellowCard ? (
							<SvgIcon
								component={NkmbIconCardYellow}
								sx={{width: 36, height: 36, opacity: 1,}}
								viewBox={"0 0 100 100"}
							/>
						) : (
							<SvgIcon
								component={NkmbIconRumRdBarva}
								sx={{width: 36, height: 36, opacity: 1,}}
								viewBox={"0 0 100 100"}
							/>
						)
					}
						<Typography variant={"podnaslovS"}
						            sx={{color: "#676767"}}>{data.stats.gotRedCard || data.stats.gotYellowCard ? 1 : 0}</Typography>
					</Stack>

				</Stack>
			</Stack>
		</Box>
	) : (
		<CardWithShadow
			disableOnHoverActions={true}
			sx={{
				p: 0,
				display: "flex",
				flexDirection: "column"
			}}
			cardHeightOverride={140}
		>
			<Grid container direction={"row"}
			      sx={{height: "100%", alignItems: "center", justifyContent: "space-between", p: 2}}>
				<Grid item xs={3}>
					<MatchContainerTimeAndDate match={data.common}/>
				</Grid>
				<Divider orientation={"vertical"}/>
				<Grid item xs={isGoalKeeper ? 6 : 4}>
					<MatchContainerCenterColumn match={data.common}/>
				</Grid>
				<Divider orientation={"vertical"}/>
				<Grid item xs={1}>
					<PlayerProfileMatchMinutesColumn value={data.stats.minutesPlayed}/>
				</Grid>
				{!isGoalKeeper && <>
                    <Divider orientation={"vertical"}/>
                    <Grid item xs={1}>
                        <PlayerProfileMatchGoalsColumn value={data.stats.goals}/>
                    </Grid>
                    <Divider orientation={"vertical"}/>
                    <Grid item xs={1}>
                        <PlayerProfileMatchAssistsColumn value={data.stats.assists}/>
                    </Grid>
                </>
				}
				<Divider orientation={"vertical"}/>
				<Grid item xs={1}>
					<PlayerProfileMatchCardsColumn gotYellowCard={data.stats.gotYellowCard}
					                               gotRedCard={data.stats.gotRedCard}/>
				</Grid>
			</Grid>
		</CardWithShadow>
	)
}

function PlayerProfileMatchColumn(props: {
	icon: (props: SVGProps<SVGSVGElement>) => any,
	value: number,
	title: string,
}) {
	const {icon, value, title} = props;

	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Stack
			alignItems={"center"}
		>
			{icon({
				height: "60px",
				width: "60px",
			})}
			<Typography variant={"h4"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{value.toString()}</Typography>
			<Typography variant={"bodyS"}>{title}</Typography>
		</Stack>
	)
}

function PlayerProfileMatchMinutesColumn(props: {
	value: number,
}) {
	return (
		<PlayerProfileMatchColumn
			icon={NkmbIconPolcasKonec}
			value={props.value}
			title={"minute"}
		/>
	)
}


function PlayerProfileMatchGoalsColumn(props: {
	value: number,
}) {
	return (
		<PlayerProfileMatchColumn
			icon={NkmbIconGol}
			value={props.value}
			title={"zadetki"}
		/>
	)
}

function PlayerProfileMatchAssistsColumn(props: {
	value: number,
}) {
	return (
		<PlayerProfileMatchColumn
			icon={NkmbIconPodaje}
			value={props.value}
			title={"asistence"}
		/>
	)
}

function PlayerProfileMatchCardsColumn(props: {
	gotYellowCard: boolean,
	gotRedCard: boolean,
}) {
	const {
		gotYellowCard,
		gotRedCard,
	} = props;
	if (gotRedCard) {
		return (
			<PlayerProfileMatchColumn
				icon={NkmbIconCardRed}
				value={1}
				title={"kartoni"}
			/>
		)
	}
	if (gotYellowCard) {
		return (
			<PlayerProfileMatchColumn
				icon={NkmbIconCardYellow}
				value={1}
				title={"kartoni"}
			/>
		)
	}

	return (
		<PlayerProfileMatchColumn
			icon={NkmbIconKarton}
			value={0}
			title={"kartoni"}
		/>
	)
}
