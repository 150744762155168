import {
	ArticleListFirestoreDocument,
	MobileLargeOnlyImageNewsCard,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {alpha, Box, Stack, Typography, useTheme} from "@mui/material";
import {DesktopNextArticle} from "./DesktopNextArticle";
import {MobileNextArticle} from "./MobileNextArticle";
import {InnerWidthContainer} from "../../../components/widthControlElements";
import {DesktopSuggestedArticleCard} from "./DesktopSuggestedArticleCard";

export const NewsArticleFooter = (props: {
	suggestedArticles: ArticleListFirestoreDocument[],
	nextArticle: ArticleListFirestoreDocument | undefined,
}) => {
	const {suggestedArticles, nextArticle} = props;
	const isMobile = useIsMobileContext();
	const theme = useTheme()

	return (
		<InnerWidthContainer>
			<Box sx={{maxWidth: "1000px", mx: "auto", pt: 2}}>
				{suggestedArticles && suggestedArticles.length > 0 && <Box sx={{mb: 6}}>
                    <Typography variant={isMobile ? "podnaslovXS" : "podnaslovM"}>Priporočamo</Typography>
                    <Stack direction={isMobile ? "column" : "row"} spacing={2}
                           sx={{pt: isMobile ? 2 : 4, mb: 2}}>
						{suggestedArticles.map((article) => (
							<Box key={article.firestoreId} sx={{flex: 1}}>
								{isMobile ?
									<MobileLargeOnlyImageNewsCard data={article}/>
									:
									<DesktopSuggestedArticleCard data={article}/>
								}
							</Box>
						))}
                    </Stack>
                </Box>}
				<Box sx={{
					borderTop: "1px solid",
					borderColor: alpha(theme.palette.primary.light, 0.2),
					mx: "auto",
					py: 0,
					my: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					maxWidth: "670px"
				}}>
				</Box>
				{nextArticle &&
                    <Box sx={{mt: isMobile ? 2 : 6}}>
						{isMobile ?
							<MobileNextArticle data={nextArticle}/>
							:
							<DesktopNextArticle data={nextArticle}/>
						}
                    </Box>
				}
			</Box>
		</InnerWidthContainer>
	)
}

