import {FormControl, OutlinedInput, useTheme} from "@mui/material";
import {NkmbIconSearch} from "@nitra/nkmb-theme";
import React from "react";

export function MobileStatisticsSearchBar(props: {
	filter: string,
	setFilter: (filter: string) => void
}) {
	const {filter, setFilter} = props
	const theme = useTheme()

	return (
		<FormControl sx={{flexGrow: 1, px: 2}} variant="outlined">
			<OutlinedInput
				id="outlined-adornment-weight"
				aria-describedby="outlined-weight-helper-text"
				inputProps={{
					'aria-label': 'weight',
				}}
				placeholder={"Ime priimek"}
				sx={{
					borderRadius: "40px",
					backgroundColor: theme.palette.background.default
				}}
				startAdornment={<NkmbIconSearch style={{height: "24px", width: "24px", marginRight: "16px"}}/>}
				value={filter}
				onChange={(event) => setFilter(event.target.value)}
			/>
		</FormControl>
	)
}
