import {Stack, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

/**
 * Center column for wide match cards, used to show either the score, VS or date and time of the match
 * @param props
 * @constructor
 */
export function MatchVsColumn(props: {
	match:
		{
			isDone: boolean,
			score?: string,
			afterMatchPenaltyScore?: string,
			date: Date,
		}
}) {
	const {isDone, score, afterMatchPenaltyScore, date} = props.match;

	const [text, setText] = useState(<></>);
	const [boxStyle, setBoxStyle] = useState({});
	const {isIos} = useIosContext()
	const theme = useTheme();

	useEffect(() => {
		if (isDone) {
			if (afterMatchPenaltyScore) {
				setText(<>
					<Typography
						variant={"bodyL"}
						sx={{
							lineHeight: "25px",
							color: theme.palette.background.default
						}}
					>
						{score}
					</Typography>
					<Typography
						variant={"bodyL"}
						sx={{
							color: theme.palette.secondary.main,
							lineHeight: "25px"
						}}
					>
						{afterMatchPenaltyScore}
					</Typography>
				</>)
			} else {
				setText(<Typography variant={"h4"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}} color={"#FFF"}>{score}</Typography>)
			}
			setBoxStyle({
				px: "12px",
				pt: "12px",
				pb: "8px",
				maxHeight: "42px",
				backgroundColor: "primary.main",
				borderRadius: "8px",
			})
		} else if (date.getHours() !== 0) {
			setText(<Typography variant={"h4"}
			                    sx={{
				                    [theme.breakpoints.down("sm")]: isIos && {
					                    '@media (-webkit-min-device-pixel-ratio: 2)': {
						                    fontWeight: 500, // Adjust font weight for iPhones
					                    },
				                    },
			                    }}
			                    color={"primary.main"}>{date.getHours().toString().padStart(2, '0')}:{date.getMinutes().toString().padStart(2, '0')}</Typography>)
		} else {
			setText(<Typography variant={"bodyL"}>VS</Typography>)
			setBoxStyle({
				px: "unset",
				pt: "unset",
				pb: "unset",
				backgroundColor: "unset"
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDone, date])

	return (
		<Stack alignItems={"center"} justifyContent={"center"} sx={{...boxStyle}}>{text}</Stack>
	)

}
