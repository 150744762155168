import {Button, SxProps, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

export function VipButton(props: {
	label: string,
	to: string,
	sx?: SxProps
}) {
	const {label, to} = props;

	const theme = useTheme();

	return (
		<Button
			variant={"outlined"}
			component={Link}
			to={to}
			color={"inherit"}
			sx={{
				borderRadius: "20px",
				color: theme.palette.background.paper,
				...props.sx
			}}
		>
			<Typography sx={{color: "background.paper"}} variant={"gumbL"}>{label}</Typography>
		</Button>
	)
}
