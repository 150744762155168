import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {useEffect} from "react";
import {DesktopCompetitionPlayerStatistics} from "./DesktopCompetitionPlayerStatistics";
import {CompetitionStatisticsClubPlayersTable} from "./CompetitionStatisticsClubPlayersTable";
import useCompetitionFilterButtonsContext
	from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";

export function CompetitionPlayerStatistics() {
	const isMobile = useIsMobileContext();

	const {addCompetition} = useCompetitionFilterButtonsContext();

	useEffect(() => {
		addCompetition(ECompetitionType.FIRST_LEAGUE)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isMobile ? <CompetitionStatisticsClubPlayersTable/>
		: <DesktopCompetitionPlayerStatistics/>
}


