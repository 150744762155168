import {Button, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {CardContainerTitle} from "../../../../components/CardContainerTitle";

export function StadiumPrePurchaseTickets() {
	return (
		<Grid item xs={12} sx={{mt: 2}}>
			<CardContainerTitle title={"Prodaja vstopnic"}/>
			<Grid container spacing={1}>
				<Grid xs={12} item><Typography variant={"bodyLBold"}>Vijolčna Bajta</Typography></Grid>
				<Grid xs={12} item>
					<Button
						component={Link}
						to={"https://goo.gl/maps/J3uqQAUjMmYoh6HH8"}
						target={"_blank"}>
						<Typography variant={"bodyL"}>Tyrševa ulica 1</Typography>
					</Button>
				</Grid>
				<Grid xs={12} item>
					<Button
						component={Link}
						to={"https://goo.gl/maps/pyyfU9EXBmE43wvYA"}
						target={"_blank"}>
						<Typography variant={"bodyL"}>Mercator center Tabor II</Typography>
					</Button>
				</Grid>
				<Grid xs={12} item>
					<Button
						component={Link}
						to={"https://www.vijolcnabajta.si/si/vstopnice.html"}
						target={"_blank"}>
						<Typography variant={"bodyL"}>Spletni nakup</Typography>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}
