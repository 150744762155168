import {Grid, Stack, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles/createTheme";
import {SxProps} from "@mui/system";
import {useEffect, useState} from "react";

/**
 * Used to show club name and logo above each component,
 * should be supplied with either
 * clubLogoName if it's hosted inside ./public/images/logos/clubs
 * clubLogoUrl if it's not inside ./public
 * @param props
 * @constructor
 */
export function ClubLogoAndNameColumn(props: {
	clubName: string,
	clubLogoName?: string,
	clubLogoUrl?: string,
	sx?: SxProps<Theme>

}) {
	const {clubName, clubLogoName, clubLogoUrl, sx} = props;

	const [url, setUrl] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (clubLogoUrl) {
			setUrl(clubLogoUrl);
		} else if (clubLogoName) {
			setUrl(`/images/logos/clubs/${clubLogoName}`);
		} else {
			setUrl(undefined);
		}
	}, [clubLogoName, clubLogoUrl])

	return (
		<Stack sx={{
			minWidth: "100px",
			height: "96px",
			flexGrow: 1,
			...sx
		}} alignItems={"center"}>
			<Grid container sx={{width: "80px", height: "80px"}} justifyContent={"center"} alignContent={"center"}>
				<Grid item>
					<img style={{maxWidth: "80px"}} src={url} alt={clubName}/>
				</Grid>
			</Grid>
			<Typography variant={"bodyMBold"} sx={{textAlign: "center", mt: "-8px"}}>{clubName}</Typography>
		</Stack>
	)
}
