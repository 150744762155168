import {Box, Stack, Typography} from "@mui/material";
import {CompetitionStatisticsClubStatsBlock} from "./CompetitionStatisticsClubStatsBlock";
import React from "react";
import {CompetitionStatisticsStreaks} from "./CompetitionStatisticsStreaks";
import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {SingleMatchMobileContainer} from "../../../components/Competitions/SingleMatchMobileContainer";
import {useCompetitionViewContext} from "../common/CompetitionContext";

export function MobileCompetitionClubStatistics() {
	const {competitionStatBlock} = useCompetitionStatisticsContext();
	const {isFirstLeagueSelected} = useCompetitionViewContext()


	return (
		<Box sx={{mt: 3}}>
			<Typography sx={{px: 2}} variant={"podnaslovXS"}>Splošno</Typography>
			<CompetitionStatisticsClubStatsBlock/>
			<Box sx={{mt: 5, pb: 2, px: 2}}>
				<Typography variant={"podnaslovXS"}>Najvišji rezultati</Typography>
			</Box>
			<Stack sx={{flexGrow: 1, pr: 2}}>
				{competitionStatBlock &&
                    <Stack spacing={3}>
						{competitionStatBlock.highestWins.map(match => (
							<SingleMatchMobileContainer key={match.key} match={match}/>
						))}
                    </Stack>
				}
				{isFirstLeagueSelected && < CompetitionStatisticsStreaks/>}
			</Stack>
		</Box>
	);
}