import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import {useCurrentSeasonContext, useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import useTicketData from "../../components/ContextProviders/TicketDataContext";
import {SeasonTicketCard} from "./SeasonTicketCard";
import {ETicketLevel} from "../../models/enums/ETicketLevel";
import {ETicketType} from "../../models/enums/ETicketType";
import React from "react";

export function SeasonTicketContainer() {
	const theme = useTheme()
	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()
	const {currentSeasonString} = useCurrentSeasonContext()
	const {seasonNormalTicketLink, seasonVipTicketLink} = useTicketData()

	const seasonText = `Sezona ${currentSeasonString.substring(0, 5)}${currentSeasonString.substring(7, 9)}`

	if (seasonNormalTicketLink === undefined && seasonVipTicketLink === undefined) {
		return <></>
	}

	return (
		<Stack
			direction={isMobile ? "column" : "row"}
			sx={{
				minHeight: "440px",
				backgroundColor: "#232530",
				borderRadius: "20px",
				boxShadow: "0px 0px 8px 0px #0000001F",
				position: "relative",
				display: "flex",
				my: 5,
				maxWidth: "1440px",
			}}>
			<Box sx={{
				position: "absolute",
				bottom: "0",
				left: "0",
				right: "0",
				height: "50%",
				borderBottomLeftRadius: "20px",
				borderBottomRightRadius: "20px",
				background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(18,18,18,0.8) 100%)",
			}}/>
			<Stack sx={{p: isMobile ? 3 : 5, maxWidth: "240px", zIndex: 1}}>
				<Typography
					variant={isMobile ? "h3" : "h2"}
					sx={{
						color: theme.palette.background.default,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						pb: 2,
						mb: "auto"
					}}>
					{"Sezonske vstopnice"}
				</Typography>
				<Typography
					variant={isMobile ? "h5" : "h4"}
					sx={{
						color: theme.palette.background.default,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						pb: isMobile ? 2 : 0,
					}}>
					{seasonText}
				</Typography>
			</Stack>
			<Grid container spacing={3} sx={{
				py: isMobile ? 1 : 5,
				px: isMobile ? 1 : 5,
				zIndex: 1,
				flexDirection: isMobile ? "column" : "row",
				alignItems: isMobile ? "stretch" : "unset"
			}}>
				{seasonNormalTicketLink &&
                    <Grid item>
                        <SeasonTicketCard
                            extraText={seasonText}
                            data={{
								actionLink: seasonNormalTicketLink,
								ticketLevel: ETicketLevel.NORMAL,
								ticketType: ETicketType.SEASON_TICKET
							}}/>
                    </Grid>
				}
				{seasonVipTicketLink &&
                    <Grid item>
                        <SeasonTicketCard
                            extraText={seasonText}

                            data={{
								actionLink: seasonVipTicketLink,
								ticketLevel: ETicketLevel.VPS,
								ticketType: ETicketType.SEASON_TICKET
							}}/>
                    </Grid>
				}
				<Grid item>
					<SeasonTicketCard
						extraText={seasonText}
						data={{
							actionLink: "/hospitality/skybox",
							ticketLevel: ETicketLevel.SKYBOX,
							ticketType: ETicketType.SEASON_TICKET
						}}/>
				</Grid>
			</Grid>
		</Stack>
	)
}