import {Box} from "@mui/material";
import {ImageWithSubtitleAndCredits} from "@nitra/nkmb-card-components";
import {ImageContentElementPageCacheFirebaseDocument} from "models";

export function ImageContent(props: {
	element: ImageContentElementPageCacheFirebaseDocument
}) {
	const {imageUrl, imageCaption, imageCredit} = props.element;

	return (
		<Box>
			<ImageWithSubtitleAndCredits
				url={imageUrl}
				subtitle={imageCaption}
				credits={imageCredit}
			/>
		</Box>
	)
}
