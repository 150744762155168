import {Stack, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {PropsWithChildren} from "react";

export function MoreAbout() {
	return (
		<Stack>
			<MoreAboutGroup title={"Predsednik"}>
				<MoreAboutPerson name={"Drago Cotar"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Upravni odbor"}>
				<MoreAboutSubGroup title={"Predsednik"}>
					<MoreAboutPerson name={"Drago Cotar"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Podpredsednika"}>
					<MoreAboutPerson name={"Matjaž Kirbiš"}/>
					<MoreAboutPerson name={"Uroš Mlakar"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={"Člani"}>
					<MoreAboutPerson name={"Rok Moljk"}/>
					<MoreAboutPerson name={"Jure Struc"}/>
					<MoreAboutPerson name={"Jure Bračko"}/>
					<MoreAboutPerson name={"Miha Pitamic"}/>
					<MoreAboutPerson name={"Valentino Mendek"}/>
					<MoreAboutPerson name={"Andrej Plos"}/>
				</MoreAboutSubGroup>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Disciplinska komisija"}>
				<MoreAboutPerson name={"Karl Midlil"}/>
				<MoreAboutPerson name={"Matjaž Kirbiš"}/>
				<MoreAboutPerson name={"Martin Mlakar"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Vodja nogometnih operacij"}>
				<MoreAboutPerson name={"Cem Başgül"} email={"cem.basgul@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Pomočnik športnega direktorja"}>
				<MoreAboutPerson name={"Marcos Tavares"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Direktor"}>
				<MoreAboutPerson name={"Bojan Ban"} email={"bojan.ban@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Sekretar"}>
				<MoreAboutPerson name={"Uroš Jurišič"} email={"uros.jurisic@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Pomočnik sekretarja"}>
				<MoreAboutPerson name={"Tadej Zajmi"} email={"tadej.zajmi@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Tajništvo"}>
				<MoreAboutPerson name={"Mojca Kramberger"}/>
				<MoreAboutPerson name={"Albina Dovnik"} email={"tajnistvo@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Vstopnice"}>
				<MoreAboutPerson name={"Sandra Poljanec"} email={"sandra@nkmaribor.com"}/>
				<MoreAboutPerson name={"Marko Peroš"} email={"marko.peros@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"PR & Mediji"}>
				<MoreAboutPerson name={"Željko Latin"} email={"zeljko.latin@nkmaribor.com"}/>
				<MoreAboutPerson name={"Stipe Jerić"}/>
				<MoreAboutPerson name={"Miha Vidrih"}/>
				<MoreAboutPerson name={"Marijo Tomić"}/>
				<MoreAboutPerson name={"Grega Wernig"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Vodja hospitality"}>
				<MoreAboutPerson name={"Nataša Cvijanović"} email={"hospitality@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={"Računovodstvo"}>
				<MoreAboutPerson name={"Mateja Golnar Pesrl"} email={"racunovodstvo@nkmaribor.com"}/>
				<MoreAboutPerson name={"Cvetka Godec"}/>
				<MoreAboutPerson name={"Marija Kamenečki"}/>
				<MoreAboutPerson name={"Mateja Kamenečki"}/>
			</MoreAboutGroup>
		</Stack>
	)
}


export function MoreAboutGroup(props: PropsWithChildren<{
	title: string
}>) {
	const {title} = props;
	const isMobile = useIsMobileContext();

	return (
		<Stack sx={{pt: 6}}>
			<Typography variant={isMobile ? "podnaslovS" : "podnaslovL"}>{title}</Typography>
			{props.children}
		</Stack>
	)
}

export function MoreAboutSubGroup(props: PropsWithChildren<{
	title: string
}>) {
	const {title} = props;
	const isMobile = useIsMobileContext();

	return (
		<Stack sx={{pt: 2}}>
			<Typography variant={isMobile ? "bodyMBold" : "podnaslovS"}>{title}</Typography>
			{props.children}
		</Stack>
	)
}

export function MoreAboutPerson(props: {
	name: string,
	email?: string,
}) {
	const {name, email} = props;

	return (
		<Stack>
			<Typography variant={"bodyXL"}>{name}</Typography>
			{email && <Typography variant={"bodyM"} sx={{color: "#676767", mt: "-6px"}}>{email}</Typography>}
		</Stack>
	)
}
