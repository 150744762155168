import {Typography, useTheme} from "@mui/material";
import {DataGrid, GridColumns, GridRenderCellParams} from "@mui/x-data-grid";
import typography from "@nitra/nkmb-theme/typography";
import {ClubStatisticsSeasonStandingFirestoreDocument} from "models";
import React from "react";


const columns: GridColumns<ClubStatisticsSeasonStandingFirestoreDocument> = [
	{
		field: "place", headerName: "#",
		align: "center",
		headerAlign: "center",
		renderCell: (params: { row: ClubStatisticsSeasonStandingFirestoreDocument }) =>
			<Typography variant={"podnaslovM"}>{params.row.place}</Typography>
	},
	{
		field: "clubName",
		headerName: "Klub",
		flex: 1,
		renderCell: (params: { row: ClubStatisticsSeasonStandingFirestoreDocument }) =>
			<Typography variant={"bodyL"}>
				{params.row.clubName.toUpperCase()}
			</Typography>
	},
	{
		field: "played",
		align: "center",
		headerAlign: "center",
		headerName: "T",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "wins",
		align: "center",
		headerAlign: "center",
		headerName: "Z",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "draws",
		align: "center",
		headerAlign: "center",
		headerName: "N",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "losses",
		align: "center",
		headerAlign: "center",
		headerName: "P",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "goalsFor",
		align: "center",
		headerAlign: "center",
		headerName: "+",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "goalsAgainst",
		align: "center",
		headerAlign: "center",
		headerName: "-",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyL"}>{params.value}</Typography>
	},
	{
		field: "goalDifference",
		align: "center",
		headerAlign: "center",
		headerName: "+/-",
		renderCell: (params: { row: ClubStatisticsSeasonStandingFirestoreDocument }) =>
			<Typography variant={"bodyL"}>{params.row.goalsFor - params.row.goalsAgainst}</Typography>
	},
	{
		field: "points", headerName: "T",
		align: "center",
		headerAlign: "center",
		renderCell: (params: GridRenderCellParams<number>) => <Typography
			variant={"bodyLBold"}>{params.value}</Typography>
	},
];

export function DesktopClubStatisticsStandings(props: { standings: ClubStatisticsSeasonStandingFirestoreDocument[] }) {
	const {standings} = props;
	const theme = useTheme();

	return (
		<DataGrid columns={columns}
		          rows={standings}
		          getRowId={(row) => row.clubName}
		          hideFooterPagination={true}
		          hideFooter={true}
		          autoHeight={true}
		          disableColumnMenu={true}
		          sx={{
			          '& .MuiDataGrid-columnSeparator': {
				          display: "none"
			          },
			          '& .MuiDataGrid-columnHeaders': {
				          backgroundColor: theme.palette.background.default,
			          },
			          '& .MuiDataGrid-columnHeaderTitle': {
				          ...typography.bodySBold,
				          color: "#676767",
			          },
			          backgroundColor: theme.palette.background.default,
			          borderRadius: 1,
		          }}
		/>
	)
}
