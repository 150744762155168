import {Stack, Typography, useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {PaperWidthContainer} from "components/widthControlElements";
import {useEffect, useState} from "react";

import {
	PlayerProfileBannerLeftColumnDesktop
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerLeftColumnDesktop";
import {
	PlayerProfileBannerLeftColumnMobile
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerLeftColumnMobile";
import {
	PlayerProfileBannerNationalFlagDesktop
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerNationalFlagDesktop";
import {
	PlayerProfileBannerNationalFlagMobile
} from "views/team/playerDetailsPage/banner/PlayerProfileBannerNationalFlagMobile";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBanner() {
	const player = usePlayerDetailsContext()
	const isMobile = useIsMobileContext();

	const [bannerUrl, setBannerUrl] = useState<string | undefined>(undefined)
	const [hasBanner, setHasBanner] = useState<boolean>(false)


	useEffect(() => {
		if (!player) return
		if (isMobile) {
			setHasBanner(!!player.image382x382url)
			setBannerUrl(player.image382x382url)
		} else {
			setHasBanner(!!player.image680x680url)
			setBannerUrl(player.image680x680url)
		}
	}, [player, isMobile]);

	if (!player) return <></>

	if (!hasBanner) {
		return (
			<PictureLessBanner/>
		)
	}

	return (
		<PaperWidthContainer
			sx={{
				backgroundColor: "white",
				backgroundImage: `url(${bannerUrl})`,
				height: hasBanner ? (isMobile ? "271px" : "680px") : "unset",
				position: "relative",
				backgroundRepeat: "no-repeat",
				backgroundPosition: isMobile ? "100px" : "center",
				pt: hasBanner ? 0 : isMobile ? 2 : 5,
				pb: !hasBanner ? 2 : 0,
				backgroundSize: isMobile ? "contain" : "unset",
			}}
			innerSx={{
				height: "100%"
			}}
		>
			{isMobile ? <PlayerProfileBannerLeftColumnMobile/> : <PlayerProfileBannerLeftColumnDesktop/>}
			{
				player.isActive && <>
					{isMobile ? <PlayerProfileBannerNationalFlagMobile forceRight={!hasBanner}/> :
						<PlayerProfileBannerNationalFlagDesktop/>}
                </>
			}
		</PaperWidthContainer>
	)
}

function PictureLessBanner() {
	const player = usePlayerDetailsContext()
	const isMobile = useIsMobileContext();
	const theme = useTheme();
	const {isIos} = useIosContext()


	if (!player) return <></>

	const {firstName, lastName, jerseyNumber} = player


	return (
		<PaperWidthContainer
			sx={{
				backgroundColor: "white",
				position: "relative",
				pt: isMobile ? 2 : 5,
				pb: 2,
				height: "126px",
				marginLeft: isMobile ? "-16px" : "unset",
				marginRight: isMobile ? "-16px" : "unset",
				width: isMobile ? "calc(100% + 32px)" : "100%",
			}}
			innerSx={{
				height: "100%"
			}}
		>
			<Stack sx={{
				pl: isMobile ? 4 : 10,
				height: "100%",
				color: theme.palette.primary.main,
			}} direction={"row"} alignItems={"center"} spacing={4}>
				{!!jerseyNumber &&
                    <Typography
                        variant={isMobile ? "h1" : "h1"}
                        sx={{
							fontSize: isMobile ? undefined : "110px",
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
                    >{jerseyNumber}</Typography>
				}
				<Stack direction={"row"} alignItems={"end"} spacing={isMobile ? 1 : 2}>
					<Typography
						variant={isMobile ? "h4" : "h2"}
						sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
					>{firstName}</Typography>
					<Typography
						variant={isMobile ? "h3" : "h1"}
						sx={{
							lineHeight: isMobile ? "40px" : "54px",
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
					>{lastName}</Typography>
				</Stack>
			</Stack>
			{
				player.isActive && <>
					{isMobile ? <PlayerProfileBannerNationalFlagMobile forceRight={true}/> :
						<PlayerProfileBannerNationalFlagDesktop/>}
                </>
			}
		</PaperWidthContainer>
	)
}
