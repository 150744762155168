import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {ClubColors} from "./ClubColors";
import {ClubRegion} from "./ClubRegion";
import {ClubEmblem} from "./emblem/ClubEmblem";

export function ClubSymbolsRouter() {
	return (
		<Routes>
			<Route path={"/"} element={<Navigate to={"emblem"} replace/>}/>
			<Route path="emblem" element={<ClubEmblem/>}/>
			<Route path="colors" element={<ClubColors/>}/>
			<Route path="region" element={<ClubRegion/>}/>
		</Routes>
	)
}


