import {useCompetitionViewContext} from "./CompetitionContext";
import {Box} from "@mui/material";
import {SeasonPicker} from "./SeasonPicker";
import {NavigationBar} from "../../../components/NavigationBar/NavigationBar";
import React from "react";


const tabs = [
	{label: "Tekme", url: "matches"},
	{label: "Lestvica", url: "ledger"},
	{label: "Koledar", url: "calendar"},
	{label: "Statistika", url: "statistics"},
]

export function CompetitionTopBar(props: {
	seasons: string[],
}) {
	const {seasons} = props;
	const {barHeights} = useCompetitionViewContext();

	return (
		<Box sx={{
			zIndex: 100,
			position: "fixed",
			top: barHeights.seasonPicker.start,
			width: "100%",
			backgroundColor: "#181818",
		}}>
			<SeasonPicker seasons={seasons}/>
			<NavigationBar tabs={tabs}/>
		</Box>
	)
}
