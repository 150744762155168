import {CardContent, Divider, Grid, Stack, SvgIcon, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useBreakpointContext} from "@nitra/nkmb-card-components";
import React from "react";

export function ClubStadiumInfoEmblem(props: {
	emblem: React.FC,
	upperText: string,
	mainText: string,
	lowerText: string,
	isLast?: boolean,
}) {
	const {upperText, mainText, lowerText, emblem, isLast} = props
	const theme = useTheme();

	const breakpoint = useBreakpointContext()

	const [isMobile, setIsMobile] = React.useState(false)

	React.useEffect(() => {
		setIsMobile(breakpoint === "xs" || breakpoint === "sm")
	}, [breakpoint])

	if (isMobile) return (
		<Grid item xs={12}>
			<Grid container direction={"row"} alignItems={"center"}>
				<Grid item container xs={6} alignItems={"center"}>
					<Grid item xs={3}>
						<SvgIcon component={emblem} sx={{width: 35, height: 34, opacity: 0.3}}
						         viewBox={"0 0 100 100"}/>
					</Grid>
					<Grid item xs={9}>
						<Typography variant={"bodyM"} sx={{color: "#676767"}}>{upperText}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Stack>
						<Typography variant={"h5"} sx={{color: theme.palette.primary.main}}>{mainText}</Typography>
						<Typography variant={"bodyLBold"} sx={{color: "#676767"}}>{lowerText}</Typography>
					</Stack>
				</Grid>
			</Grid>
			<Divider/>
		</Grid>
	)

	return (
		<Grid item xs={"auto"} sx={{mr: isLast ? "auto" : undefined}}>
			<CardWithShadow
				cardHeightOverride={122}
				disableOnHoverActions={true}
				sx={{
					borderRadius: "20px",
					display: "flex",
					flexDirection: "display"
				}}>
				<CardContent style={{paddingTop: 0, paddingLeft: 0}} sx={{m: 0}}>
					<Stack direction={"row"} spacing={0} alignItems={"center"} sx={{m: 1, p: 0, mr: 4}}>
						<SvgIcon
							component={emblem}
							sx={{width: 100, height: 100, opacity: 0.3}}
							viewBox={"0 0 100 100"}/>
						<Stack>
							<Typography variant={"bodyM"} sx={{color: "#676767"}}>{upperText}</Typography>
							<Typography variant={"h5"} sx={{color: theme.palette.primary.main}}>{mainText}</Typography>
							<Typography variant={"bodyLBold"} sx={{color: "#676767"}}>{lowerText}</Typography>
						</Stack>
					</Stack>
				</CardContent>
			</CardWithShadow>
		</Grid>
	)
}
