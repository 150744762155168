import {Box, Typography} from "@mui/material";
import {ArticleListFirestoreDocument, DynamicNewsCard} from "@nitra/nkmb-card-components";
import React, {useCallback, useRef} from "react";
import {CardContainerWithName, InnerWidthContainer, SmallLoader} from "../../components";
import {NewsSearchLine} from "../news/NewsSearchLine";
import useNewsSearchContext from "../news/search/NewsSearchContext";

export function FootballSchoolNewsFeed() {
	const {
		elements,
		page,
		totalPages,
		isLoading,
		callGetFunction,
	} = useNewsSearchContext<ArticleListFirestoreDocument>()


	const observer = useRef<IntersectionObserver>();
	const lastNewsElementRef = useCallback((node: any) => {
		if (observer.current) observer.current.disconnect();
		if (elements.length < 20) return
		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && (page < totalPages) && !isLoading) {
				callGetFunction();
			}
		})
		if (node) observer.current.observe(node);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elements]); // Depend on news

	return (
		<InnerWidthContainer>
			<NewsSearchLine initialYear={2024}/>
			<CardContainerWithName
				title={"Zadnje novice"}
				to={"/footballSchool/news"}
			>
				{elements.map((item, index) =>
					<Box key={item.firestoreId} ref={index === elements.length - 1 ? lastNewsElementRef : null}>
						<DynamicNewsCard
							key={item.firestoreId}
							data={item}
							index={index}
							isOnLanding={false}
							isFootballSchoolNewsArticle={true}
						/>
					</Box>
				)}
				{elements.length === 0 && !isLoading &&
                    <Typography sx={{textAlign: "center"}} variant={"bodyL"}>Ni najdenih novic</Typography>}
			</CardContainerWithName>
			<SmallLoader isLoading={isLoading}/>
		</InnerWidthContainer>
	)
}