import {ArticleMatchMetaDataFirestoreDocument} from "../../../../models";
import {toWeekdayDayAndTime} from "@nitra/nkmb-card-components";
import {Box, Stack, Typography, useTheme} from "@mui/material";
import {ArticleMatchClubEmblemNameAndEvents} from "./ArticleMatchClubEmblemNameAndEvents";

export function ArticleMatchCommonData(props: {
	matchMetaData?: ArticleMatchMetaDataFirestoreDocument
}) {
	const {matchMetaData} = props;
	const theme = useTheme()

	if (!matchMetaData) return null

	return (
		<Stack
			alignItems={"center"}
			sx={{
				backgroundColor: theme.palette.text.primary,
				pt: "60px"
			}}>
			<Typography variant={"bodyM"} sx={{color: "#676767"}}>{matchMetaData.stadium}</Typography>
			<Typography variant={"bodyM"} sx={{color: "#C3C3C3"}}>{toWeekdayDayAndTime(matchMetaData.date)}</Typography>
			<Typography variant={"bodyM"} sx={{color: "#676767"}}>{matchMetaData.viewerCount} gledalcev</Typography>

			<Stack direction={"row"}>
				<ArticleMatchClubEmblemNameAndEvents emblemUrl={matchMetaData.homeTeamEmblem}
				                                     teamName={matchMetaData.homeTeamName}
				                                     events={[...matchMetaData.homeTeamGoals, ...matchMetaData.homeTeamCards, ...matchMetaData.homeTeamSubstitutions]}/>
				<Box sx={{pt: "40px"}}>
					{
						matchMetaData.afterMatchPenaltyScore ? <Stack alignItems={"center"}>
								<Typography
									variant={"bodyMobile"}
									sx={{
										lineHeight: "25px",
										color: theme.palette.background.default
									}}
								>
									{matchMetaData.score}
								</Typography>
								<Typography
									variant={"bodyL"}
									sx={{
										color: theme.palette.secondary.main,
										lineHeight: "25px"
									}}
								>
									{matchMetaData.afterMatchPenaltyScore}
								</Typography>
							</Stack>
							:
							<Typography sx={{color: theme.palette.background.paper}}
							            variant={"h3"}>{matchMetaData.score}</Typography>
					}

				</Box>
				<ArticleMatchClubEmblemNameAndEvents emblemUrl={matchMetaData.awayTeamEmblem}
				                                     teamName={matchMetaData.awayTeamName}
				                                     events={[...matchMetaData.awayTeamGoals, ...matchMetaData.awayTeamCards, ...matchMetaData.awayTeamSubstitutions]}/>
			</Stack>


			<Stack direction={"row"}>

			</Stack>
		</Stack>
	)
}

