import {Button, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useCurrentSeasonContext} from "@nitra/nkmb-card-components";
import {ENationality, NationFlagRound} from "@nitra/nkmb-theme";
import React from "react";
import {Link} from "react-router-dom";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function LandingPermanentPlayerCardLarge(props: {
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	imageUrl: string,
	scale: number,
	playerNumber: string,
	firstName: string,
	lastName: string,
	playerKey: string,
	nationality: ENationality
}) {
	const {
		onMouseLeave,
		onMouseEnter,
		imageUrl,
		scale,
		playerNumber,
		firstName,
		lastName,
		playerKey,
		nationality
	} = props;

	const {currentSeasonString} = useCurrentSeasonContext()
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<CardWithShadow
			to={`/team/player/${playerKey}/profile`}
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				p: 0,
				m: 0,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<img
				src={imageUrl}
				alt={"igralec"}
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					bottom: 0,
					height: "100%",
					objectFit: "contain",
					transform: `scale(${scale})`,
					transition: "transform 1.5s",
				}}/>
			<div style={{
				position: "absolute",
				top: 0,
				left: 0,
				backgroundImage: "url(/images/logos/clubs/nkmaribor.png)",
				backgroundSize: "contain",
				width: "100px",
				height: "100px",
				zIndex: 1,
				transform: `scale(${scale})`,
				transition: "transform 1.5s",
			}}/>
			<CardContent sx={{
				zIndex: 1,
				display: "flex",
				height: "100%",
				p: 2
			}}>
				<NationFlagRound
					countryCode={nationality}
					sx={{
						position: "absolute",
						zIndex: 1,
						top: 0,
						right: 0,
					}}
					svgProps={{
						width: "60px",
						height: "60px",
					}}
				/>
				<Stack direction={"column"} alignItems={"flex-start"} justifyContent={"flex-end"}
				       sx={{flexGrow: 1}}>

					<Stack>
						<Typography
							variant={"h1"}
							sx={{
								maxWidth: "220px",
								[theme.breakpoints.down("sm")]: isIos && {
									'@media (-webkit-min-device-pixel-ratio: 2)': {
										fontWeight: 500, // Adjust font weight for iPhones
									},
								},
							}}>
							{playerNumber}
						</Typography>
						<Typography variant={"podnaslovM"}>
							{firstName}
						</Typography>
						<Typography variant={"podnaslovM"}>
							{lastName}
						</Typography>
					</Stack>
					<Button
						component={Link}
						to={"/team"}
						variant={"contained"}

						sx={{
							px: "24px",
							py: "12px",
							borderRadius: 40,
							mt: 5
						}}
					>
						<Typography variant={"gumbL"}>
							Ekipa {currentSeasonString}
						</Typography>
					</Button>
				</Stack>
			</CardContent>
		</CardWithShadow>
	)
}
