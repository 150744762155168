import {
	ECompetitionType,
	useCurrentSeasonContext,
	useSeasonContext,
	useSeasonSettings
} from "@nitra/nkmb-card-components";
import {isEuroCompetitionType} from "@nitra/nkmb-card-components/enums/ECompetitionType";
import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useCompetitionFilterButtonsContext
	from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";
import {getPlayerStatsForProfile} from "../../../firestore/BaseFirestore";
import {
	PlayerCurrentSeasonStatsFirebaseDocument,
	PlayerPlayedMatchStatsFirebaseDocument,
	PlayerProfileMatchFirebaseDocument,
	PlayerStatsFirebaseDocument
} from "../../../models";

const PlayerStatsContext = createContext<{
	dataLoaded: boolean,

	firstMatch?: PlayerProfileMatchFirebaseDocument,
	lastMatch?: PlayerProfileMatchFirebaseDocument,

	seasonStats: PlayerCurrentSeasonStatsFirebaseDocument,

	matches: PlayerPlayedMatchStatsFirebaseDocument[],
	/**
	 * This is used to show all data in the player stats page, for instance players that played before 1991
	 * do not have all data and therefore should now attempt to show it
	 */
	showFullData: boolean,
} | null>(null);

export function PlayerStatsContextProvider(props: PropsWithChildren) {
	const {key} = useParams()

	const [data, setData] = useState<PlayerStatsFirebaseDocument | undefined>(undefined);
	const [seasonStats, setSeasonStats] = useState<PlayerCurrentSeasonStatsFirebaseDocument>({
		appearances: 0,
		firstElevenCount: 0,
		subInCount: 0,
		subOutCount: 0,
		minutesPlayed: 0,
		goals: 0,
		assists: 0,
		yellowCards: 0,
		redCards: 0,
	})
	const [filteredMatches, setFilteredMatches] = useState<PlayerPlayedMatchStatsFirebaseDocument[]>([])
	const [showFullData, setShowFullData] = useState(false)
	const {filteredCompetitions} = useCompetitionFilterButtonsContext()
	const {
		setAllowAllSeasons,
		setFirstSeasonOverride,
		setLastSeasonOverride,
		setAvailableSeasonsOverride,
	} = useSeasonSettings()
	const {season, setSeason} = useSeasonContext()
	const {currentSeasonNumber} = useCurrentSeasonContext()

	function getData(_key: string) {
		getPlayerStatsForProfile(_key).then((data) => setData(data))
	}

	useEffect(() => {
		if (data === undefined) return
		const seasons = data.seasons.map((season) => season.season)
		setAllowAllSeasons(true)
		setFirstSeasonOverride(undefined)
		setLastSeasonOverride(undefined)
		setAvailableSeasonsOverride(seasons)

		const potentialCurrentSeason = seasons.filter((a) => a === Math.round(currentSeasonNumber / 10000))
		if (potentialCurrentSeason.length > 0) {
			setSeason(potentialCurrentSeason[0], true)
		}

		const matches: PlayerPlayedMatchStatsFirebaseDocument[] = []
		data.seasons.forEach(season =>
			season.matches.forEach(match => matches.push(match))
		)
		if (seasons.filter(season => season !== 42 && season < 1991).length > 0) {
			setShowFullData(false)
		} else {
			setShowFullData(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (data === undefined) return;

		let selectedSeasonMatches: PlayerPlayedMatchStatsFirebaseDocument[] = []
		data.seasons.forEach(item => {
			if (season === 42 || item.season === season) {
				item.matches.forEach(match => selectedSeasonMatches.push(match))
			}
		})

		selectedSeasonMatches = selectedSeasonMatches.filter(match => {
			if (filteredCompetitions.length === 0) return true
			if (filteredCompetitions.includes(ECompetitionType.ANY_UEFA)) {
				return isEuroCompetitionType(match.competitionType)
			}
			return filteredCompetitions.includes(match.competitionType)
		})

		setSeasonStats({
			appearances: selectedSeasonMatches.length,
			firstElevenCount: selectedSeasonMatches.filter(match => match.isFirstEleven).length,
			subInCount: selectedSeasonMatches.filter(match => match.subbedIn).length,
			subOutCount: selectedSeasonMatches.filter(match => match.subbedOut).length,
			minutesPlayed: selectedSeasonMatches.reduce((acc, match) => acc + match.minutesPlayed, 0),
			goals: selectedSeasonMatches.reduce((acc, match) => acc + match.goalCount, 0),
			assists: selectedSeasonMatches.reduce((acc, match) => acc + match.assistCount, 0),
			yellowCards: selectedSeasonMatches.reduce((acc, match) => acc + (match.gotYellowCard ? 1 : 0), 0),
			redCards: selectedSeasonMatches.reduce((acc, match) => acc + (match.gotRedCard ? 1 : 0), 0),
		})

		setFilteredMatches(selectedSeasonMatches)
	}, [season, filteredCompetitions, data]);


	useEffect(() => {
		if (key === undefined) return
		getData(key)
	}, [key])

	return (
		<PlayerStatsContext.Provider value={{
			dataLoaded: data !== undefined,
			lastMatch: data?.lastMatch,
			firstMatch: data?.firstMatch,
			seasonStats: seasonStats,
			matches: filteredMatches,
			showFullData: showFullData
		}}>
			{props.children}
		</PlayerStatsContext.Provider>
	)
}

export default function usePlayerStatsContext() {
	const context = useContext(PlayerStatsContext);
	if (context === null) {
		throw new Error('usePlayerStatsContext must be used within an PlayerStatsContext');
	}
	return context;
}
