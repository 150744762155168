import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";
import {mapAndSortContent} from "firestore/mappers/ContentMapper";
import {
	StadiumLegendaryMatchFullFirestoreDocument,
	StadiumLegendaryMatchListContainerFirestoreDocument
} from "models/firestore/pageCache";

export async function GetStadiumLegendaryMatches(): Promise<StadiumLegendaryMatchListContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache'), 'stadium_legendary_matches'));

	if (!snapshot.exists()) throw new Error('Stadium page not found');

	return {
		matches: snapshot.data().matches.map((match: any) => {
				return {
					...match,
					date: match.date.toDate(),
				}
			}
		)
	} as StadiumLegendaryMatchListContainerFirestoreDocument;
}

export async function getStadiumLegendaryMatch(key: string): Promise<StadiumLegendaryMatchFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/stadium_legendary_matches/stadiumLegendaryMatches'), key));

	if (!snapshot.exists()) throw new Error('Stadium match not found');

	return {
		...snapshot.data(),
		date: snapshot.data().date.toDate(),
		content: mapAndSortContent(snapshot.data().content),
	} as StadiumLegendaryMatchFullFirestoreDocument;
}
