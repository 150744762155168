import {Box} from "@mui/material";
import {EMatchOutcome} from "models";
import {PropsWithChildren} from "react";

const MatchOutcomeBadge = (props: PropsWithChildren<{ outcome: EMatchOutcome }>) => {
	const outcome = props.outcome;

	return (
		<Box sx={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "14px",
			height: "14px",
			backgroundColor: outcome === EMatchOutcome.WIN ? "primary.main" : outcome === EMatchOutcome.DRAW ? "text.secondary" : "secondary.main",
			color: "background.paper",
			borderRadius: "0%",
			fontSize: "10px",
			fontWeight: "bold",
			marginInline: "5px",
		}}>
			{outcome === EMatchOutcome.WIN ? "Z" : outcome === EMatchOutcome.DRAW ? "N" : "P"}
		</Box>
	)
}
export default MatchOutcomeBadge
