import {Box} from "@mui/material";
import {EmbedContentElementPageCacheFirebaseDocument} from "models";
import {useEffect} from "react";

export function EmbedContent(props: { element: EmbedContentElementPageCacheFirebaseDocument }) {
	const {embedCode} = props.element;

	useEffect(() => {
		// @ts-ignore
		if (embedCode.includes("https://www.instagram.com") && window.instgrm) {
			// @ts-ignore
			window.instgrm.Embeds.process();
		}
	}, [embedCode]);

	return (
		<Box sx={{width: "100%"}}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
				dangerouslySetInnerHTML={{__html: embedCode}}
			/>
		</Box>
	)
}
