import {ChevronRight} from "@mui/icons-material";
import {Button, Grid, Stack, Typography, useTheme} from "@mui/material";
import {
	BasicCardDto,
	CalendarCardWithLogic,
	MatchCardDto,
	SingleMatchCard,
	TimeManagementContextProvider,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import {getSeasonMatches} from "firestore/BaseFirestore";
import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import {CardContainerWithoutName} from "../../components";


const LandingMatches = (props: PropsWithChildren<{ matches: BasicCardDto[] }>) => {
	const {matches} = props;

	const isMobile = useIsMobileContext()
	const theme = useTheme();


	return (
		<InnerWidthContainer
			sx={{
				pt: 3,
			}}>
			<Stack
				direction={"row"}
				alignItems={"center"}
				justifyContent={isMobile ? "space-between" : "flex-start"}
				sx={{pb: isMobile ? 1 : 3}}
			>
				<Typography
					variant={isMobile ? "podnaslovXS" : "section"}
				>Tekmovanja</Typography>
				<Button
					component={Link}
					to={"/competitions/matches"}
					sx={{
						pl: 4,
						py: 0,
						pr: 2,
						':hover': {
							backgroundColor: theme.palette.background.paper,
						}
					}}>
					<Typography sx={{color: theme.palette.text.primary}} variant={isMobile ? "gumbS" : "gumbL"}>Koledar
						sezone</Typography><ChevronRight/>
				</Button>
			</Stack>
			<CardContainerWithoutName
				sx={{
					pb: 1,
				}}
				innerSx={{
					flexWrap: "nowrap",
					pb: 4,
					overflow: "visible",
					overflowX: "auto",
					justifyContent: "flex-start",
				}}>
				{/* TODO make sure there are always 3 matches here, (if there are non left in the season display last 3?) */}
				{matches.slice(0, 3).map((match, index) =>
					<SingleMatchCard
						key={index}
						{...match as MatchCardDto}
					/>
				)}
				{!isMobile &&
                    <Grid item xs={3} sx={{minWidth: "288px"}}>
                        <TimeManagementContextProvider
                            getterFunction={getSeasonMatches}
                            limitToCurrentSeason={true}
                        >
                            <CalendarCardWithLogic/>
                        </TimeManagementContextProvider>
                    </Grid>
				}
			</CardContainerWithoutName>
		</InnerWidthContainer>
	)
}

export default LandingMatches
