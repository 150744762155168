import {Stack} from "@mui/material";
import {MatchCallToActionButton, MatchListElementFirestoreDocument} from "@nitra/nkmb-card-components";

export function MatchContainerRightColumn(props: { match: MatchListElementFirestoreDocument }) {
	const {reportId, liveTickerKey, buyTicketLink} = props.match;

	return <Stack
		sx={{
			ml: "auto",
			flexDirection: "row-reverse",
			maxWidth: "250px",
			width: "100%",
			height: "96px"
		}}>
		<MatchCallToActionButton
			reportId={reportId}
			liveTickerKey={liveTickerKey}
			buyTicketLink={buyTicketLink}
		/>
	</Stack>;
}
