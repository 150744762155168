import {SupportStaffContainerFirestoreDocument} from "../../models";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../BaseFirestore";

export async function getSupportStaff(): Promise<SupportStaffContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'support_staff'));

	if (!snapshot.exists()) throw new Error('Support staff page not found');

	return {
		staff: snapshot.data().staff
	} as SupportStaffContainerFirestoreDocument;
}