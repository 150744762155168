import React, {PropsWithChildren} from "react";
import {Box, SxProps} from "@mui/material";

export function BoxWithFullWidthBackground(props: PropsWithChildren<{
	backgroundColor: string,
	sx?: SxProps,
}>) {
	const {backgroundColor} = props

	return (
		<Box sx={{
			position: "relative",
			width: "100%",
			...props.sx,
		}}>
			<Box sx={{
				position: "absolute",
				top: 0,
				left: "-50vw",
				right: "-50vw",
				height: "100%",
				backgroundColor: backgroundColor,
			}}/>
			{props.children}
		</Box>
	)
}
