import {Box, Stack, Typography, useTheme} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function DebugTypographies() {
	const {isIos} = useIosContext()
	const theme = useTheme();
	return (
		<InnerWidthContainer>
			<Stack>
				<Typography variant={"h1"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>Headline h1</Typography>
				<Typography variant={"h2"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>Headline h2</Typography>
				<Typography variant={"h3"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>Headline h3</Typography>
				<Typography variant={"h4"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>Headline h4</Typography>
				<Typography variant={"h5"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>Headline h5</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"section"}>section</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"podnaslovL"}>Podnaslov L</Typography>
				<Typography variant={"podnaslovM"}>Podnaslov M</Typography>
				<Typography variant={"podnaslovS"}>Podnaslov S</Typography>
				<Typography variant={"podnaslovXS"}>Podnaslov XS</Typography>
				<Typography variant={"podnaslovXS1"}>Podnaslov XS2 (xs1)</Typography>
				<Typography variant={"podnaslovXSmale"}>podnaslov xs male</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"bodyNaslovBoldUppercase"}>Body naslov bold (uppercase)</Typography>
				<Typography variant={"bodyNaslovBold"}>Body naslov bold</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"bodyMobile"}>Body Mobile</Typography>
				<Box sx={{py: 2}}/>
				<Stack direction={"row"}>
					<Stack>
						<Typography variant={"bodyXL"}>Body XL</Typography>
						<Typography variant={"bodyL"}>Body L</Typography>
						<Typography variant={"bodyM"}>Body M</Typography>
						<Typography variant={"bodyS"}>Body S</Typography>
					</Stack>
					<Stack sx={{pl: 5}}>
						<Typography variant={"bodyXLBold"}>Body XL bold</Typography>
						<Typography variant={"bodyLBold"}>Body L bold</Typography>
						<Typography variant={"bodyMBold"}>Body M bold</Typography>
						<Typography variant={"bodySBold"}>Body S bold</Typography>
					</Stack>
				</Stack>
				<Box sx={{py: 2}}/>
				<Typography variant={"podnapis"}>Podnapis</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"gumbL"}>Gumb L</Typography>
				<Typography variant={"gumbS"}>Gumb S</Typography>
				<Typography variant={"gumbSthin"}>Gumb S thin</Typography>
				<Box sx={{py: 2}}/>
				<Typography variant={"meni"}>meni</Typography>


			</Stack>
		</InnerWidthContainer>
	)
}
