import React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {MoreContactDesktop} from "./contact/MoreContactDesktop";
import {MoreContactMobile} from "./contact/MoreContactMobile";

export function MoreContact() {
	const isMobile = useIsMobileContext();

	return isMobile ? <MoreContactMobile/> : <MoreContactDesktop/>;
}


