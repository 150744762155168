import {Box, Stack} from "@mui/material";
import {
	CalendarCardWithoutLogic,
	TimeManagementContextProvider,
	useTimeManagementContext
} from "@nitra/nkmb-card-components";
import React, {useEffect} from "react";
import {getSeasonMatches} from "../../../firestore/BaseFirestore";

export function CurrentStandingsCalendarCards() {
	const ContextNeedingPart = () => {
		const {month, year} = useTimeManagementContext();

		const [earlierMonth, setEarlierMonth] = React.useState<number>(month - 1);
		const [laterMonth, setLaterMonth] = React.useState<number>(month + 1);

		const [earlierYear, setEarlierYear] = React.useState<number>(year);
		const [laterYear, setLaterYear] = React.useState<number>(year);

		useEffect(() => {
			if (month === 0) {
				setEarlierMonth(11);
				setEarlierYear(year - 1);
			} else if (month === 11) {
				setLaterMonth(0);
				setLaterYear(year + 1);
			} else {
				setEarlierMonth(month - 1);
				setLaterMonth(month + 1);
				setEarlierYear(year);
				setLaterYear(year);
			}
		}, [month, year])

		return (
			<>
				<Box sx={{width: "288px"}}>
					<CalendarCardWithoutLogic month={earlierMonth} year={earlierYear}/>
				</Box>
				<Box sx={{width: "288px"}}>
					<CalendarCardWithoutLogic month={month} year={year}/>
				</Box>
				<Box sx={{width: "288px"}}>
					<CalendarCardWithoutLogic month={laterMonth} year={laterYear}/>
				</Box>
			</>
		)
	}

	return (
		<Stack direction={"row"} spacing={3}>
			<TimeManagementContextProvider getterFunction={getSeasonMatches} limitToCurrentSeason={true}>
				<ContextNeedingPart/>
			</TimeManagementContextProvider>
		</Stack>
	)
}
