import {
	ContentElementPageCacheFirebaseDocument,
	EContentType,
	EmbedContentElementPageCacheFirebaseDocument,
	ImageContentElementPageCacheFirebaseDocument,
	TextContentElementPageCacheFirebaseDocument,
	VideoContentElementPageCacheFirebaseDocument
} from "models";

export function mapAndSortContent(payload: any[]): ContentElementPageCacheFirebaseDocument[] {
	return payload.map((element: ContentElementPageCacheFirebaseDocument) => {
		let data
		switch (element.type) {
			case EContentType.TEXT:
				data = {
					text: (element as TextContentElementPageCacheFirebaseDocument).text,
				}
				break;
			case EContentType.IMAGE:
				data = {
					imageUrl: (element as ImageContentElementPageCacheFirebaseDocument).imageUrl,
					imageAltText: (element as ImageContentElementPageCacheFirebaseDocument).imageAltText,
					imageCaption: (element as ImageContentElementPageCacheFirebaseDocument).imageCaption,
					imageCredit: (element as ImageContentElementPageCacheFirebaseDocument).imageCredit,
				}
				break;
			case EContentType.VIDEO:
				data = {
					youtubeId: (element as VideoContentElementPageCacheFirebaseDocument).youtubeId,
					videoThumbnailUrl: (element as VideoContentElementPageCacheFirebaseDocument).videoThumbnailUrl,
				}
				break;
			case EContentType.EMBED:
				data = {
					embedCode: (element as EmbedContentElementPageCacheFirebaseDocument).embedCode,
				}
				break;
			default:
				throw new Error("Unknown content type")
		}

		return {
			type: element.type,
			index: element.index,
			...data
		}
	}).sort((a: ContentElementPageCacheFirebaseDocument, b: ContentElementPageCacheFirebaseDocument) => a.index - b.index)
}
