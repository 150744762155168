import {Chip, FormControl, MenuItem, Select, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {selections} from "./FootballSchoolRouter";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {NkmbArrowBasicDown} from "@nitra/nkmb-theme";

export function FootballSchoolNavigator() {
	const navigate = useNavigate();
	const {pathname} = useLocation()
	const isMobile = useIsMobileContext()

	const [currentSelection, setCurrentSelection] = useState<string | null>(null)

	useEffect(() => {
		if (pathname.includes("selection")) {
			selections.forEach(selection => {
				if (pathname.includes(selection)) {
					setCurrentSelection(selection)
				}
			})
		} else {
			if (pathname.includes("primarySchool")) {
				setCurrentSelection("onš")
			} else {
				setCurrentSelection("nš")
			}
		}
	}, [pathname])

	const handleClick = (selection: string) => {
		if (selection === "nš") {
			navigate("/footballSchool")
		} else if (selection === "onš") {
			navigate("/footballSchool/primarySchools")
		} else {
			navigate(`/footballSchool/selection/${selection}`)
		}
	}

	return isMobile ? (
			<FormControl sx={{pb: 2, ml: -1.5}}>
				<Select
					value={currentSelection}
					sx={{
						borderColor: "#181818",
						height: "32px",
						color: "white",
						'.MuiSelect-icon ': {
							color: "white",
							height: "18px",
							width: "18px"
						},
						'.MuiOutlinedInput-notchedOutline': {
							border: "0",
						},
					}}
					MenuProps={{
						style: {
							maxHeight: 300,
							color: "text.secondary",
						}
					}}
					IconComponent={NkmbArrowBasicDown}

				>
					{selections.map((selection, index) =>
						<MenuItem key={index} value={selection}
						          onClick={() => handleClick(selection)}>{selection.toUpperCase()}</MenuItem>
					)}
				</Select>
			</FormControl>
		) :

		(
			<Stack direction={"row"} sx={{justifyContent: "space-between", maxWidth: "735px", mx: "auto"}}>
				{selections.map((selection, index) =>
					<Chip
						key={index}
						label={selection.toUpperCase()}
						onClick={() => handleClick(selection)}
						variant={currentSelection === selection ? "filled" : "outlined"}
					/>
				)}
			</Stack>
		)
}