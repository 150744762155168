import {Stack, Typography} from "@mui/material";
import {ECompetitionType, getCompetitionNameFromType} from "@nitra/nkmb-card-components";
import {useEffect, useState} from "react";

/**
 * Left most column of wide match card
 * @param props
 * @constructor
 */
export function MatchContainerTimeAndDate(props: {
	match: {
		round?: number,
		roundString?: string,
		date: Date,
		stadium?: string,
		competition: ECompetitionType,
		isDone: boolean
	}
}) {
	const {round, date, stadium, competition, roundString, isDone} = props.match;

	const [roundLabel, setRoundLabel] = useState<string>("");

	useEffect(() => {
		if (round !== null && competition === ECompetitionType.FIRST_LEAGUE) {
			setRoundLabel(`${round}. krog`)
		} else if (roundString != null) {
			setRoundLabel(roundString)
		} else {
			setRoundLabel("")
		}

		// if (round !== null && competition === ECompetitionType.CUP) {
		// 	if (round === 7) {
		// 		setRoundLabel("Finale")
		// 	} else if (round === 6) {
		// 		setRoundLabel("1/2 finale")
		// 	} else if (round === 5) {
		// 		setRoundLabel("1/4 finala")
		// 	} else if (round === 4) {
		// 		setRoundLabel("1/8 finala")
		// 	} else if (round === 3) {
		// 		setRoundLabel("1/16 finala")
		// 	} else if (round === 2) {
		// 		setRoundLabel("1/32 finala")
		// 	} else {
		// 		setRoundLabel(`${round}. krog`)
		// 	}
		// } else if (round == null && roundString != null) {
		// 	setRoundLabel(roundString)
		// } else if (round !== null) {
		// 	setRoundLabel(`${round}. krog`)
		// } else {
		// 	setRoundLabel("")
		// }
	}, [round, competition, roundString])

	return (
		<Stack spacing={0} sx={{maxWidth: "250px", width: "100%"}}>
			{!!roundLabel && <Typography variant={"bodyS"} sx={{pb: "12px"}}>{roundLabel}</Typography>}
			{
				((date.getHours() > 8) || isDone) &&
                <Typography variant={"bodyLBold"} sx={{
					lineHeight: "18px",
					pt: !!roundLabel ? "unset" : "27px",
				}}>{date.toLocaleString("sl-sl", {
					weekday: "long",
					day: "2-digit",
					month: "long",
					year: "numeric"
				})}</Typography>
			}
			{stadium && <Typography variant={"bodyL"}>{stadium}</Typography>}
			<Typography variant={"bodyS"}
			            sx={{pt: !!stadium ? "unset" : "37px"}}>{getCompetitionNameFromType(competition)}</Typography>
		</Stack>
	)
}
