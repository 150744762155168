import {Box, Stack, SxProps, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MatchListElementFirestoreDocument, useTimeManagementContext} from "@nitra/nkmb-card-components";

import {areDatesEqual} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/AreDatesEqual";
import {CalendarCardDay} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/CalendarCardDay";
import {useNavigate} from "react-router-dom";

export function BigCalendarDayBoxDesktop(props: {
	calendarDay: CalendarCardDay;
	prominentMonth: number;
	sx?: SxProps;
}) {
	const {calendarDay, sx, prominentMonth} = props;
	const {date,} = calendarDay;

	const {matches} = useTimeManagementContext();

	const [match, setMatch] = useState<MatchListElementFirestoreDocument | undefined>(undefined);

	useEffect(() => {
		const match = matches.find(match => areDatesEqual(match.date, date));
		setMatch(match);
	}, [date, matches])

	const theme = useTheme();
	const navigate = useNavigate();

	const dayOfMonth = date.getDate();

	function handleClick() {
		if (match) {
			const {buyTicketLink, reportId, liveTickerKey} = match

			if (reportId) {
				navigate("/news/" + reportId)
			} else if (liveTickerKey) {
				navigate("/live-ticker/" + liveTickerKey)
			} else if (buyTicketLink) {
				window.open(buyTicketLink, "_blank")
			}
		}

	}

	return (
		<Box
			sx={{
				position: "relative",
				height: "80px",
				width: "180px",
				display: "flex",
				alignItems: "center",
				opacity: date.getMonth() === prominentMonth ? 1 : 0.2,
				cursor: (match?.reportId || match?.buyTicketLink || match?.liveTickerKey) ? "pointer" : "default",
				...sx,
			}}
		>
			<Box
				sx={{
					borderRadius: "50%",
					width: "40px",
					height: "40px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",

					position: "absolute",
					zIndex: 1,
					left: 0,
					backgroundColor: match ? theme.palette.secondary.main : "transparent",
					border: "2px solid transparent",
				}}
			>
				<Typography variant={"podnaslovS"}>{dayOfMonth}</Typography>
			</Box>
			{match && (
				<Box
					onClick={() => handleClick()}
					sx={{
						position: "absolute",
						border: "2px solid",
						borderColor: theme.palette.secondary.main,
						top: 0,
						right: 0,
						bottom: 0,
						left: 20,
						borderRadius: "16px",
					}}>
					<Stack sx={{pl: "28px", pr: 1, height: "100%", color: "#676767"}} justifyContent={"center"}>
						<Typography noWrap variant={"bodyLBold"}>{match.homeTeamName}</Typography>
						<Typography noWrap variant={"bodyLBold"}>{match.awayTeamName}</Typography>
						<Typography noWrap variant={"bodyS"}>{match.stadium}</Typography>
					</Stack>
				</Box>
			)}
		</Box>
	)
}
