import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowBird() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/bird.png"}
			title={"3. \"Golob\""}
			text={"Iz grba mesta Maribor je v klubski grb prenesen tudi simbol ptiča. Nekoč so menili, da gre za orla, pa se nato sredi 16. stoletja poenotili, da je ptič v mariborskem grbu golob, ki simbolizira sv. Duha, kako se spušča z neba proti zemlji."}
		/>
	)
}
