import {Box, SxProps} from "@mui/material";
import {PropsWithChildren} from "react";

/**
 * Container for text width control elements
 */
export function TextWidthContainer(props: PropsWithChildren<{
	sx?: SxProps,
	innerSx?: SxProps,
}>) {
	return (
		<Box sx={{
			maxWidth: "100%",
			...props.sx
		}}>
			<Box sx={{
				mx: "auto",
				maxWidth: "734px",
				...props.innerSx
			}}>
				{props.children}
			</Box>
		</Box>
	)
}
