import {Grid, Typography, useTheme} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {CardWithShadow} from "@nitra/nkmb-card-components";
import typography from "@nitra/nkmb-theme/typography";
import ClubStandingsFirestoreDocument from "models/firestore/common/ClubStandingsFirestoreDocument";
import React from "react";
import {getIconFromMomentum} from "./GetIconFromMomentum";
import MatchOutcomeBadge from "./MatchOutcomeBadge";

/**
 * DataGrid containing the standings of the league
 * @param props
 * @constructor
 */
export function CurrentStandingsDesktopGrid(props: { clubs: ClubStandingsFirestoreDocument[] }) {
	const {clubs} = props;
	const theme = useTheme();

	const columns = [
		{
			field: "momentum",
			headerName: "",
			width: 40,
			minWidth: 40,
			maxWidth: 40,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) => getIconFromMomentum(params.row.momentum),
		},
		{
			field: "place",
			headerName: "#",
			width: 40,
			minWidth: 40,
			maxWidth: 40,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<Typography variant={"podnaslovM"}>{params.row.place}</Typography>
		},
		{
			field: "clubName",
			headerName: "Klub",
			minWidth: 200,
			flex: 1,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<span>
					<Grid container direction={"row"} alignItems={"center"}>
						<img src={params.row.metaData.emblemUrl}
						     alt={params.row.metaData.shortName}
						     style={{
							     width: 40,
							     height: 40,
							     paddingRight: 8
						     }}/>
						<Typography variant={"bodyL"}>
						{params.row.metaData.shortName.toUpperCase()}
					</Typography>
						</Grid>
				</span>
		},
		{
			field: "played",
			headerName: "T",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "wins",
			headerName: "Z",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "draws",
			headerName: "N",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "losses",
			headerName: "P",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "goalsFor",
			headerName: "+",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "goalsAgainst",
			headerName: "-",
			width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyL"}>{params.value}</Typography>
		},
		{
			field: "goalDifference",
			headerName: "+/-",
			width: 30,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<Typography variant={"bodyL"}>{params.row.goalsFor - params.row.goalsAgainst}</Typography>
		},
		{
			field: "points", headerName: "T", width: 30,
			renderCell: (params: GridRenderCellParams<number>) => <Typography
				variant={"bodyLBold"}>{params.value}</Typography>
		},
		{
			field: "lastFive", headerName: "Zadnjih 5 tekem", width: 144,
			renderCell: (params: { row: ClubStandingsFirestoreDocument }) =>
				<div style={{display: "flex", flexDirection: "row"}}>
					{params.row.lastFiveOutcomes.map((outcome, index) =>
						<MatchOutcomeBadge key={index} outcome={outcome}/>)}
				</div>
		},
	];

	return (
		<CardWithShadow disableOnHoverActions={true} sx={{mt: 2}} cardHeightOverride={58 + clubs.length * 52}>
			<DataGrid columns={columns}
			          rows={clubs}
			          getRowId={(row) => row.metaData.key}
			          hideFooterPagination={true}
			          hideFooter={true}
			          autoHeight={true}
			          disableColumnMenu={true}
			          sx={{
				          '& .MuiDataGrid-columnSeparator': {
					          display: "none"
				          },
				          '& .MuiDataGrid-columnHeaders': {
					          backgroundColor: theme.palette.background.paper,
				          },
				          '& .MuiDataGrid-columnHeaderTitle': {
					          ...typography.bodySBold,
					          color: "#676767",
				          },
				          backgroundColor: theme.palette.background.default,
				          borderRadius: 2,
			          }}
			/>
		</CardWithShadow>
	)
}
