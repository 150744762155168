import {createContext, PropsWithChildren, useContext, useState} from "react";
import {ECompetitionType} from "@nitra/nkmb-card-components";

const CompetitionFilterButtonsContext = createContext<{
	filteredCompetitions: ECompetitionType[]
	addCompetition: (competition: ECompetitionType) => void
	removeCompetition: (competition: ECompetitionType) => void
} | null>(null);

export default function useCompetitionFilterButtonsContext() {
	const context = useContext(CompetitionFilterButtonsContext);
	if (!context) {
		throw new Error("useCompetitionFilterButtonsContext must be used within a CompetitionFilterButtonsContextProvider");
	}
	return context;
}

export function CompetitionFilterButtonsContextProvider(props: PropsWithChildren<{
	allowMultipleSelection: boolean
}>) {
	const {allowMultipleSelection} = props;

	const [filteredCompetitions, setFilteredCompetitions] = useState<ECompetitionType[]>([]);

	function addCompetition(competition: ECompetitionType) {
		if (allowMultipleSelection) {
			setFilteredCompetitions([...filteredCompetitions, competition]);
		} else {
			setFilteredCompetitions([competition]);
		}
	}

	function removeCompetition(competition: ECompetitionType) {
		setFilteredCompetitions(filteredCompetitions.filter((comp) => comp !== competition));
	}

	return (
		<CompetitionFilterButtonsContext.Provider value={{
			filteredCompetitions,
			addCompetition,
			removeCompetition
		}}>
			{props.children}
		</CompetitionFilterButtonsContext.Provider>
	)
}