import {Search as SearchIcon} from "@mui/icons-material";
import {Box, ClickAwayListener, IconButton, InputBase, Popper, useTheme} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

export const SearchBar = React.forwardRef<HTMLButtonElement, {}>(({}, ref) => {
		const theme = useTheme();
		const navigate = useNavigate()

		const [search, setSearch] = React.useState("");

		const [isOpen, setIsOpen] = useState(false);
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
			setIsOpen((prevOpen) => !prevOpen);
		};

		const handleClose = () => {
			setIsOpen(false);
			setSearch("");
		};

		return (
			<React.Fragment>
				<IconButton
					color="inherit"
					aria-label="open search"
					edge="end"
					sx={{
						mr: 2,
						pr: {
							xs: 3,
							md: 3,
							// lg: 0,
						},
						flexShrink: 0,
					}}
					onClick={handleClick}
					ref={ref}
				>
					<SearchIcon/>
				</IconButton>
				<Popper open={isOpen} anchorEl={anchorEl} placement="bottom-end" sx={{zIndex: 11111}}>
					<ClickAwayListener onClickAway={handleClose}>
						<Box
							sx={{
								backgroundColor: theme.palette.background.paper,
								borderRadius: theme.shape.borderRadius,
								padding: theme.spacing(1),
								minWidth: 200,
							}}
						>
							<InputBase
								autoFocus
								sx={{
									color: 'inherit',
									width: "100%",
									'& .MuiInputBase-input': {
										padding: theme.spacing(1, 1, 1, 0),
										transition: theme.transitions.create('width'),
										width: '100%',
									},
								}}
								placeholder="Iskanje…"
								inputProps={{'aria-label': 'search'}}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								onKeyUp={(event) => {
									if (event.key === 'Enter') {
										navigate("/news/feed?search=" + search)
										handleClose()
									}
								}}
							/>
						</Box>
					</ClickAwayListener>
				</Popper>
			</React.Fragment>
		)
	}
)
