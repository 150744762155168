import {Box, Stack, Typography} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";

import {ClubStatisticsMatchFirestoreDocument, ClubStatisticsSeasonStandingFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {DesktopStatisticsSeasonSelector} from "views/club/statistics/desktop/DesktopStatisticsSeasonSelector";
import {getClubStatistics} from "../../../../../firestore/specificGetters/StatisticsFirestore";
import {DesktopClubStatisticsMatchList} from "./DesktopClubStatisticsMatchList";
import {DesktopClubStatisticsStandings} from "./DesktopClubStatisticsStandings";
import {DesktopClubStatisticsSelectedMatchContainer} from "./DesktopClubStatisticsSelectedMatchContainer";
import {useSeasonContext, useSeasonSettings} from "@nitra/nkmb-card-components";


export function DesktopClubStatistics() {
	const [name, setName] = useState("");
	const [standings, setStandings] = useState<ClubStatisticsSeasonStandingFirestoreDocument[]>([])
	const [matches, setMatches] = useState<ClubStatisticsMatchFirestoreDocument[]>([])
	const [selectedMatch, setSelectedMatch] = useState<ClubStatisticsMatchFirestoreDocument | null>(null)
	const {
		setAllowAllSeasons,
		setFirstSeasonOverride,
		setLastSeasonOverride,
		setAvailableSeasonsOverride
	} = useSeasonSettings()
	const {season} = useSeasonContext()


	useEffect(() => {
		setAllowAllSeasons(false)
		setAvailableSeasonsOverride(undefined);
		setFirstSeasonOverride(undefined);
		setLastSeasonOverride(undefined);

		get()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [season]);

	async function get() {
		setName("")
		setStandings([])
		setMatches([])
		setSelectedMatch(null)
		if (season < 1960) return

		const payload = await getClubStatistics(season)

		setName(payload.name)
		setStandings(payload.standings)
		setMatches(payload.matches)
		setSelectedMatch(payload.matches[0])
	}

	return (
		<Stack>
			<InnerWidthContainer>
				<DesktopStatisticsSeasonSelector/>
				<Box sx={{py: 2, pl: 0.5}}>
					<Typography variant={"podnaslovL"}>{name} - sezona {season}/{season + 1}</Typography>
				</Box>
				<DesktopClubStatisticsStandings standings={standings}/>
				<Stack direction={"row"} sx={{pt: 4}} alignItems={"flex-start"}>
					<DesktopClubStatisticsMatchList matches={matches}
					                                season={season}
					                                setSelectedMatch={setSelectedMatch}/>
					<DesktopClubStatisticsSelectedMatchContainer selectedMatch={selectedMatch} season={season}/>
				</Stack>
			</InnerWidthContainer>
		</Stack>
	)
}

