import {Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {NkmbArrowBasicDown} from "@nitra/nkmb-theme";
import {BoxWithFullWidthBackground} from "components/BoxWithFullWidthBackground";
import React from "react";
import {useCompetitionViewContext} from "./CompetitionContext";
import {INNER_MAX_WIDTH} from "../../../App";

export function SeasonPicker(props: {
	seasons: string[],
}) {
	const {seasons} = props;
	const {season, setSeason} = useCompetitionViewContext();

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	}

	const handleOpen = () => {
		setOpen(true);
	}

	function handleChange(e: SelectChangeEvent<string>) {
		setSeason(e.target.value)
		handleClose()
	}

	return (
		<BoxWithFullWidthBackground backgroundColor={"#181818"}>
			<Box sx={{
				maxWidth: {
					xs: "100%",
					md: INNER_MAX_WIDTH
				},
				pl: {
					xs: 0,
					md: "90px"
				}
			}}>
				<FormControl size={"small"} sx={{py: 0.5}}>
					{seasons.length > 0 && seasons.includes(season) &&
                        <Select
                            id="seasons-select"
                            value={season}
                            onChange={handleChange}
                            sx={{
								borderColor: "#181818",
								height: "32px",
								color: "white",
								'.MuiSelect-icon ': {
									color: "white",
									height: "18px",
									width: "18px"
								},
								'.MuiOutlinedInput-notchedOutline': {
									borderColor: "#181818 !important"
								},
							}}
                            MenuProps={{
								style: {
									maxHeight: 300,
									color: "text.secondary",
								}
							}}
                            IconComponent={NkmbArrowBasicDown}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                        >
							{seasons.map((season) => (
								<MenuItem key={season} value={season}>
									<Typography variant={"gumbS"}>Sezona {season}</Typography>
								</MenuItem>
							))}
                        </Select>
					}
				</FormControl>
			</Box>
		</BoxWithFullWidthBackground>
	)
}
