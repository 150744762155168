import {Stack, Typography} from "@mui/material";
import React from "react";

export function MobileClubStatisticsExpandableListPlayerDataLine(props: {
	label: string,
	value: string,
	forceThin?: boolean
}) {
	const {label, value, forceThin} = props;
	return (
		<Stack direction={"row"} sx={{color: "#676767", p: 0, height: "30px", borderBottom: "1px solid #D9D9D9"}}
		       alignItems={"center"}>
			<Typography variant={forceThin ? "bodyS" : "bodySBold"} sx={{pl: 3, mr: "auto"}}>{label}</Typography>
			<Typography variant={forceThin ? "bodyS" : "bodySBold"} sx={{pr: 3, width: "70px "}}>{value}</Typography>
		</Stack>
	)
}
