import React from "react";
import {Toolbar, useTheme} from "@mui/material";
import {MenuIconButton} from "./MenuIconButton";
import {AppBarLogo} from "./AppBarLogo";
import {SearchBar} from "./SearchBar";

export function MobileAppBar(props: {
	handleDrawerToggle: () => void,
	isDrawerOpen: boolean,
	searchIconRef: React.RefObject<HTMLButtonElement>
}) {
	const {handleDrawerToggle, isDrawerOpen, searchIconRef} = props;
	const theme = useTheme();

	return (
		<Toolbar
			sx={{
				width: "100%",
				justifyContent: "space-between",
				height: theme.spacing(8),
			}}
		>
			<MenuIconButton onClick={handleDrawerToggle} isDrawerOpen={isDrawerOpen}/>
			<AppBarLogo/>
			<SearchBar ref={searchIconRef}/>
		</Toolbar>
	)
}
