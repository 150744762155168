import {Box, Typography, useTheme} from "@mui/material";

export function ScoreAssistTableCell(props: {
	text: string | number,
	black?: boolean,
	bold?: boolean,
	extraBold?: boolean,
	wide?: boolean
}) {
	const {text, black, bold, wide} = props;
	const theme = useTheme();

	const width = wide ? {flexGrow: 1} : {width: "24px"}

	return (
		<Box
			sx={{
				...width,
				maxWidth: "144px",
				display: "flex",
				justifyContent: wide ? "flex-start" : "center",
			}}
		>
			<Typography
				variant={bold ? "bodySBold" : "bodyS"}
				sx={{color: black ? theme.palette.text.primary : "#676767"}}
			>
				{text}
			</Typography>
		</Box>
	)
}
