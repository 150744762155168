import {alpha, CardContent, Typography, useTheme} from "@mui/material";
import {CardOverImageTitle, CardWithShadow, useIsMobileContext} from "@nitra/nkmb-card-components";
import {TicketFirestoreDocument} from "models";
import {ETicketLevel} from "models/enums/ETicketLevel";
import React, {useEffect, useState} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NkmbIconExternalLink} from "@nitra/nkmb-theme";

export function SeasonTicketCard(props: {
	data: TicketFirestoreDocument,
	extraText: string,
}) {
	const {extraText} = props;
	const {ticketLevel, actionLink} = props.data

	const [title, setTitle] = useState("")
	const [imageUrl, setImageUrl] = useState("")

	const isMobile = useIsMobileContext();
	const {isIos} = useIosContext()
	const theme = useTheme();

	useEffect(() => {
		switch (ticketLevel) {
			case ETicketLevel.NORMAL:
				setTitle("Vstopnica")
				if (isMobile) {
					setImageUrl("/images/tickets_mobile_day_ticket.jpg")
				} else {
					setImageUrl("/images/vstopnice-sedezi.png")
				}
				break;
			case ETicketLevel.VPS:
				setTitle("Hospitality vstopnica")
				if (isMobile) {
					setImageUrl("/images/vps_seats_mobile_tickets.png")
				} else {
					setImageUrl("/images/sezonska-poslovni-sedezi.png")
				}
				break;
			case ETicketLevel.SKYBOX:
				setTitle("Zasebna loža")
				if (isMobile) {
					setImageUrl("/images/skybox_tickets_mobile.jpg")
				} else {
					setImageUrl("/images/sezonska-privatna-loza.png")
				}

				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketLevel]);


	return (
		<CardWithShadow
			sx={{display: "flex", flexDirection: "column", position: "relative"}}
			cardHeightOverride={isMobile ? 141 : 360}
			fixedWidth={isMobile ? undefined : 288}
			fullSizeImageUrl={imageUrl}
			to={actionLink}
		>
			<CardContent sx={{mt: "auto", zIndex: 1}} style={{paddingBottom: isMobile ? 8 : 16}}>
				<CardOverImageTitle
					title={title}
					variant={isMobile ? "h4" : "podnaslovM"}
					sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
				/>
				<Typography
					sx={{
						color: alpha(theme.palette.background.default, 0.65),
						pt: 2,

					}}
					variant={"bodyS"}>{extraText}</Typography>
				{ticketLevel !== ETicketLevel.SKYBOX &&
                    <NkmbIconExternalLink
                        style={{
							position: "absolute",
							right: 24,
							bottom: 24,
							height: 24,
							width: 24,
							color: theme.palette.background.default
						}}
                    />
				}
			</CardContent>
		</CardWithShadow>
	)
}
