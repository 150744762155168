import {ChevronRight} from "@mui/icons-material";
import {Button, CardContent, Typography, useTheme} from "@mui/material";
import {CardWithShadow, EPlayerSidebarStatType} from "@nitra/nkmb-card-components";
import {ScoreAssistCompetitionLogo} from "views/competitions/playerStatSidebar/ScoreAssistCompetitionLogo";
import {ScoreAssistTable} from "./ScoreAssistTable";
import {useNavigate} from "react-router-dom";

export function SideCardWithPlayerStats(props: {
	type: EPlayerSidebarStatType,
}) {
	const {type} = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<CardWithShadow sx={{width: "288px", height: "456px",}} disableOnHoverActions={true}>
			<CardContent
				sx={{
					height: "100%",
					width: "100%",
					display: "flex",
					flexDirection: "column",
				}}
				style={{
					padding: "0", // Style is used instead of sx since theme settings override this otherwise
				}}
			>
				<ScoreAssistCompetitionLogo/>
				<Typography sx={{pb: 2, pl: 2}}
				            variant={"podnaslovS"}>{type === EPlayerSidebarStatType.GOAL ? "Strelci" : "Asistence"}</Typography>
				<ScoreAssistTable type={type}/>
				<Button variant={"text"}
				        sx={{
					        ml: "auto",
					        mt: "auto",
					        mb: 2,
					        mr: 1,
					        color: theme.palette.text.primary,
					        ':hover': {
						        backgroundColor: theme.palette.background.default,
					        }
				        }}
				        onClick={() => navigate('/competitions/statistics/players')}
				>
					<Typography variant={"gumbS"}>Podrobno</Typography> <ChevronRight fontSize={"small"}/>
				</Button>
			</CardContent>
		</CardWithShadow>
	)
}

