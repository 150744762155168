import {FormControl, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {NkmbArrowBasicDown} from "@nitra/nkmb-theme";
import React, {useState} from "react";
import {useSeasonContext} from "@nitra/nkmb-card-components";


export function DesktopStatisticsSeasonSelector() {
	const {setSeason, availableSeasons, season} = useSeasonContext()

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	}

	const handleOpen = () => {
		setOpen(true);
	}

	function handleChange(e: SelectChangeEvent<number>) {
		setSeason(e.target.value as number)
		handleClose()
	}

	return (
		<FormControl>
			{
				availableSeasons.length > 0 && availableSeasons.includes(season) &&
                <Select
                    id="seasons-select"
                    value={season}
                    onChange={handleChange}
                    sx={{
						'& .MuiSelect-icon': {
							top: "calc(52% - 0.5em)"
						},
						'.MuiOutlinedInput-notchedOutline': {
							border: "0 !important",
						},
						'.MuiOutlinedInput-input': {
							px: 0
						},
						height: "24px",
						py: 0,
					}}
                    MenuProps={{
						style: {
							maxHeight: 300,
							color: "text.secondary",
						}
					}}
                    open={open}
                    IconComponent={NkmbArrowBasicDown}
                    inputProps={{}}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
					{availableSeasons.includes(42) &&
                        <MenuItem key={42} value={42}>
                            <Typography variant={"gumbL"}>Vse sezone</Typography>
                        </MenuItem>
					}
					{availableSeasons.filter((_season) => _season !== 42).map((_season) => (
						<MenuItem key={_season} value={_season}>
							<Typography variant={"gumbL"}>
								Sezona {_season.toString()}/{(_season + 1).toString().substring(2)}
							</Typography>
						</MenuItem>
					))}
                </Select>
			}
		</FormControl>
	)
}
