import {Grid} from "@mui/material";

export function VipPhotoGallery(props: {
	imageUrls: string[]
}) {
	const {imageUrls} = props;

	return (
		<Grid
			container
			spacing={5}
			sx={{mt: 4}}
			alignItems={"center"}
		>
			{imageUrls.map((imageUrl, index) => (
				<Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
					<img
						alt={""}
						src={imageUrl}
						style={{
							width: "100%",
							height: "auto",
							objectFit: "cover",
						}}
					/>
				</Grid>
			))}
		</Grid>
	)
}
