import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

const AppBarHeightContext = createContext<{
	isScrolled: boolean;
	heightWithBlackBarWithPx: string;
	heightWithoutBlackBarWithPx: string;
} | null>(null);

export default function useAppBarHeightContext() {
	const context = useContext(AppBarHeightContext);
	if (context === null) {
		throw new Error('useAppBarHeightContext must be used within a AppBarHeightContext');
	}
	return context;
}

export function AppBarHeightContextProvider(props: PropsWithChildren<{}>) {
	const theme = useTheme();
	const isMobile = useIsMobileContext();

	const [heightWithBlackBarWithPx, setHeightWithBlackBarWithPx] = useState<string>("");
	const [heightWithoutBlackBarWithPx, setHeightWithoutBlackBarWithPx] = useState<string>("");

	const [isScrolled, setIsScrolled] = useState(false);
	const handleScroll = () => {
		setIsScrolled((window.scrollY ?? 0) > 0)
	}

	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useEffect(() => {
		if (isMobile) {
			setHeightWithBlackBarWithPx(theme.spacing(8))
			setHeightWithoutBlackBarWithPx(theme.spacing(8))
		} else {
			if (isScrolled) {
				setHeightWithBlackBarWithPx("76px")
				setHeightWithoutBlackBarWithPx("76px")
			} else {
				setHeightWithBlackBarWithPx("76px")
				setHeightWithoutBlackBarWithPx("76px")

				// setHeightWithBlackBarWithPx("104px")
				// setHeightWithoutBlackBarWithPx("76px")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile, isScrolled]);

	return (
		<AppBarHeightContext.Provider value={{
			isScrolled: isScrolled,
			heightWithBlackBarWithPx: heightWithBlackBarWithPx,
			heightWithoutBlackBarWithPx: heightWithoutBlackBarWithPx,
		}}>
			{props.children}
		</AppBarHeightContext.Provider>
	)
}