import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowCastle() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/castle.png"}
			title={"2. Grad"}
			text={"Najstarejši ohranjen mariborski mestni pečat je iz srede 13. stoletja. Že na njem se pojavi podoba mestnih vrat, ki pa so za razliko od vrat na današnjem grbu enokrilno zaprta, zato se na njih vidi okovje obeh vratnih tečajev. V 14. stoletju sta na grbu že upodobljena štirioglata stolpa, ki smo ju vajeni danes. Sredi obzidja je nizek pokrit vratni stolp s širokim odprtim polkrožnim vhodom."}
		/>
	)
}
