import {Stack, Typography, useTheme} from "@mui/material";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";
import {useEffect, useState} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function PlayerProfileBannerLeftColumnDesktop() {
	const player = usePlayerDetailsContext();
	const theme = useTheme();
	const {isIos} = useIosContext()

	const {jerseyNumber, firstName, lastName} = player!;
	const [hasBanner, setHasBanner] = useState<boolean>(false);

	useEffect(() => {
		setHasBanner(player?.image680x680url !== undefined);
	}, [player]);

	return (
		<Stack sx={{
			pl: 10,
			height: "100%",
			color: theme.palette.primary.main
		}}>
			{!!jerseyNumber &&
                <Typography
                    variant={"h1"}
                    sx={{
						pt: hasBanner ? "186px" : "24px",
						fontSize: hasBanner ? "150px" : "110px",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
                >{jerseyNumber}</Typography>
			}
			<Stack sx={{pb: 7, mt: "auto", pt: hasBanner ? "unset" : 3}}>
				<Typography
					variant={"h4"}
					sx={{
						pb: 3,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
				>{firstName}</Typography>
				<Typography
					variant={"h1"}
					sx={{
						fontSize: "102px",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
				>{lastName}</Typography>
			</Stack>
		</Stack>
	)
}
