import * as React from "react";
import {useEffect} from "react";
import {VideoFirestoreListDocument} from "../../../models";
import {Box, Grid, Stack, useTheme} from "@mui/material";
import {InnerWidthContainer, SmallLoader} from "../../../components";
import {DesktopVideoMetaSegment} from "./DesktopVideoMetaSegment";
import {DesktopVideoList} from "./DesktopVideoList";
import useNewsSearchContext from "../search/NewsSearchContext";
import {NewsSearchLine} from "../NewsSearchLine";

export function DesktopVideoFeed() {
	const {
		elements,
		isLoading
	} = useNewsSearchContext<VideoFirestoreListDocument>()

	const [selectedVideo, setSelectedVideo] = React.useState<VideoFirestoreListDocument | null>(null)

	const theme = useTheme()

	useEffect(() => {
		if (elements.length > 0) {
			setSelectedVideo(elements[0])
		} else {
			setSelectedVideo(null)
		}
	}, [elements]);

	return (
		<InnerWidthContainer sx={{
			backgroundColor: theme.palette.text.primary,
			color: theme.palette.background.paper,
			pt: 3
		}}>
			<NewsSearchLine isOnDarkBackground={true} initialYear={2010}/>
			<SmallLoader isLoading={isLoading}/>
			<Grid container sx={{pt: 5}}>
				<Grid item xs={12} lg={8}>
					<Stack>
						{selectedVideo &&
                            <Box sx={{flexGrow: 1, pr: 5, pb: 5}}>
                                <Box sx={{
									position: "relative", paddingBottom: "56.25%", height: 0, width: "100%", flexGrow: 1
								}}>
                                    <iframe
                                        style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											borderWidth: 0,
										}}
                                        src={`https://www.youtube.com/embed/${selectedVideo.youtubeId}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>

                                </Box>
                            </Box>
						}
						{selectedVideo && <DesktopVideoMetaSegment video={selectedVideo}/>}
					</Stack>
				</Grid>
				<Grid item xs={12} lg={4}>
					<DesktopVideoList
						videos={elements}
						setSelectedVideo={setSelectedVideo}
						selectedVideo={selectedVideo}/>
				</Grid>
			</Grid>
		</InnerWidthContainer>
	)
}