import React from "react";
import {alpha, Box, CardContent, Stack, SvgIcon, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useIsMobileContext} from "@nitra/nkmb-card-components";
import {PlayerProfileStatCard} from "./PlayerProfileStatCardContainer";

export function PlayerStatsSingleAttributeElement(props: {
	emblem: React.FC,
	value: number,
	title: string,
}) {
	const {emblem, value, title} = props;

	const theme = useTheme()
	const isMobile = useIsMobileContext()

	if (isMobile) {
		return (
			<PlayerProfileStatCard
				title={title}
				value={value.toLocaleString(["sl-sl"])}
				icon={emblem}
				sx={{
					ml: "unset"
				}}
			/>
		)
	}

	return (
		<CardWithShadow
			cardHeightOverride={128}
			fixedWidth={230}
			sx={{borderRadius: "20px", border: "1px solid", borderColor: alpha("#676767", 0.2)}}
			style={{marginBottom: "16px", marginLeft: "16px"}}
			disableOnHoverActions={true}
		>
			<CardContent style={{paddingTop: 0, paddingLeft: 0}} sx={{m: 0, py: 0, height: "100%"}}>
				<Stack direction={"row"}
				       sx={{p: 0, height: "100%", alignItems: "center", justifyContent: "flex-start"}}>
					<Box sx={{pl: 1}}>
						<SvgIcon component={emblem} sx={{
							width: 100, height: 100,
							opacity: (title === "rumeni kartoni" || title === "rdeči kartoni") ? 1.0 : 0.3,
							color: title === "rumeni kartoni" ? theme.palette.secondary.main : title === "rdeči kartoni" ? "#C20831" : "unset",
						}}
						         viewBox={"0 0 100 100"}/>
					</Box>
					<Stack sx={{pl: 1}}>
						<Typography variant={"h3"}
						            sx={{color: "#676767"}}>{value.toLocaleString(["sl-sl"])}</Typography>
						<Typography variant={"bodyM"} sx={{color: "#676767", pt: 1}}>{title}</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</CardWithShadow>
	)
}