import {Box, Divider, IconButton, Stack, Typography, useTheme} from "@mui/material";

import {InnerWidthContainer, PaperWidthContainer} from "../../../components";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import React from "react";

import {BigCalendarDayBoxDesktop} from "./BigCalendarDayBoxDesktop";
import {useTimeManagementContext} from "@nitra/nkmb-card-components";

/**
 * Element containing a big overview calendar for a month, including the option to switch between months
 * @constructor
 */
export function BigCalendarDesktop() {
	const theme = useTheme()

	const {
		monthNameLong,
		weeks,
		year,
		month,
		handleGoIntoPast,
		handleGoIntoFuture,
	} = useTimeManagementContext()


	return (
		<PaperWidthContainer sx={{
			backgroundColor: theme.palette.background.default,
		}}>
			<InnerWidthContainer>
				<Stack spacing={1} sx={{pb: 1}}>
					<Stack direction={"row"} alignItems={"flex-end"} justifyContent={"flex-start"}>
						<Typography variant={"h1"} sx={{mt: 5, pr: 3}}>{monthNameLong}</Typography>
						<IconButton onClick={handleGoIntoPast} sx={{pb: 2}}>
							<ChevronLeft style={{color: theme.palette.text.primary}}/>
						</IconButton>
						<IconButton onClick={handleGoIntoFuture} sx={{pb: 2}}>
							<ChevronRight style={{color: theme.palette.text.primary}}/>
						</IconButton>
					</Stack>
					<Typography variant={"podnaslovL"} sx={{color: "#676767"}}>{year}</Typography>
					{weeks.map((week, index) =>
						<Box key={index}>
							<Divider/>
							<Stack key={index} direction={"row"} alignItems={"stretch"} justifyContent={"space-between"}
							       sx={{pt: 1}}
							>
								{week.map((day, index) =>
									<BigCalendarDayBoxDesktop
										key={index}
										prominentMonth={month}
										calendarDay={day}
									/>
								)}
							</Stack>
						</Box>
					)}
				</Stack>
			</InnerWidthContainer>
		</PaperWidthContainer>
	)
}
