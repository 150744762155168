import {useTheme} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {useFooterColorMode, useIsMobileContext, usePaperBackgroundContext} from "@nitra/nkmb-card-components";
import {DesktopVideoFeed} from "./video/DesktopVideoFeed";
import {MobileVideoFeed} from "./video/MobileVideoFeed";
import {NewsSearchContextProvider} from "./search/NewsSearchContext";
import {getVideos} from "../../firestore/specificGetters/VideoRequests";
import {VideoFirestoreListDocument} from "../../models";

export function VideoFeed() {
	const isMobile = useIsMobileContext();

	const theme = useTheme();
	const {setPaperBackgroundColor} = usePaperBackgroundContext();
	const {setIsMonochrome} = useFooterColorMode()

	useEffect(() => {
		const black = "#181818"
		document.body.style.backgroundColor = black;
		setPaperBackgroundColor(black);
		setIsMonochrome(true);
		return () => {
			document.body.style.backgroundColor = "white";
			setPaperBackgroundColor(theme.palette.background.paper);
			setIsMonochrome(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<NewsSearchContextProvider<VideoFirestoreListDocument>
			getFunction={getVideos}
			sortFunction={(a, b) => b.publishedAt.getTime() - a.publishedAt.getTime()}
			idAccessor={(video) => video.key}>
			{isMobile ? <MobileVideoFeed/> : <DesktopVideoFeed/>}
		</NewsSearchContextProvider>
	)

}

