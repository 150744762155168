import {alpha, Button, SxProps, Typography, useTheme} from "@mui/material";
import React, {forwardRef} from "react";
import {Link, useLocation} from "react-router-dom";

interface AppBarTextProps {
	title: string;
	url: string;
	sx?: SxProps;
}

export const AppBarNavigationButton = forwardRef<HTMLAnchorElement | null, AppBarTextProps>((props, ref) => {
	const {url, title} = props;
	const theme = useTheme()

	const path = useLocation().pathname;

	return (
		<Button
			component={Link}
			to={url}
			target={url.startsWith("https") ? "_blank" : undefined}
			id={`test-${url}`}
			sx={{
				color: "inherit",
				p: 0,
				height: "100%",
				minWidth: "fit-content",
				backgroundColor: path.indexOf(url) !== -1 ? alpha(theme.palette.common.white, 0.07) : "unset",
				borderRadius: 0,
				"&:hover": {
					backgroundColor: "rgba(255,255,255,0.07)",
				},
			}}
			ref={ref}
		>
			<Typography
				variant="meni"
				noWrap
				sx={{
					p: theme.spacing(3),
					color: 'inherit',
					textDecoration: 'none',
					cursor: "pointer",
					...props.sx
				}}
			>
				{title}
			</Typography>
		</Button>
	)
})
