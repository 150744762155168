import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowStar() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/star.png"}
			title={"5. zvezdica"}
			text={"NK Maribor je po sezoni 2011/12 s ponosom nad svoj grb dodal zvezdico, ki simbolizira deset (in več) naslovov državnega prvaka. V nekaterih državah so pravila za postavljanje zvezdic zapisana v statutih lig in nogometnih zvez, pri nas tega pravila ni, zato nekateri klubi zvezdico nad grbom uporabljajo za vsak naslov prvaka, v NK Maribor pa za kaj takega nimamo prostora."}
		/>
	)
}
