import {NewsSearchContextProvider} from "../news/search/NewsSearchContext";
import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";
import {getFootballSchoolNewsFeed} from "../../firestore";
import {FootballSchoolNewsFeed} from "./FootballSchoolNewsFeed";
import React from "react";

export function FootballSchoolNewsFeedContainer() {
	return (
		<NewsSearchContextProvider<ArticleListFirestoreDocument>
			getFunction={getFootballSchoolNewsFeed}
			sortFunction={(a, b) => b.createdAt.getTime() - a.createdAt.getTime()}
			idAccessor={(element) => element.firestoreId}>
			<FootballSchoolNewsFeed/>
		</NewsSearchContextProvider>
	)
}