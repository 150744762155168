import React, {PropsWithChildren} from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Box, Stack, Typography, useTheme} from "@mui/material";

export function OpeningTimeDesktopBox(props: PropsWithChildren<{ days: string[], times: string[] }>) {
	const {days, times} = props;
	const isMobile = useIsMobileContext()
	const theme = useTheme()


	return (
		<Box sx={{
			backgroundColor: isMobile ? theme.palette.background.default : "#F8F8F8",
			pl: 1,
			py: 2,
			pr: 6,
			marginRight: isMobile ? "unset" : "auto",
		}}>
			<Typography variant={"bodyMBold"} sx={{color: "#676767"}}>Odpiralni čas</Typography>
			<Stack direction={"row"} sx={{pt: 1}} spacing={6}>
				<Stack>
					{days.map(day => <Typography variant={"bodyLBold"} sx={{lineHeight: "18px"}}>{day}</Typography>)}
				</Stack>
				<Stack>
					{times.map(time => <Typography variant={"bodyLBold"} sx={{lineHeight: "18px"}}>{time}</Typography>)}
				</Stack>
			</Stack>
		</Box>
	)
}