import {FormControl, OutlinedInput, useTheme} from "@mui/material";
import {NkmbIconSearch} from "@nitra/nkmb-theme";
import typography from "@nitra/nkmb-theme/typography";
import React from "react";

export function DesktopStatisticsSearchBar(props: {
	filter: string,
	setFilter: (filter: string) => void
}) {
	const {filter, setFilter} = props
	const theme = useTheme()

	return (
		<FormControl sx={{flexGrow: 1}} variant="outlined">
			<OutlinedInput
				id="outlined-adornment-weight"
				aria-describedby="outlined-weight-helper-text"
				inputProps={{
					'aria-label': 'weight',
				}}
				placeholder={"ime priimek"}
				sx={{
					borderRadius: "40px",
					height: "21px",
					width: "235px",
					ml: "auto",
					backgroundColor: theme.palette.background.default,
					typography: typography.gumbSthin,
				}}
				startAdornment={<NkmbIconSearch style={{height: "12px", width: "12px", marginRight: "16px"}}/>}
				value={filter}
				onChange={(event) => setFilter(event.target.value)}
			/>
		</FormControl>
	)
}
