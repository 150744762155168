import {FormControl, InputAdornment, OutlinedInput, TextField, useTheme} from "@mui/material";
import React from "react";
import {NkmbIconSearch} from "@nitra/nkmb-theme";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

export function NkmbSearchBar(props: {
	search: string,
	setSearch: (value: string) => void,
	callSearch: () => void,
}) {
	const {search, setSearch, callSearch} = props
	const theme = useTheme()
	const isMobile = useIsMobileContext()

	return (
		<FormControl sx={{m: 1, height: isMobile ? "56px" : "unset", width: isMobile ? "100%" : "unset"}} variant={"standard"}>
			{isMobile ?
				<OutlinedInput
					placeholder={"iskanje"}
					sx={{
						borderRadius: "40px",
						backgroundColor: theme.palette.background.default,
						height: "56px",
						mr: 1,
						ml: -1,
					}}
					startAdornment={<NkmbIconSearch style={{height: "24px", width: "24px", marginRight: "16px"}}/>}
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>

			:
			<TextField
				placeholder="iskanje"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onKeyUp={(event) => {
					if (event.key === 'Enter' || event.key === "Done" || event.key === "Go") {
						event.preventDefault();
						callSearch();
					}
				}}
				InputProps={{
					startAdornment: <InputAdornment position="start"><NkmbIconSearch/></InputAdornment>,
					style: {
						borderRadius: '50px',
						backgroundColor: theme.palette.background.default,
						padding: 0,
						paddingInline: "8px",
						height: "25px",
					}
				}}
				sx={{
					borderRadius: '50px',
				}}
			/>
			}
		</FormControl>
	)
}