import {Route, Routes} from "react-router-dom";
import TicketLanding from "./TicketLanding";


function TicketRouter() {
	return (
		<Routes>
			<Route path={"/"} element={<TicketLanding/>}/>
		</Routes>
	);
}

export default TicketRouter;