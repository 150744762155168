import {CompetitionStatisticsContainerFirestoreDocument} from "../../models";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../BaseFirestore";

/**
 * Get the statistics of the competitions
 * @param season - The season in the 4 digit format
 */
export async function getCompetitionsStatistics(season: string): Promise<CompetitionStatisticsContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/competitionsStatistics/competitionsStatistics'), season));

	if (!snapshot.exists()) throw new Error('Competitions statistics not found');

	return {
		season: snapshot.data().season,
		blocks: snapshot.data().blocks.map((block: any) =>
			({
				...block,
				highestWins: block.highestWins.map((win: any) => ({
					...win,
					date: win.date.toDate(),
				})),
				matchWithMostViewers: block.matchWithMostViewers ? {
					...block.matchWithMostViewers,
					date: block.matchWithMostViewers.date.toDate(),
				} : undefined,
				matchWithLeastViewers: block.matchWithLeastViewers ? {
					...block.matchWithLeastViewers,
					date: block.matchWithLeastViewers.date.toDate(),
				} : undefined,
			})
		),
		players: snapshot.data().players.map((player: any) => ({
			...player,
			dateOfBirth: player.dateOfBirth.toDate(),
			competitionStats: player.competitionStats
		})),
		streaks: snapshot.data().streaks
	} as CompetitionStatisticsContainerFirestoreDocument;
}