export const VipTexts = {
	basic: {
		vps: {
			imageTitle: "vijol'čni premium sedež",
			imageUrl: "/images/hospitality/vps/vps1.png",
			link: "/hospitality/vps",
			textTitle: "VIJOL’ČNI PREMIUM SEDEŽ",
			textBody: "Ogled tekme z Vijol'čnih premium sedežev je prestižna in vrhunska izkušnja, ki ti omogoča uživanje v tekmi z dodatno pozornostjo na gostoljubnosti ter udobju. VIP hospitality se od klasičnega ogleda tekem razlikuje po premium okolju, ki vključuje vrhunsko kulinariko in selekcijo pijač, ki bo druženju ali mreženju v premium okolju dalo dodatno razsežnost.",
			buttonLabel: "Več",
		},
		skybox: {
			imageTitle: "Zasebna loža",
			imageUrl: "/images/hospitality/skybox/skybox1.png",
			link: "/hospitality/skybox",
			textTitle: "ZASEBNE LOŽE",
			textBody: "Ogled tekme iz zasebne lože kot ekskluzivno doživetje, s spremljanjem tekme ob nadgradnji udobja z vrhunsko postrežbo in z navdušujočim pogledom v elitnem okolju. Kot priložnost za poslovna srečanja ali mreženje, praznovanja v ožjem krogu ali pogostitev poslovnih partnerjev.",
			buttonLabel: "Več",
		}
	},
	vps: {
		banner: {
			title: "VIJOL'ČNI PREMIUM SEDEŽ",
			subtitle: "Premium sedeži"
		},
		title1: "Kaj vključuje Vijol'čni premium sedež:",
		body1: "⦁\tMožnost prihoda v hospitality prostor dve uri pred začetkom tekme\n" +
			"⦁\tBar s selekcijo izbranih vin, piva, koktejlov, brezalkoholnih pijač in toplih napitkov\n" +
			"⦁\tBogata, raznolika ponudba hrane\n" +
			"⦁\tPrigrizek ob polčasu »half time snack«\n" +
			"⦁\tOgled tekme z oblazinjenih sedežev\n" +
			"⦁\tRezervirano parkino mesto v neposredni bližini stadiona (ob nakupu najmanj dveh vstopnic) \n" +
			"⦁\tPoseben vhod\n" +
			"⦁\tPrijazno osebje\n" +
			"⦁\tDJ, ki bo poskrbel za prijetno vzdušje\n",
		title2: "Vijol'čni premium sedež",
		body2: "Dobrodošli na tribuni Marcos Tavares, kjer si boste tekmo ogledali na vijol’čnih premium sedežih. Nakup vstopnice VPS (Vijol'čni premium sedež) omogoča najboljše nogometno doživetje v Sloveniji, z vstopom v skupni pogostitveni prostor dve uri pred začetkom posamezne tekme. Dva polčasa s kulinaričnim podaljškom kot zabava za prijatelje, družine, sodelavce ali poslovne partnerje. ⦁ Sezonska vstopnica VPS: velja za tekme NK Maribor v Ljudskem v državnem prvenstvu in v pokalnem tekmovanju. Več informacij na hospitality@nkmaribor.com ali po telefonu 02 / 228 47 07.\n" +
			"Možnost nakupa vstopnice VPS za posamično tekmo.",
		imageUrls: [
			"/images/hospitality/vps/Rectangle 63.jpg",
			"/images/hospitality/vps/Rectangle 64.jpg",
			"/images/hospitality/vps/Rectangle 65.jpg",
			"/images/hospitality/vps/Rectangle 66.jpg",
			"/images/hospitality/vps/Rectangle 67.jpg",
			"/images/hospitality/vps/Rectangle 68.jpg",
			"/images/hospitality/vps/Rectangle 69.jpg",
			"/images/hospitality/vps/Rectangle 70.jpg",
			"/images/hospitality/vps/Rectangle 71.jpg",
			"/images/hospitality/vps/Rectangle 72.jpg",
		],
		mobileImageUrls: [
			"/images/hospitality/vps/mobile/vps1.png",
			"/images/hospitality/vps/mobile/vps2.png",
			"/images/hospitality/vps/mobile/vps3.png",
			"/images/hospitality/vps/mobile/vps4.png",
			"/images/hospitality/vps/mobile/vps5.png",
			"/images/hospitality/vps/mobile/vps6.png",
			"/images/hospitality/vps/mobile/vps7.png",
			"/images/hospitality/vps/mobile/vps8.png",
			"/images/hospitality/vps/mobile/vps9.png",
			"/images/hospitality/vps/mobile/vps10.png",
		]
	},
	skybox: {
		banner: {
			title: "Zasebne lože",
			subtitle: "Zasebne lože"
		},
		title1: "Ekskluzivno doživetje v zasebni loži:",
		body1: "⦁\togled tekme z oblazinjenih sedežev na najbolj ekskluzivnem delu stadiona\n" +
			"⦁\tšampanjec/aperitiv ob prihodu\n" +
			"⦁\tsoba s pogledom: zasebna pogostitev v elegantnem okolju za 12 oseb\n" +
			"⦁\tvrhunska kulinarika: kosilo ali večerja \n" +
			"⦁\tosebna selekcija: izbira vina, piva, žganih in brezalkoholnih pijač ter toplih napitkov\n" +
			"⦁\tparkirišče za 6 avtomobilov, poseben vhod, VIP dvigalo\n" +
			"⦁\tusposobljeno in predano osebje\n" +
			"⦁\tNK Maribor premium darilo za vsakega gosta\n" +
			"⦁\tWi-Fi + HD TV-zaslon\n",
		title2: "Zasebne lože",
		body2: "Vstop na stadion, prihod na igrišče, naboj tekme pri vsakem športniku sprožijo adrenalin posebne vrste. Nič drugačni ne bodo občutki gostov, ki bodo vstopili v elitne suite Ljudskega vrta. Kar je za nogometaše igrišče, je za gledalce zasebna loža. Privilegij med, a tudi pred tekmo in po tekmi. Vaša ekipa bo imela ves čas poseben status. Vaši gostje bodo ostali zmagovalci dneva.\n" +
			"⦁" +
			"Zasebna loža, ki sprejme 12 gostov, z ekskluzivnimi zasebnimi sedeži na balkonu, ponuja vrhunec VIP doživetij na tekmah, saj združuje vrhunsko dogajanje na igrišču z razkošjem brez primere. Priložnost za skupine, tiste, ki iščete dodatno možnost ekskluzivnega dogodka, saj ponuja izvrstno kulinariko pred tekmo in bogato selekcijo pijač med celotnim dogodkom – pred tekmo, ob polčasu ter po zadnjem sodniškem žvižgu.\n" +
			"⦁" +
			"Kot nepozaben pridih bo vsak gost prejel darilo, kot trajen spomin na nepozabno izkušnjo spremljanja tekem iz zasebne lože.\n" +
			"⦁" +
			"Najem za posamezno tekmo ali za celotno sezono (velja za tekme NK Maribor v Ljudskem v državnem prvenstvu in v pokalnem tekmovanju, a tudi v evropskih pokalih). Več informacij na hospitality@nkmaribor.com ali po telefonu 02 / 228 47 07.\n",
		imageUrls: [
			"/images/hospitality/skybox/Rectangle 65.png",
			"/images/hospitality/skybox/Rectangle 66.png",
			"/images/hospitality/skybox/Rectangle 67.png",
			"/images/hospitality/skybox/Rectangle 73.png",
			"/images/hospitality/skybox/Rectangle 74.png",
			"/images/hospitality/skybox/Rectangle 75.png",
			"/images/hospitality/skybox/Rectangle 76.png",
		],
		mobileImageUrls: [
			"/images/hospitality/skybox/mobile/skybox1.png",
			"/images/hospitality/skybox/mobile/skybox2.png",
			"/images/hospitality/skybox/mobile/skybox3.png",
			"/images/hospitality/skybox/mobile/skybox4.png",
			"/images/hospitality/skybox/mobile/skybox5.png",
			"/images/hospitality/skybox/mobile/skybox6.png",
			"/images/hospitality/skybox/mobile/skybox7.png",
		]
	}
}
