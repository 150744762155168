import {Box} from "@mui/material";
import {VideoContentElementPageCacheFirebaseDocument} from "models";

export function VideoContent(props: { element: VideoContentElementPageCacheFirebaseDocument }) {
	const {youtubeId} = props.element;
	return (
		<Box
			sx={{
				width: '100%',
				position: 'relative',
				display: "flex",
				alignItems: "center",
			}}
		>
			<iframe
				style={{
					aspectRatio: '16 / 9',
					flexGrow: 1,
					marginInline: "auto",
					maxWidth: '690px',
				}}
				src={`https://www.youtube.com/embed/${youtubeId}`}
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen>
			</iframe>
		</Box>
	)
}
