import {PropsWithChildren} from "react";

const SponsorIcon = (props: PropsWithChildren<{
	logoFileName: string,
	link: string,
	name: string,
	isBig: boolean,
	useMonochrome: boolean
}>) => {
	const {logoFileName, name, link, isBig, useMonochrome} = props;

	return (
		<img
			src={`/images/sponsors/${useMonochrome ? "/mono" : "color"}/${logoFileName}`}
			alt={name}
			style={{
				maxWidth: isBig ? "unset" : "100%",
				maxHeight: isBig ? 216 : 130,
				marginInline: isBig ? "-35px" : "unset",
				opacity: useMonochrome ? 0.3 : 1,
			}}
			onClick={() => {
				window.open(link, "_blank")
			}}
		/>
	)
}

export default SponsorIcon
