import {IconButton, SxProps} from "@mui/material";
import React from "react";
import {NkmbIconMeni, NkmbIconMeniclose} from "@nitra/nkmb-theme";

export const MenuIconButton = (props: {
	onClick: () => void,
	isDrawerOpen: boolean,
	sx?: SxProps,
}) => {
	const {onClick, isDrawerOpen} = props
	return (
		<IconButton
			color="inherit"
			aria-label="open drawer"
			edge="start"
			onClick={onClick}
			sx={{
				mx: 0,
				flexShrink: 0,
				...props.sx
			}}
		>

			{isDrawerOpen ? <NkmbIconMeniclose/> : <NkmbIconMeni/>}
		</IconButton>
	)
}
