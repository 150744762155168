import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {Box, Table, TableBody, Typography, useTheme} from "@mui/material";
import {CompetitionStatisticsStreakRow} from "./CompetitionStatisticsStreakRow";
import React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

export function CompetitionStatisticsStreaks() {
	const {streaks} = useCompetitionStatisticsContext();
	const theme = useTheme()
	const isMobile = useIsMobileContext()

	if (!streaks) return (<></>)

	return (
		<>
			{!isMobile &&
                <Typography sx={{mt: 9, pb: 2, color: "#676767"}} variant={"podnaslovS"}>serije</Typography>
			}
			<Box sx={{
				borderRadius: isMobile ? 0 : "8px",
				backgroundColor: isMobile ? theme.palette.background.paper : theme.palette.background.default,
				boxShadow: isMobile ? "" : "0px 0px 8px 0px #0000001F",
				pb: 1,
			}}>
				<Table sx={{
					color: "#676767",
				}}>
					<TableBody>
						<CompetitionStatisticsStreakRow
							label={"Zaporedne zmage"}
							data={streaks.longestWinningStreak}
						/>
						<CompetitionStatisticsStreakRow
							label={"Zaporedne neodločeni"}
							data={streaks.longestDrawStreak}
						/>
						<CompetitionStatisticsStreakRow
							label={"Zaporedni porazi"}
							data={streaks.longestLosingStreak}
						/>
						<CompetitionStatisticsStreakRow
							label={"Brez poraza"}
							data={streaks.longestWithoutLossStreak}
						/>
						<CompetitionStatisticsStreakRow
							label={"Brez zmage"}
							data={streaks.longestWinlessStreak}
						/>
						<CompetitionStatisticsStreakRow
							label={"Brez neodločenega"}
							data={streaks.longestWithoutDrawStreak}
						/>
					</TableBody>
				</Table>
			</Box>
		</>
	)
}