import {Box, Grid, SxProps} from "@mui/material";
import React, {PropsWithChildren} from "react";

/**
 * Container for cards that follow the 12, 6, 4, 3 grid pattern and don't have a title
 * @param props
 * @constructor
 */
export function CardContainerWithoutName(props: PropsWithChildren<{
	sx?: SxProps,
	innerSx?: SxProps,
}>) {
	const {children} = props

	return (
		<Box sx={{
			pb: 5,
			...props.sx
		}}>
			<Grid container spacing={2} sx={{...props.innerSx}}>
				{React.Children.map(children, (child, index) =>
					<Grid
						key={index}
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
					>
						{child}
					</Grid>
				)}
			</Grid>
		</Box>
	)
}
