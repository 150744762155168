export enum EPlayerPosition {
	GOALKEEPER = "GOALKEEPER",
	DEFENDER = "DEFENDER",
	MIDFIELDER = "MIDFIELDER",
	FORWARD = "FORWARD",
	UNKNOWN = "UNKNOWN",
}

export function EPlayerPositionToPretty(position: EPlayerPosition): string {
	switch (position) {
		case EPlayerPosition.GOALKEEPER:
			return "Vratar";
		case EPlayerPosition.DEFENDER:
			return "Branilec";
		case EPlayerPosition.MIDFIELDER:
			return "Vezist";
		case EPlayerPosition.FORWARD:
			return "Napadalec";
		default:
			return "Neznan";
	}
}
