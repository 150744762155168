import {FootballSchoolStaffListFirestoreDocument} from "../../models";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import {FootballSchoolStaffCard, useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";

export function FootballSchoolSupportStaffCommonGroup(props: {
	group: {
		label: string,
		staff: FootballSchoolStaffListFirestoreDocument[]
	}
}) {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const isMobile = useIsMobileContext()

	return (
		<Stack direction={"column"} sx={{width: "100%", pt: 4,}}>
			<Typography
				variant={isMobile ? "podnaslovXS" : "podnaslovM"}
				sx={{
					pb: 2,
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>{props.group.label}</Typography>
			<Grid container spacing={2} justifyContent={"space-between"}>
				{props.group.staff.map((person, index) => (
					<Grid key={index} item
					      xs={6}
					      lg={4}
					      xl={3}
					      sx={{marginRight: index === props.group.staff.length - 1 ? "auto" : "unset",}}
					>
						<Box style={{
							maxWidth: isMobile ? "331px" : "288px",
							width: "100%",
							paddingTop: isMobile ? "min(100%, 331px)" : "360px",
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								<FootballSchoolStaffCard staff={{
									...person,
									imageUrl: isMobile ? person.image331x331url : person.image288x360url,
								}}/>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Stack>
	)
}