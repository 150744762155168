import {Box, Stack, useTheme} from "@mui/material";
import {useSeasonSettings} from "@nitra/nkmb-card-components";
import {BoxWithFullWidthBackground} from "components/BoxWithFullWidthBackground";
import {InnerWidthContainer} from "components/widthControlElements";
import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {MobileClubStatistics} from "views/club/statistics/mobile/club/MobileClubStatistics";
import {MobileStatisticsBlackBarButton} from "views/club/statistics/mobile/MobileStatisticsBlackBarButton";
import {MobileStatisticsSeasonSelector} from "views/club/statistics/mobile/MobileStatisticsSeasonSelector";
import {MobilePlayerStatistics} from "views/club/statistics/mobile/player/MobilePlayerStatistics";


const CLUB_PATH = "standings"
const PLAYERS_PATH = "players"

export function MobileStatistics() {
	const [currentTab, setCurrentTab] = useState<"standings" | "players">(CLUB_PATH)


	const {pathname} = useLocation()
	const navigate = useNavigate()
	const theme = useTheme();
	const {setAllowAllSeasons} = useSeasonSettings();

	useEffect(() => {
		if (pathname.includes(CLUB_PATH)) {
			setCurrentTab(CLUB_PATH)
			setAllowAllSeasons(false)
		}
		if (pathname.includes(PLAYERS_PATH)) {
			setCurrentTab(PLAYERS_PATH)
			setAllowAllSeasons(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	return (
		<Stack>
			<BoxWithFullWidthBackground backgroundColor={theme.palette.text.primary}>
				<InnerWidthContainer>
					<Stack direction={"row"} alignItems={"center"} sx={{height: "50px"}}>
						<MobileStatisticsSeasonSelector/>
						<MobileStatisticsBlackBarButton
							label={"Klub"}
							selected={currentTab === CLUB_PATH}
							onClick={() => navigate(CLUB_PATH)}
							sx={{ml: "auto"}}
						/>
						<Box sx={{
							backgroundColor: "#676767",
							height: "11px",
							width: "1px",
							zIndex: 1,
						}}/>
						<MobileStatisticsBlackBarButton
							label={"Igralci"}
							selected={currentTab === PLAYERS_PATH}
							onClick={() => navigate(PLAYERS_PATH)}
						/>
					</Stack>
				</InnerWidthContainer>
			</BoxWithFullWidthBackground>
			<Routes>
				<Route path={"/"} element={<Navigate to={CLUB_PATH} replace/>}/>
				<Route path={`${CLUB_PATH}/*`} element={<MobileClubStatistics/>}/>
				<Route path={`${PLAYERS_PATH}/*`} element={<MobilePlayerStatistics/>}/>
			</Routes>
		</Stack>
	)
}
