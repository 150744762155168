import {Box, CircularProgress} from "@mui/material";
import React from "react";

export function SmallLoader(props: {
	isLoading: boolean,
}) {
	const {isLoading} = props;

	if (!isLoading) return <></>

	return (
		<Box sx={{position: "relative", width: "100%", height: "100%"}}>
			<Box sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}>
				<CircularProgress size={40}/>
			</Box>
		</Box>
	)
}