import {Box, Stack} from "@mui/material";
import {CalendarCardWithLogic, TimeManagementContextProvider, useIsMobileContext} from "@nitra/nkmb-card-components";
import {PaperWidthContainer} from "components/widthControlElements";
import {getSeasonMatches} from "firestore/BaseFirestore";
import React from "react";
import Banner from "../../components/Banner";
import {TicketLandingMobileHospitalityBanner} from "./TicketLandingMobileHospitalityBanner";
import useTicketData from "../../components/ContextProviders/TicketDataContext";
import {SeasonTicketContainer} from "./SeasonTicketContainer";
import {NormalTicketContainer} from "./NormalTicketContainer";

function TicketLanding() {
	const vipBanner = {
		title: "Ljudski vrt 5*",
		imageUrl: "/images/hospitality/tribuna.png",
		actionLink: "/hospitality",
		subtitle: "Ekskluzivno doživetje v Ljudskem vrtu",
	};
	const isMobile = useIsMobileContext();
	const {selectedMatch} = useTicketData()

	return (
		<Stack>
			<Stack sx={{mx: isMobile ? 2 : 0}}>
				{selectedMatch &&
                    <NormalTicketContainer/>
				}
				{isMobile &&
                    <Box sx={{pt: 2}}>
                        <TimeManagementContextProvider
                            getterFunction={getSeasonMatches}
                            limitToCurrentSeason={true}
                        >
                            <CalendarCardWithLogic/>
                        </TimeManagementContextProvider>
                    </Box>
				}
				<SeasonTicketContainer/>
			</Stack>
			{vipBanner && isMobile ? <TicketLandingMobileHospitalityBanner vipBanner={vipBanner}/> :
				<PaperWidthContainer sx={{mt: 2}}>
					<Banner
						title={vipBanner.title}
						imageUrl={vipBanner.imageUrl}
						subtitle={vipBanner.subtitle}
						onClickLink={vipBanner.actionLink}
						sx={{marginTop: "unset"}}
						desktopHeight={480}
					/>
				</PaperWidthContainer>
			}
		</Stack>
	)
}

export default TicketLanding;

