import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../BaseFirestore";
import {VideoFirestoreListDocument} from "../../models";
import {functionConfig} from "../firebaseConfig";
import PageDto from "../../models/common/PagableDto";

export type IGetVideosParams = {
	pageSize?: number,
	month?: number,
	year?: number,
	searchQuery?: string,
	lastId?: string,
	page?: number,
}

export async function getVideos(
	params: IGetVideosParams = {}
): Promise<PageDto<VideoFirestoreListDocument>> {
	const {pageSize, month, year, searchQuery, lastId, page} = params;

	if (lastId === undefined && month === undefined && year === undefined && searchQuery === undefined) { // get cached news from the database
		const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'videos'));

		if (!snapshot.exists()) throw new Error('Videos page not found');

		return {
			...snapshot.data(),
			number: 0,
			totalPages: 50,
			totalElements: 5000,
			content: snapshot.data().videos.map((item: any) => {
				return {
					...item,
					publishedAt: item.publishedAt.toDate()
				} as VideoFirestoreListDocument
			})
		} as PageDto<VideoFirestoreListDocument>
	} else {
		let url = `${functionConfig.url}videoSearch?`;
		if (lastId) url += `lastFirestoreId=${lastId}`;
		if (pageSize) url += `&pageSize=${pageSize}`;
		if (month) url += `&month=${month}`;
		if (year) url += `&year=${year}`;
		if (searchQuery) url += `&searchQuery=${searchQuery}`;
		if (page) url += `&page=${page}`

		return fetch(url)
			.then(response => response.json())
			.then(data => {
				return {
					...data,
					content: data.content.map((item: any) => {
						return {
							...item,
							publishedAt: new Date(item.publishedAt)
						} as VideoFirestoreListDocument
					})
				} as PageDto<VideoFirestoreListDocument>
			})
	}
}
