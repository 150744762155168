import {FormControl, MenuItem, Select, SelectChangeEvent, SxProps, Typography, useTheme} from "@mui/material";
import {NkmbArrowBasicDown} from "@nitra/nkmb-theme";
import React, {useState} from "react";
import {useSeasonContext} from "@nitra/nkmb-card-components";

export function MobileStatisticsSeasonSelector(props: {
	sx?: SxProps,
}) {
	const theme = useTheme();
	const {season, availableSeasons, setSeason} = useSeasonContext()

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	}

	const handleOpen = () => {
		setOpen(true);
	}

	function handleChange(e: SelectChangeEvent<number>) {
		setSeason(e.target.value as number)
		handleClose()
	}

	return (
		<FormControl sx={{...props.sx}}>
			<Select
				id="seasons-select"
				value={season}
				onChange={handleChange}
				sx={{
					color: theme.palette.background.default,
					'& .MuiSelect-icon': {
						color: theme.palette.background.default,
						top: "calc(52% - 0.5em)"
					},
					'.MuiOutlinedInput-notchedOutline': {
						border: "0 !important",
					},
					'.MuiOutlinedInput-input': {
						px: 0
					},
					height: "24px",
				}}
				MenuProps={{
					style: {
						maxHeight: 300,
						color: "text.secondary",
						// backgroundColor: "red"
					}
				}}
				open={open}
				IconComponent={NkmbArrowBasicDown}
				inputProps={{}}
				onClose={handleClose}
				onOpen={handleOpen}
			>
				{availableSeasons.includes(42) &&
                    <MenuItem key={42} value={42}>
                        <Typography variant={"gumbS"}>Vse sezone</Typography>
                    </MenuItem>
				}
				{availableSeasons.filter((_season) => _season !== 42).map((_season) => (
					<MenuItem key={_season} value={_season}>
						<Typography variant={"gumbS"}>
							Sezona {_season.toString()}/{(_season + 1).toString().substring(2)}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
