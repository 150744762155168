import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export function StadiumSchema() {
	return (
		<Stack alignItems={"stretch"} spacing={2}>
			<img src={"/images/club/stadium-schema.png"} alt={"Shema stadiona Ljudski vrt"}/>
			<Stack direction={"row"} spacing={2} justifyContent={"center"}>
				{/*<Button variant={"contained"} sx={{borderRadius: "24px"}}>*/}
				{/*	<Typography>Natisni shemo</Typography>*/}
				{/*</Button>*/}
				<Link to={"https://www.vijolcnabajta.si/si/vstopnice.html"} target={"_blank"}>
					<Button variant={"contained"} sx={{borderRadius: "24px"}}>
						<Typography>Kupi vstopnico</Typography>
					</Button>
				</Link>
			</Stack>
		</Stack>
	)
}
