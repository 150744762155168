import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {ClubStadiumHistory} from "./history/ClubStadiumHistory";
import {ClubStadiumInfo} from "./info/ClubStadiumInfo";
import {ClubStadiumLegendaryMatch} from "./legendaryMatches/ClubStadiumLegendaryMatch";
import {ClubStadiumLegendaryMatches} from "./legendaryMatches/ClubStadiumLegendaryMatches";

export function ClubStadiumRouter() {
	console.log("Rendering ClubStadiumRouter");
	return (
		<Routes>
			<Route path="info" element={<ClubStadiumInfo/>}/>
			<Route path="stadiumHistory" element={<ClubStadiumHistory/>}/>
			<Route path="legendaryMatches" element={<ClubStadiumLegendaryMatches/>}/>
			<Route path="legendaryMatches/:key" element={<ClubStadiumLegendaryMatch/>}/>
			<Route index element={<Navigate to={"/club/stadium/info"} replace={true}/>}/>
		</Routes>
	);
}

