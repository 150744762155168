import {Box, Grid, Stack} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {CardContainerTitle} from "components/CardContainerTitle";
import {getCurrentStandings} from "firestore/BaseFirestore";
import ClubStandingsFirestoreDocument from "models/firestore/common/ClubStandingsFirestoreDocument";
import React, {useEffect, useState} from "react";
import {useCompetitionViewContext} from "../common/CompetitionContext";
import {CurrentStandingsCalendarCards} from "./CurrentStandingsCalendarCards";
import {CurrentStandingsDesktopGrid} from "./CurrentStandingsDesktopGrid";
import {CurrentStandingsMobileGrid} from "./CurrentStandingsMobileGrid";
import {CompetitionsRightStack} from "../common/CompetitionsRightStack";

export function CurrentStandings() {
	const [clubs, setClubs] = useState<ClubStandingsFirestoreDocument[]>([]);

	const {season} = useCompetitionViewContext();
	const isMobile = useIsMobileContext();

	useEffect(() => {
		if (season) {
			getCurrentStandings(
				season
			).then((data) => {
				setClubs(data.clubs);
			});
		}
	}, [season])

	if (isMobile) {
		return (
			<Stack direction={"column"} spacing={3} alignItems={"center"}>
				<img src={"/images/competition_logos/2022/SNL-lezeci.png"} alt={"SNL"}
				     style={{height: "60px", width: "120px"}}/>
				<CurrentStandingsMobileGrid clubs={clubs}/>
			</Stack>
		)
	} else {
		return (
			<Grid container sx={{pt: 4, maxWidth: "100vw"}}>
				<Box sx={{flexGrow: 1, minWidth: "752px"}}>
					<Stack direction={"column"} sx={{flexGrow: 1}} spacing={3}>
						<Box sx={{pr: 2}}>
							<CardContainerTitle title={`Lestvica sezona ${season}`} sx={{pt: 0}}/>
							<CurrentStandingsDesktopGrid clubs={clubs}/>
						</Box>
						<CurrentStandingsCalendarCards/>
					</Stack>
				</Box>
				<CompetitionsRightStack/>
			</Grid>
		);
	}
}
