import {Stack, Typography} from "@mui/material";
import {TextWidthContainer} from "../../components";
import React from "react";

export function FootballSchoolVision() {
	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack>
				<Typography variant={"bodyXL"}>
					• s strokovnostjo vodstvenih in trenerskih kadrov želimo otrokom ponuditi okolje, v katerem bodo
					razvijali
					nogometna in tudi osebnostna znanja ter se izrazili preko ljubezni do nogometne igre
				</Typography>
				<Typography variant={"bodyXL"} sx={{pt: 2}}>
					• ključ do naših ciljev so povezovanje, odnosi in jasen razvojni načrt za nogometaše v naši šoli
				</Typography>
				<Typography variant={"bodyXL"} sx={{pt: 2}}>
					• želimo si, da starši pri nas vidijo okolje, v katerem bodo nogometaši celostno obravnavani z
					željo, da
					uživajo v procesu in skupaj z nami doživijo svoje nogometne sanje.
				</Typography>
			</Stack>
		</TextWidthContainer>
	)
}