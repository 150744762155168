import {TextWidthContainer} from "../../components";
import {Stack, Typography} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";

export function FootballSchoolPreschool() {
	return (
		<TextWidthContainer sx={{pt: 5}}>
			<Stack>
				<Typography variant={"bodyXL"}>
					Pod okriljem naše Otroške nogometne šole NK Maribor pa poteka tudi projekt poučne, zabavne vadbe
					za najmlajše. Poteka dvakrat tedensko v Ljudskem vrtu in MP Zone in skozi celotno šolsko leto pod
					strokovnim vodstvom naših trenerjev. Vabilo v Vijol'čni nogometni vrtec velja za otroke, rojene v
					letih
					2018 in 2019.
				</Typography>

				<Typography variant={"bodyXL"} sx={{pt: 2}}>
					Vodja nogometnega vrtca: Primož Kocuvan
				</Typography>
				<Stack sx={{justifyContent: "space-between"}}>
					<Stack direction={"row"}>
						<CallIcon/>
						<Typography sx={{pl: 1}}>031 285 665</Typography>
						<Typography sx={{pl: 1}}>(PON/PET med 11. in 13. uro)</Typography>
					</Stack>

					<Stack direction={"row"}>
						<EmailIcon/>
						<Typography sx={{pl: 1}}>vrtec@nkmaribor.com</Typography>
					</Stack>
				</Stack>
			</Stack>
		</TextWidthContainer>
	)
}