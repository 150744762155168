import {Box, SxProps} from "@mui/material";

import {useViewportWidth} from "@nitra/nkmb-card-components";
import {PropsWithChildren, useEffect, useRef, useState} from "react";

/**
 * Container for full window width. This is used for special banners that go past the width of the paper.
 * @param props
 * @constructor
 */
export function FullWidthContainer(props: PropsWithChildren<{
	sx?: SxProps,
	innerSx?: SxProps,
}>) {
	const {viewportWidth} = useViewportWidth()
	const parentWidthRef = useRef<HTMLDivElement | null>(null);
	const [leftOffset, setLeftOffset] = useState(0);

	useEffect(() => {
		if (parentWidthRef.current) {
			const parentWidth = parentWidthRef.current.clientWidth;
			setLeftOffset((viewportWidth - parentWidth) / 2);
		}
	}, [viewportWidth, parentWidthRef]);

	return (
		<Box
			ref={parentWidthRef}
			sx={{
				position: 'relative',
				width: '100%',
				...props.sx,
			}}
		>
			<Box sx={{
				position: 'relative',
				left: -leftOffset,
				width: '100vw',
				overflow: 'hidden',

				...props.innerSx,
			}}>
				{props.children}
			</Box>
		</Box>
	);
}
