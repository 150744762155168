import {Grid, Typography} from "@mui/material";
import {CardContainerTitle} from "components/CardContainerTitle";
import React from "react";
import {StadiumTicketCounter} from "./StadiumTicketCounter";

export function StadiumTicketBuySpots() {
	return (
		<Grid container spacing={1}>
			<Grid item>
				<CardContainerTitle title={"Blagajne"}/>
			</Grid>
			<StadiumTicketCounter name={"A"} location={"Jug"} description={"dve prodajni mesti"}/>
			<StadiumTicketCounter name={"B"} location={"Sever"} description={"eno prodajno mesto"}/>
			<StadiumTicketCounter name={"C"} location={"Sever"} description={"eno prodajno mesto + VIP in press"}/>
			<StadiumTicketCounter name={"D"} location={"Jug"} description={"prodajno mesto samo za tribuno jug"}/>
			<Grid item>
				<Typography variant={"bodyS"}>Blagajne se na dan tekme odprejo vsaj 60 minut pred sodniškim
					žvižgom.</Typography>
			</Grid>
		</Grid>
	)
}

