import {Box, SxProps, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Link} from "react-router-dom";

/**
 * Title element to be placed above Grid with cards
 * @param props
 * @constructor
 */
export function CardContainerTitle(props: {
	title: string,
	to?: string,
	sx?: SxProps,
}) {
	const {title, to} = props;

	const isMobile = useIsMobileContext()

	return (
		<Box
			sx={{
				pt: isMobile ? 2 : 3,
				marginRight: "auto",
				pb: isMobile ? 1 : 3,
				textDecoration: "none",
				color: "inherit",
				...props.sx
			}}
			component={to ? Link : "div"}
			target={to?.startsWith("http") ? "_blank" : undefined}
			to={to}
		>
			<Box sx={{height: "64px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
				<Typography variant={isMobile ? "podnaslovXS" : "section"}>{title}</Typography>
			</Box>
		</Box>
	)
}
