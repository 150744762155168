import {Box, SxProps, useTheme} from "@mui/material";
import {PropsWithChildren} from "react";

/**
 * Container for the width of the paper, useful for banners, to stay the correct width.
 * @param props
 * @constructor
 */
export function PaperWidthContainer(props: PropsWithChildren<{
	sx?: SxProps,
	innerSx?: SxProps,
}>) {
	const theme = useTheme();
	const breakpointXL = theme.breakpoints.values.xl

	return (
		<Box sx={{
			position: 'relative',
			width: "100%",
			maxWidth: breakpointXL,
			...props.sx,
		}}>
			<Box sx={{
				position: 'relative',
				width: '100%',
				overflow: 'hidden',

				...props.innerSx,
			}}>
				{props.children}
			</Box>
		</Box>
	);
}
