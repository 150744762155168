import {Stack} from "@mui/material";
import {ClubBanner} from "views/club/ClubBanner";
import {InnerWidthContainer} from "../../components";
import {FootballSchoolPrimarySchoolsBody} from "./FootballSchoolPrimarySchoolsBody";
import React from "react";
import {ButtonTabNavigatorWithRouter} from "@nitra/nkmb-card-components";
import {FootballSchoolPreschool} from "./FootballSchoolPreschool";

const tabs = [{
	label: "ONŠ",
	path: "ons",
	component: <FootballSchoolPrimarySchoolsBody/>
}, {
	label: "Vrtec",
	path: "preschool",
	component: <FootballSchoolPreschool/>
}]

export function FootballSchoolPrimarySchools() {
	return (
		<Stack alignItems={"center"}>
			<ClubBanner
				url={"/images/football-school-banner.png"}
				mainText={"Otroška nogometna šola"}
				alignText={"stretch"}/>
			<InnerWidthContainer>
				<ButtonTabNavigatorWithRouter tabs={tabs} sx={{}}/>
			</InnerWidthContainer>
		</Stack>
	)
}
