import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {getSponsorsPage} from "firestore";
import {SponsorCategoryFirestoreListDocument, SponsorFirestoreListDocument} from "models";
import React, {useEffect, useState} from "react";
import {MoreSponsorsMobile} from "./sponsors/MoreSponsorsMobile";
import {MoreSponsorsDesktop} from "./sponsors/MoreSponsorsDesktop";

export function MoreSponsors() {
	const [categories, setCategories] = useState<SponsorCategoryFirestoreListDocument[]>([]);
	const [sponsors, setSponsors] = useState<SponsorFirestoreListDocument[]>([]);

	const isMobile = useIsMobileContext();

	useEffect(() => {
		getALl();
	}, []);

	async function getALl() {
		const response = await getSponsorsPage();

		if (response) {
			setCategories(response.categories);
			setSponsors(response.sponsors);
		}
	}

	return isMobile ?
		(<MoreSponsorsMobile categories={categories} sponsors={sponsors}/>) :
		(<MoreSponsorsDesktop categories={categories} sponsors={sponsors}/>)
}

