import {Box, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {
	CardOverImageTitle,
	CardWithShadow,
	useBreakpointContext,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import React from "react";

import {VipButton} from "./VipButton";

export function VipElementWithScreenWideBackground(props: {
	imageTitle: string,
	imageUrl: string,
	link: string,

	textTitle: string,
	textBody: string,

	buttonLabel: string,
}) {
	const {imageTitle, imageUrl, link, textTitle, textBody, buttonLabel} = props;

	const theme = useTheme();
	const {isIos} = useIosContext()
	const isMobile = useIsMobileContext()
	const breakpoint = useBreakpointContext()

	return (
		<Box sx={{
			position: "relative",
			mt: 4,
			py: 4,
			width: "100%",
		}}>
			<Box sx={{
				position: "absolute",
				top: 0,
				left: "-50vw",
				right: "-50vw",
				height: "100%",
				backgroundColor: "rgba(217, 217, 217, 0.1)",
			}}/>
			<Stack
				direction={"row"}
				justifyContent="center"
				alignItems="flex-start"
				spacing={breakpoint === "xl" ? 8 : 0}
				sx={{
					mt: 0,

					flexWrap: {
						xs: "wrap",
						sm: "wrap",
						md: "wrap",
						lg: "nowrap"
					}
				}}
			>
				<CardWithShadow
					cardHeightOverride={360}
					to={link}
					fullSizeImageUrl={imageUrl}
					sx={{
						width: "600px",
						minWidth: "350px",
						position: "relative",
						display: "flex", flexDirection: "column",
					}}
				>
					<CardContent sx={{mt: "auto", zIndex: 1, pb: 0}}>
						<CardOverImageTitle title={imageTitle} variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}/>
					</CardContent>
				</CardWithShadow>
				{
					!isMobile &&

                    <Stack direction="column"
                           justifyContent="start"
                           alignItems="flex-start"
                           sx={{
						       width: "600px",
						       minWidth: "350px",
						       p: 2,
					       }}
                           spacing={2}
                    >
                        <Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}} color={"#FFF"}>{textTitle}</Typography>
                        <Typography variant={"body1"} align={"justify"} color={"#FFF"}>{textBody}</Typography>
                        <VipButton sx={{color: theme.palette.background.paper}} label={buttonLabel} to={link}/>
                    </Stack>
				}
			</Stack>
		</Box>
	)
}
