import {Stack} from "@mui/material";
import {Group} from "models/Group";
import {Person} from "models/Person";
import React from "react";
import {PersonsGridGroupElement} from "./PersonsGridGroupElement";

export function PersonsGrid(props: {
	groups: Group<Person>[]
}) {
	const {groups} = props

	return (
		<Stack direction={"column"} sx={{
			px: {xs: 2, md: 3},
			mx: 0,
		}}>
			{groups.map((group, index) => (
				<PersonsGridGroupElement key={index} group={group}/>
			))}
		</Stack>
	)
}
