import {Grid} from "@mui/material";
import {CardContainerTitle} from "../../../../components/CardContainerTitle";
import {StadiumEntrance} from "./StadiumEntrance";
import React from "react";

export function StadiumEntrances() {
	return (
		<Grid item xs={12}>
			<CardContainerTitle title={"Vhodi"}/>
			<Grid container spacing={1}>
				<StadiumEntrance name={"1"} location={"Severozahod"} description={"vstop na Marcos Tavares"}/>
				<StadiumEntrance name={"2"} location={"Jugozahod"} description={"vstop na Marcos Tavares"}/>
				<StadiumEntrance name={"3"} location={"Jug"} description={"vstop na tribuno jug"}/>
				<StadiumEntrance name={"4"} location={"Jug"} description={"vstop na tribuno jug"}/>
				<StadiumEntrance name={"5"} location={"Jugovzhod"} description={"vstop na tribuno vzhod"}/>
				<StadiumEntrance name={"6"} location={"Severovzhod"} description={"vstop na tribuno vzhod"}/>
				<StadiumEntrance name={"7"} location={"Sever"} description={"vstop na tribuno sever"}/>
				<StadiumEntrance name={"8"} location={"Sever"} description={"vstop na tribuno sever"}/>
				<StadiumEntrance name={"V1"} location={"Severozahod"} description={"vstop na Marcos Tavares - VIP"}/>
				<StadiumEntrance name={"V2"} location={"Jugozahod"} description={"vstop na Marcos Tavares - VIP"}/>
			</Grid>
		</Grid>
	)
}
