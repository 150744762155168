import {ButtonTabNavigatorButtons} from "@nitra/nkmb-card-components";
import React from "react";

const tabs = [{
	label: "Grb",
	path: "emblem",
}, {
	label: "Barve",
	path: "colors",
}, {
	label: "Regija",
	path: "region",
}]

export function ClubSymbolNavigationButtons() {
	return (
		<ButtonTabNavigatorButtons
			tabs={tabs}
			replaceLastPath={true}
		/>
	)
}
