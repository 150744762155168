import {EPlayerSidebarStatType} from "@nitra/nkmb-card-components";
import {ScoreAssistTableCell} from "views/competitions/playerStatSidebar/ScoreAssistTableCell";
import {ScoreAssistTableRow} from "views/competitions/playerStatSidebar/ScoreAssistTableRow";

export function ScoreAssistHeaderRow(props: {
	type: EPlayerSidebarStatType,
}) {
	const {type} = props;

	return (
		<ScoreAssistTableRow nonDefaultBackground={true}>
			<ScoreAssistTableCell text={"#"} bold={true}/>
			<ScoreAssistTableCell text={"Ime Priimek"} bold={true} wide={true}/>
			<ScoreAssistTableCell text={"T"} bold={true}/>
			<ScoreAssistTableCell text={"M"} bold={true}/>
			<ScoreAssistTableCell text={type === EPlayerSidebarStatType.GOAL ? "G" : "A"} bold={true}/>
		</ScoreAssistTableRow>
	)
}
