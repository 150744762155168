import {useIsMobileContext} from "@nitra/nkmb-card-components";
import Banner from "../../components/Banner";
import BannerFirebaseDocument from "../../models/firestore/common/BannerFirebaseDocument";

export function LandingTopBanner(props: { data: BannerFirebaseDocument }) {
	const {title, image1440x480url, image720x540url, createdAt, actionLink, isForMatch} = props.data;

	const isMobile = useIsMobileContext();

	return (
		<Banner
			title={title}
			imageUrl={(isMobile && image720x540url) ? image720x540url : image1440x480url}
			createdAt={createdAt}
			onClickLink={actionLink}
			isForMatch={isForMatch}
			desktopHeight={(isMobile && image720x540url) ? 1080 : 480}
		/>
	)
}
