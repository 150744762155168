import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {CompetitionStatisticsClubPlayersTable} from "./CompetitionStatisticsClubPlayersTable";
import {CompetitionsRightStack} from "../common/CompetitionsRightStack";
import React from "react";
import {CompetitionFilterButtons} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtons";
import {ECompetitionType} from "@nitra/nkmb-card-components";

export function DesktopCompetitionPlayerStatistics() {
	const {seasonNumber} = useCompetitionStatisticsContext();

	return (
		<Grid container sx={{pt: 4, maxWidth: "100vw"}}>
			<Box sx={{flexGrow: 1, minWidth: "752px"}}>
				<Stack>
					<Stack direction={"row"} alignItems={"center"}>
						<Typography variant={"podnaslovM"}>Statistika kluba
							sezona {seasonNumber}/{seasonNumber + 1}</Typography>
						<Box sx={{ml: "auto", mr: 3}}>
							<CompetitionFilterButtons competitionsOverride={[
								ECompetitionType.FIRST_LEAGUE,
								ECompetitionType.CUP,
								ECompetitionType.ANY_UEFA,
							]}/>
						</Box>
					</Stack>
					<CompetitionStatisticsClubPlayersTable/>
				</Stack>
			</Box>
			<CompetitionsRightStack/>
		</Grid>
	);
}