import React, {PropsWithChildren, useEffect} from "react";
import {Box, Divider, Drawer, List, useTheme} from "@mui/material";
import {INavigationTarget, navigationTargets} from "./NavigationTargets";
import {LoginDrawerComponent} from "./LoginDrawerComponent";
import {NavigationItem} from "./NavigationItem";
import {MenuDrawerSocials} from "./MenuDrawerSocials";

export const MobileDrawer = (props: PropsWithChildren<{
	handleDrawerToggle: () => void;
	open: boolean;
	isLoggedIn: boolean;
}>) => {
	const {handleDrawerToggle, open, isLoggedIn} = props;

	const theme = useTheme();

	const [openCollapseIndex, setOpenCollapseIndex] = React.useState(-1);

	const handleClose = () => {
		setOpenCollapseIndex(-1)
		handleDrawerToggle()
	}

	useEffect(() => {
		if (!open) {
			setOpenCollapseIndex(-1)
		}
	}, [open])

	return (
		<Drawer
			variant="temporary"
			open={open}
			onClose={handleClose}
			disableScrollLock={true} // This thing prevents the whitespace, but it allows scrolling the page behind the drawer
			sx={{
				'& .MuiDrawer-paper': {
					boxSizing: 'border-box',
					width: "100%",
					[theme.breakpoints.up('sm')]: {
						width: "400px",
					},
				},
				minHeight: "100vh",
			}}
		>
			<Box
				id={"mobile-drawer"}
				sx={{textAlign: 'center', paddingTop: theme.mixins.toolbar.minHeight + "px"}}>
				{!isLoggedIn && <LoginDrawerComponent/>}
				<List>
					<NavigationItem
						item={{
							title: "Vijol'čna bajta",
							url: "https://www.vijolcnabajta.si/si.html",
							showInMobile: true,
							showInDesktop: false
						}}
						index={0}
						closeDrawer={handleClose}
						isDrawerOpen={open}
						openCollapseIndex={openCollapseIndex}
						setOpenCollapseIndex={setOpenCollapseIndex}
						sx={{
							color: theme.palette.primary.main,
							backgroundColor: theme.palette.secondary.main,
							mr: -4,
							mt: 1,
						}}
					/>
					{navigationTargets
						.filter((item) => item.showInMobile)
						.map((item: INavigationTarget, index) => (
							<React.Fragment key={index}>
								{index !== 0 && <Divider/>}
								<NavigationItem item={item} index={index} closeDrawer={handleClose}
								                isDrawerOpen={open}
								                openCollapseIndex={openCollapseIndex}
								                setOpenCollapseIndex={setOpenCollapseIndex}
								                sx={{color: "black"}}
								/>
							</React.Fragment>
						))}
				</List>
				<Box sx={{px: 4, pt: "inherit"}}>
					<MenuDrawerSocials/>
				</Box>
			</Box>
		</Drawer>
	)
}
