import {Box, Stack, SxProps, Typography, useTheme} from "@mui/material";
import {ShareArea, toLongDate, toWeekdayDayAndTime, useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {useLocation} from "react-router-dom";

export const NewsArticleHeader = (props: {
	title: string,
	createdAt: Date,
	image1440x680url?: string,
	image720x540url?: string,
	hideTitle?: boolean,
	sx?: SxProps,
}) => {
	const {title, createdAt, image1440x680url, image720x540url, hideTitle} = props;
	const isMobile = useIsMobileContext()
	const location = useLocation()
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Box sx={{
			backgroundImage: `url(${isMobile && image720x540url ? image720x540url : image1440x680url}), url(/images/placeholder1440x680.png)`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			aspectRatio: isMobile && image720x540url ? "720/540" : "1440 / 680",
			position: "relative",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			...props.sx
		}}>
			{
				!hideTitle && <>
                    <div style={{
						backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0.8))",
						zIndex: 0,
						position: "absolute", top: 0, left: 0, right: 0, bottom: 0
					}}/>
                    <Stack
                        justifyContent="flex-end"
                        alignItems={isMobile ? "flex-start" : "center"}
                        sx={{
							zIndex: 1,
							pb: isMobile ? 0 : 4,
							flexGrow: 1,
							width: "100%"
						}}
                    >
                        <Typography sx={{
							color: "background.paper",
							typography: {xs: "h4", md: "h2"},
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
							px: isMobile ? 2 : 0,
							pb: isMobile ? 0 : 1
						}}>{title}</Typography>
						{isMobile ?
							<MobileDateShareLine createdAt={createdAt}/>
							: <>
								<Typography
									sx={{pb: 1}}
									color={"#FFF"}
									variant={"bodyL"}
								>{toWeekdayDayAndTime(createdAt)}</Typography>
								<ShareArea
									iconSx={{fontSize: 12}}
									sx={{mr: "auto"}}
									shareUrl={location.pathname}
									brightText={true}
								/>
							</>
						}
                    </Stack>
                </>}
		</Box>
	)
}

function MobileDateShareLine(props: {
	createdAt: Date,
}) {
	const {createdAt} = props;
	const location = useLocation()

	return (
		<Stack direction={"row"}
		       sx={{
			       width: "100%",
			       // transform: "translateX(-32px)",
		       }}
		       justifyItems={"center"}
		       alignItems={"center"}
		       justifyContent={"center"}
		>
			<ShareArea
				iconSx={{fontSize: 12}}
				sx={{
					mr: 2,
					ml: 2,
					mt: "12px",
					mb: "12px",
				}}
				shareUrl={location.pathname}
				brightText={true}
			/>
			<Typography
				sx={{mr: "auto"}}
				color={"#FFF"}
				variant={"bodyL"}
			>{toLongDate(createdAt)}</Typography>
		</Stack>
	)
}