import {Collapse, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {NkmbArrowBasicDown, NkmbArrowBasicUp} from "@nitra/nkmb-theme";
import {ClubStatisticsMatchFirestoreDocument} from "models";
import React from "react";
import {
	MobileClubStatisticsExpandableListBasicDataLine
} from "views/club/statistics/mobile/club/MobileClubStatisticsExpandableListBasicDataLine";
import {
	MobileClubStatisticsExpandableListPlayerDataLine
} from "views/club/statistics/mobile/club/MobileClubStatisticsExpandableListPlayerDataLine";

export function MobileClubStatisticsExpendableListItemButton(props: {
	match: ClubStatisticsMatchFirestoreDocument,
	isOpen: boolean,
	calledCallback: (key: string) => void,
	_key: string
	season: number,
}) {
	const {_key, match, isOpen, calledCallback, season} = props;
	const theme = useTheme()


	return (
		<>
			<ListItemButton onClick={() => calledCallback(isOpen ? "" : _key)}>
				<Typography variant={"gumbS"}>{match.round}. krog</Typography>
				{isOpen ? <NkmbArrowBasicUp/> : <NkmbArrowBasicDown/>}
			</ListItemButton>
			<Collapse in={isOpen} timeout={"auto"} unmountOnExit>
				<Stack sx={{backgroundColor: theme.palette.background.default, pb: 2}}>
					<Typography variant={"podnaslovXS"}
					            sx={{pt: 2, pb: 1, px: 2,}}>{match.homeName} - {match.awayName}</Typography>
					<MobileClubStatisticsExpandableListBasicDataLine label={"tekma"}
					                                                 value={`${match.homeName} - ${match.awayName}`}/>
					<MobileClubStatisticsExpandableListBasicDataLine label={"sezona"}
					                                                 value={`${season} / ${season + 1}`}/>
					<MobileClubStatisticsExpandableListBasicDataLine label={"datum"}
					                                                 value={match.date.toLocaleString("sl-sl", {
						                                                 day: "numeric",
						                                                 month: "long",
						                                                 year: "numeric"
					                                                 })}
					/>
					<MobileClubStatisticsExpandableListBasicDataLine label={"kolo"} value={match.round?.toString()}/>
					{
						match.result &&

                        <MobileClubStatisticsExpandableListBasicDataLine label={"rezultat"}
                                                                         value={match.result}/>
					}
					{
						match.spectators && <MobileClubStatisticsExpandableListBasicDataLine
                            label={"gledalci"}
                            value={match.spectators?.toString()}/>
					}


					<Typography variant={"podnaslovXS"} sx={{pt: 5, pb: 1, px: 2,}}>Postava</Typography>
					{
						match.lineup
							.sort((a) => a.isSubstitute ? 1 : -1)
							.map((player) =>
								<MobileClubStatisticsExpandableListPlayerDataLine
									key={player.player}
									label={player.player}
									value={player.isSubstitute ? "menjava" : "11"}
								/>
							)
					}

					<Typography variant={"podnaslovXS"}
					            sx={{pt: 5, pb: 1, px: 2, color: "#676767"}}>Strelci</Typography>
					{
						match.goals.map((goal) =>
							<MobileClubStatisticsExpandableListPlayerDataLine
								key={goal.result + goal.playerName}
								label={goal.playerName}
								value={""}
								forceThin={!goal.isForNkMaribor}
							/>
						)
					}
				</Stack>
			</Collapse>
		</>
	)
}
