import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {INavigationBarTab} from "./INavigationBarTab";
import {NavigationBarDesktop} from "./NavigationBarDesktop";

export function NavigationBar(props: {
	tabs: INavigationBarTab[]
}) {
	const {tabs} = props

	const {pathname} = useLocation()

	const [currentTab, setCurrentTab] = useState(tabs[0].url)

	useEffect(() => {
		const newTab = tabs.find(tab => pathname.includes(tab.url))
		if (newTab) setCurrentTab(newTab.url)
	}, [pathname, tabs])

	return <NavigationBarDesktop tabs={tabs} currentTab={currentTab}/>
}

