import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowShield() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/shield.png"}
			title={"4. Ščit"}
			text={"Uporaba ščita v logotipu NK Maribor ima dvojni pomen. Ščit tako pomeni zavetje pred poškodbo oz. travmo, kar pomeni, da je lahko blagovna znamka, katero predstavlja ta ščit, zaupanja vredna, da bo svojim odjemalcem ponudila najboljše produkte in storitve. Ščit v logotipu NK Maribor pomeni tudi način učenja mladih igralcev, kako postaviti svoje telo med nasprotnega igralca in žogo."}
		/>
	)
}
