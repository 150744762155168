import {createTheme, Grid, Stack, Typography, useTheme} from "@mui/material";
import {ThemeProvider} from "@mui/system";

import {useFooterColorMode} from "@nitra/nkmb-card-components";
import theme from "@nitra/nkmb-theme";
import {Link} from "react-router-dom";
import {MenuDrawerSocials} from "../AppBar/MenuDrawerSocials";
import SponsorIcon from "./SponsorIcon";

const blackTheme = createTheme(theme, {
	palette: {
		text: {
			primary: "#FFF",
		},
	},
})

const Footer = () => {
	const {isMonochrome} = useFooterColorMode()
	const theme = useTheme();

	const bigSponsors = [
		{logoFileName: "sava.png", link: "https://www.zav-sava.si/", name: "Zavarovalnica Sava"},
		{logoFileName: "adidas.png", link: "https://www.adidas.com/us", name: "Adidas"},
	];

	const smallSponsors = [
		{logoFileName: "lasko.png", link: "https://www.lasko.eu/", name: "Laško"},
		{logoFileName: "terme3000.png", link: "https://www.sava-hotels-resorts.com/si/", name: "Sava hotels & resorts"},
		{logoFileName: "mastercard.png", link: "https://www.mastercard.si/sl-si.html", name: "Mastercard"},
		{logoFileName: "TotiRadio.png", link: "https://totiradio.si/", name: "Toti radio"},
		{logoFileName: "PostaSlovenije.png", link: "https://www.posta.si/", name: "Pošta Slovenije"},
		{logoFileName: "otp.png", link: "https://www.otpbanka.si/", name: "Nova KBM"},
	]

	return (
		<Grid
			container
			direction={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			sx={{
				minHeight: 313,
				backgroundColor: isMonochrome ? "" : theme.palette.background.default,
			}}
		>
			<Grid item>
				<Stack alignItems={"center"} sx={{pt: 5, opacity: isMonochrome ? "0.2" : "0.6"}}>
					<ThemeProvider theme={isMonochrome ? blackTheme : theme}>
						<Typography sx={{
							pb: 2,
							color: isMonochrome ? blackTheme.palette.text.primary : theme.palette.text.primary
						}} variant={"bodyS"}>Sledite nam</Typography>
						<MenuDrawerSocials/>
					</ThemeProvider>
				</Stack>
			</Grid>
			<Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={0}>
				{bigSponsors.map((sponsor, index) => (
					<Grid item key={index} xs={6} sm={4} md={4} lg={2} sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<SponsorIcon
							logoFileName={sponsor.logoFileName}
							link={sponsor.link}
							name={sponsor.name}
							isBig={true}
							useMonochrome={isMonochrome}/>
					</Grid>
				))}
			</Grid>
			<Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"}>
				{smallSponsors.map((sponsor, index) => (
					<Grid item key={index} xs={3} md={1} sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<SponsorIcon
							logoFileName={sponsor.logoFileName}
							link={sponsor.link}
							name={sponsor.name}
							isBig={false}
							useMonochrome={isMonochrome}/>
					</Grid>
				))}
			</Grid>
			<Grid item container direction={"column"} justifyContent={"center"} alignItems={"center"}
			      sx={{color: isMonochrome ? "#FFF" : ""}}>
				<Typography variant={"podnapis"}>Uradna spletna stran Nogometnega Kluba
					Maribor</Typography>
				<Typography variant={"podnapis"}>vse pravice pridržane © NK Maribor,&nbsp;
					{(new Date()).getFullYear()}</Typography>
				<Link
					style={{
						textDecoration: "none",
						height: "12px",
						lineHeight: "12px",
						marginBottom: "16px",
					}}
					to={"/more/cookies"}>
					<Typography variant={"podnapis"}
					            sx={{
						            mb: 2,
						            textDecoration: "none",
						            color: isMonochrome ? "#FFF" : "#181818"
					            }}>
						Politika piškotkov
					</Typography>
				</Link>
			</Grid>
		</Grid>
	)
}

export default Footer;
