import {alpha, Stack, Typography} from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import theme from "@nitra/nkmb-theme";
import typography from "@nitra/nkmb-theme/typography";
import {StatisticsPlayerShooterAppearanceFlat} from "models";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MobileStatisticsSearchBar} from "views/club/statistics/mobile/player/MobileStatisticsSearchBar";

const columns: GridColumns<StatisticsPlayerShooterAppearanceFlat> = [{
	headerName: "Igralec",
	field: "firstName",
	renderCell: (params) => <Typography variant={"bodyM"}>{params.row.firstName} {params.row.lastName}</Typography>,
	flex: 1,
}, {
	headerName: "Liga",
	field: "league",
	type: "number",
	width: 58,
}, {
	headerName: "Pokal",
	field: "cup",
	type: "number",
	width: 58,
}, {
	headerName: "Evropa",
	field: "uefa",
	type: "number",
	width: 58,
}, {
	headerName: "Skupaj",
	field: "total",
	type: "number",
	width: 58,

}]

export function MobilePlayerShooterAppearanceStatistics(props: {
	players: StatisticsPlayerShooterAppearanceFlat[],
	title: string,
	isForGoals?: boolean
}) {
	const {players, title, isForGoals} = props;

	const [filteredPlayers, setFilteredPlayers] = useState<StatisticsPlayerShooterAppearanceFlat[]>(players)
	const [filter, setFilter] = useState<string>("")

	const navigate = useNavigate();

	useEffect(() => {
		if (filter && filter.length > 2) {
			setFilteredPlayers(players.filter(player => (player.firstName + " " + player.lastName).toLowerCase().includes(filter.toLowerCase())))
		} else {
			setFilteredPlayers(players)
		}
	}, [filter, players]);

	return (
		<Stack sx={{pt: 4}} spacing={2}>
			<Typography sx={{px: 2}} variant={"podnaslovXS"}>{title}</Typography>
			<MobileStatisticsSearchBar filter={filter} setFilter={setFilter}/>
			<DataGrid

				getRowId={row => row.key}
				columns={columns}
				rows={filteredPlayers}

				onRowClick={(params) => {
					navigate(`/club/statistics/player/${params.id}${isForGoals ? "?goal=1" : ""}`)
				}}

				hideFooterPagination={true}
				hideFooter={true}
				autoHeight={true}
				disableColumnMenu={true}

				rowHeight={31}
				headerHeight={31}

				sx={{
					'& .MuiDataGrid-columnSeparator': {
						display: "none"
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						...typography.bodySBold,
					},
					'& .MuiDataGrid-columnHeaders': {
						backgroundColor: alpha(theme.palette.text.primary, 0.07)
					},
				}}
			/>
		</Stack>
	)
}
