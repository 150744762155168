import {Stack} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getDecade} from "firestore/BaseFirestore";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Banner from "../../../components/Banner";

export function DecadeDetailsPage() {
	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [bannerName, setBannerName] = useState("");
	const [bannerUrl, setBannerUrl] = useState("");
	const [image720x540url, setImage720x540url] = useState("");

	const {key} = useParams();
	const isMobile = useIsMobileContext()

	const getData = (_key: string) => {
		getDecade(_key).then((data) => {
			setContent(data.content);
			setBannerName(data.title);
			setBannerUrl(data.image1440x680url);
			setImage720x540url(data.image720x540url ?? "");
		})
	}

	useEffect(() => {
		if (!key) {
			console.error("No key provided");
			return;
		}

		getData(key);
	}, [key])

	return (
		<Stack>
			{(isMobile && image720x540url) ?
				<Banner title={bannerName} imageUrl={image720x540url} desktopHeight={680}
				        sx={{aspectRatio: "720/540"}}/>
				:
				<Banner title={bannerName} imageUrl={bannerUrl} desktopHeight={680}/>
			}

			<ContentContainer content={content}/>
		</Stack>
	)
}
