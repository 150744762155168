import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Box, Stack, Typography, useTheme} from "@mui/material";

/**
 * Live tag as per figma shows a red background for desktop and is transparent for mobile.
 * @constructor
 */
export function LiveTag() {
	const isMobile = useIsMobileContext();
	const theme = useTheme();

	return (
		<Stack direction={"row"}>
			<Box sx={{
				backgroundColor: isMobile ? "unset" : "#FE0000",
				borderRadius: "8px",
				pt: "12px",
				pb: 0,
				pl: 2,
				pr: 4,
			}}>
				<Typography variant={isMobile ? "h3" : "h1"} sx={{color: theme.palette.background.default}}>V
					ŽIVO</Typography>
			</Box>
			<Box sx={{flexGrow: 1}}/>
		</Stack>
	)
}