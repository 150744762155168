import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {MatchTicketFirestoreDocument} from "../../models";
import {getTicketsPage} from "../../firestore/specificGetters/TicketRequests";

const TicketDataContext = createContext<{
	selectedMatch: MatchTicketFirestoreDocument | null,
	canSelectPreviousMatch: boolean,
	selectPreviousMatch: () => void,
	canSelectNextMatch: boolean,
	selectNextMatch: () => void,
	selectSpecificMatch: (key: string) => void,

	seasonNormalTicketLink?: string,
	seasonVipTicketLink?: string,
} | null>(null);

export default function useTicketData() {
	const context = useContext(TicketDataContext);
	if (context === null) {
		throw new Error('useTicketDataContext must be used within a TicketDataContext');
	}
	return context;
}

export function TicketDataContextProvider(props: PropsWithChildren<{}>) {
	const [matches, setMatches] = useState<MatchTicketFirestoreDocument[]>([]);
	const [seasonNormalTicketLink, setSeasonNormalTicketLink] = useState<string | undefined>(undefined);
	const [seasonVipTicketLink, setSeasonVipTicketLink] = useState<string | undefined>(undefined);

	const [canSelectPreviousMatch, setCanSelectPreviousMatch] = useState<boolean>(false);
	const [canSelectNextMatch, setCanSelectNextMatch] = useState<boolean>(false);
	const [selectedMatchIndex, setSelectedMatchIndex] = useState<number>(0);

	const selectPreviousMatch = () => {
		if (canSelectPreviousMatch) {
			setSelectedMatchIndex(selectedMatchIndex - 1);
		}
	};

	const selectNextMatch = () => {
		if (canSelectNextMatch) {
			setSelectedMatchIndex(selectedMatchIndex + 1);
		}
	};

	const selectSpecificMatch = (key: string) => {
		const index = matches.findIndex(match => match.key === key);
		if (index !== -1) {
			setSelectedMatchIndex(index);
		}
	};

	useEffect(() => {
		if (matches.length > 0) {
			setCanSelectPreviousMatch(selectedMatchIndex > 0);
			setCanSelectNextMatch(selectedMatchIndex < matches.length - 1);
		}
	}, [selectedMatchIndex, matches]);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		const response = await getTicketsPage()
		if (response) {
			setMatches(response.matchTickets);
			setSeasonNormalTicketLink(response.seasonNormalTicketLink);
			setSeasonVipTicketLink(response.seasonVipTicketLink);
		}
	}

	return (
		<TicketDataContext.Provider value={{
			selectedMatch: matches.length > selectedMatchIndex ? matches[selectedMatchIndex] : null,
			canSelectPreviousMatch: canSelectPreviousMatch,
			selectPreviousMatch: selectPreviousMatch,
			canSelectNextMatch: canSelectNextMatch,
			selectNextMatch: selectNextMatch,
			selectSpecificMatch: selectSpecificMatch,
			seasonNormalTicketLink: seasonNormalTicketLink,
			seasonVipTicketLink: seasonVipTicketLink,
		}}>
			{props.children}
		</TicketDataContext.Provider>
	);
}