import {Button, Grid, Typography, useTheme} from "@mui/material";
import {ECompetitionType, MatchListElementFirestoreDocument, useIsMobileContext} from "@nitra/nkmb-card-components";
import {PokalPivovarnaUnion, PrvaLigaTelemach} from "components/Logos";

import {PropsWithChildren} from "react";

const NextMatchBanner = (props: PropsWithChildren<{ data: MatchListElementFirestoreDocument }>) => {
	const {
		competition,
		date,
		// stadium,
		homeTeamName,
		homeTeamLogoUrl,
		awayTeamName,
		awayTeamLogoUrl,
		buyTicketLink
	} = props.data;

	const isMobile = useIsMobileContext();
	const theme = useTheme();

	const prettyDate = date.toLocaleDateString(["sl-sl"], {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		weekday: "long",
	})

	const prettyTime = date.toLocaleTimeString(["sl-sl"], {
		hour: "numeric",
		minute: "numeric",
	})

	const handleBuyTicketClick = () => {
		window.open(buyTicketLink, "_blank");
	}

	return (
		<div style={{
			backgroundImage: "URL(/images/next_match_banner_background.png)",
			backgroundSize: "cover",
			backgroundPosition: "center",


			width: "100%",
			// aspectRatio: "1440 / 480",
			height: isMobile ? "420px" : "480px",
			position: "relative",
			display: "flex",
			flexDirection: "column",
			marginTop: isMobile ? "8px" : "40px",
		}}>
			<div style={{
				backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0.2) 60%,rgba(0, 0, 0, 0.8))",
				zIndex: 0,
				position: "absolute", top: 0, left: 0, right: 0, bottom: 0
			}}/>
			<Grid container item
			      direction="column"
				  alignItems="center"
				  sx={{zIndex: 1, color: "background.paper", height: "100%"}}
			>
				<Grid item sx={{pt: isMobile ? 4 : "60px", height: "100px"}}>
					{competition === ECompetitionType.FIRST_LEAGUE &&
                        <PrvaLigaTelemach width={"50px"} height={"50px"}/>}
					{competition === ECompetitionType.CUP &&
                        <PokalPivovarnaUnion width={"100px"} height={"50px"}/>
					}
				</Grid>
				<Grid item sx={{pt: isMobile ? 1 : 5}}>
					<Typography variant={"podnaslovS"} sx={{textTransform: "capitalize"}}>
						{prettyDate}
					</Typography>
				</Grid>
				<Grid item sx={{pt: 0.5}}>
					<Typography variant={"podnaslovS"}>
						ob {prettyTime}
					</Typography>
				</Grid>
				<Grid item container direction={"row"} justifyContent="center"
				      sx={{pt: 3}}
				      alignItems="center">
					{!isMobile &&
                        <Grid item xs sx={{pl: 3}}>
                            <Typography align={"right"} variant={"h3"}>{homeTeamName}</Typography>
                        </Grid>}
					<Grid item><img src={homeTeamLogoUrl} alt="home team logo" style={{
						width: "100%",
						maxWidth: "120px",
						maxHeight: "120px",
						objectFit: "contain"
					}}/></Grid>
					<Grid item>
						<Typography variant={"podnaslovM"}>vs</Typography>
					</Grid>
					<Grid item>
						<img src={awayTeamLogoUrl} alt="away team logo" style={{
							width: "100%",
							maxWidth: "120px",
							maxHeight: "120px",
							objectFit: "contain"
						}}/>
					</Grid>
					{!isMobile && <Grid item xs sx={{pr: 3}}> <Typography variant={"h3"}>{awayTeamName}</Typography></Grid>}
				</Grid>
				{
					buyTicketLink &&
                    <Grid item sx={{pt: isMobile ? 3 : 5}}>
                        <Button variant={"contained"}
                                sx={{
							        backgroundColor: theme.palette.background.default,
							        borderRadius: "24px",
							        "&:hover": {
								        backgroundColor: "rgba(255,255,255,0.97)",
							        },
						        }}
                                onClick={handleBuyTicketClick}>
                            <Typography variant={"gumbL"}
                                        sx={{
								            color: theme.palette.text.primary,
								            px: 2,
								            py: 0.5
							            }}>
                                Kupi vstopnico
                            </Typography>
                        </Button>
                    </Grid>
				}
			</Grid>
		</div>
	)
}

export default NextMatchBanner
