import {Box, Grid, Typography, useTheme} from "@mui/material";
import {FootballSchoolStaffCard, useIsMobileContext} from "@nitra/nkmb-card-components";
import {FootballSchoolPlayerListFirestoreDocument, FootballSchoolStaffListFirestoreDocument} from "models";
import React from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function FootballSchoolPersonGroup(props: {
	title: string,
	elements: FootballSchoolStaffListFirestoreDocument[] | FootballSchoolPlayerListFirestoreDocument[]
}) {
	const {title, elements} = props;
	const isMobile = useIsMobileContext();
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<>
			<Typography variant={"h5"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{title}</Typography>
			<Grid container sx={{p: 2}} spacing={2} justifyContent={"space-between"}>
				{elements.map((element, index) =>
					<Grid key={index} item
					      xs={6}
					      lg={4}
					      xl={3}
					      sx={{marginRight: index === elements.length - 1 ? "auto" : "unset",}}
					>
						<Box style={{
							maxWidth: isMobile ? "331px" : "288px",
							width: "100%",
							paddingTop: isMobile ? "min(100%, 331px)" : "360px",
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								{/*{"jerseyNumber" in element ?*/}
								{/*	<FootballSchoolPlayerCard player={{*/}
								{/*		...element,*/}
								{/*		imageUrl: isMobile ? element.image331x331url : element.image288x360url,*/}
								{/*	}}/>*/}
								{/*	:*/}
								<FootballSchoolStaffCard staff={{
									...element,
									imageUrl: isMobile ? element.image331x331url : element.image288x360url,
								}}/>
								{/*}*/}
							</Box>
						</Box>
					</Grid>
				)}
			</Grid>
		</>
	)
}
