import {Divider} from "@mui/material";
import {EPlayerSidebarStatType, PlayerScoreStatFirestoreDocument} from "@nitra/nkmb-card-components";

import {ScoreAssistTableCell} from "views/competitions/playerStatSidebar/ScoreAssistTableCell";
import {ScoreAssistTableRow} from "views/competitions/playerStatSidebar/ScoreAssistTableRow";

export function ScoreAssistTableContentRow(props: {
	type: EPlayerSidebarStatType,
	player: PlayerScoreStatFirestoreDocument,
	index: number,
}) {
	const {type, player, index} = props;

	return (
		<>
			<ScoreAssistTableRow>
				<ScoreAssistTableCell text={index + 1}/>
				<ScoreAssistTableCell text={player.name} black={true} wide={true}/>
				<ScoreAssistTableCell text={player.matchesPlayed}/>
				<ScoreAssistTableCell text={player.minutesPlayed}/>
				<ScoreAssistTableCell text={type === EPlayerSidebarStatType.GOAL ? player.goals : player.assists}
				                      black={true}/>
			</ScoreAssistTableRow>
			<Divider/>
		</>
	)
}
