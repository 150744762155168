import React from "react";
import {alpha, FormControl, InputLabel, MenuItem, Select, Typography, useTheme} from "@mui/material";
import {NkmbArrowBasicDown} from "@nitra/nkmb-theme";

export function NkmbDropdown(props: {
	label?: string,
	selected: string | number | null,
	options: { value: string | number, label: string }[],
	onSelect: ((value: string) => void) | ((value: number) => void) | ((value: null) => void),
	isOnDarkBackground?: boolean
}) {
	const {label, selected, options, onSelect, isOnDarkBackground} = props
	const theme = useTheme();

	return (
		<FormControl sx={{m: 0}} variant="standard">
			{label && <InputLabel htmlFor={`dropdown-${label}`}>{label}</InputLabel>}
			<Select
				id={`dropdown-${label}`}
				value={selected}
				disableUnderline={true}
				IconComponent={NkmbArrowBasicDown}
				sx={{
					'.MuiSelect-icon ': {
						color: "#676767",
						height: "18px",
						width: "18px"
					},
				}}
				// @ts-ignore
				onChange={(e) => onSelect(e.target.value)}
				MenuProps={{
					PaperProps: {
						style: {
							minWidth: '250px',
							overflow: 'auto',
							borderRadius: 0,
							backgroundColor: isOnDarkBackground ? theme.palette.text.primary : theme.palette.background.default,
						},
					},
					sx: {
						'*::-webkit-scrollbar': {
							width: '8px',
						},
						'*::-webkit-scrollbar-thumb': {
							backgroundColor: isOnDarkBackground ? theme.palette.background.default : theme.palette.text.primary,
							borderRadius: '4px',

						},
						'*::-webkit-scrollbar-track': {
							backgroundColor: alpha(isOnDarkBackground ? theme.palette.background.default : theme.palette.text.primary, 0.2),
						},
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					// getContentAnchorEl: null,
				}}

			>
				{options.map((option, index) =>
					<MenuItem value={option.value} key={index}>
						<Typography variant={"gumbS"} sx={{
							pl: 1,
							color:
								isOnDarkBackground ?
									alpha(theme.palette.background.default, 0.5) :
									alpha(theme.palette.text.primary, 0.5),

						}}>
							{option.label}
						</Typography>
					</MenuItem>
				)}
			</Select>
		</FormControl>
	)
}