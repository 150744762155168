import {Box, Stack, Typography, useTheme} from "@mui/material";
import {LargeImageCard, useBreakpointContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import React from "react";
import {VipButton} from "./VipButton";
import {VipTexts} from "./VipTexts";

export function VipCommonDesktop() {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const breakpoint = useBreakpointContext();

	return (
		<>
			{/* Če se odtrani minHeight in backgroundSize se slika ne resiza in
				se lahko potem statično nastavijo puščice in gumbi, zgleda samo ko da se zooma noter na sliko skos */}
			<Box sx={{
				backgroundImage: "url('/images/hospitality/hospitalityBackground.png')",
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				height: "1987px",
				width: '100%', // Set the width to cover the full viewport
				maxWidth: "1440px"
			}}>
				<Typography
					variant={"h1"}
					sx={{
						pt: "725px",
						textAlign: "center",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						color: theme.palette.background.default
					}}
				>
					Ljudski vrt 5*
				</Typography>
				<Stack direction={breakpoint === "xl" ? "row" : "column"}
				       sx={{pt: "175px", alignItems: breakpoint === "xl" ? "stretch" : "center", px: 2}}
				       justifyContent={"center"}
				       spacing={6}>
					<LargeImageCard
						title={VipTexts.basic.vps.imageTitle}
						imageUrl={VipTexts.basic.vps.imageUrl}
						to={VipTexts.basic.vps.link}/>
					<Stack sx={{
						maxWidth: "600px",
						flexGrow: 1,
						color: theme.palette.background.paper,
						display: "flex",
					}}>
						<Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>{VipTexts.basic.vps.textTitle}</Typography>
						<Typography
							variant={"body1"}
							align={"justify"}
							sx={{
								pt: 2,
							}}
						>{VipTexts.basic.vps.textBody}</Typography>
						<VipButton
							label={VipTexts.basic.vps.buttonLabel}
							sx={{
								mt: "auto",
								mr: "auto",
							}}
							to={VipTexts.basic.vps.link}
						/>
					</Stack>
				</Stack>
				<Stack direction={breakpoint === "xl" ? "row" : "column"}
				       sx={{pt: 8, alignItems: breakpoint === "xl" ? "stretch" : "center", px: 2}}
				       justifyContent={"center"}
				       spacing={6}>
					<LargeImageCard
						title={VipTexts.basic.skybox.imageTitle}
						imageUrl={VipTexts.basic.skybox.imageUrl}
						to={VipTexts.basic.skybox.link}/>
					<Stack sx={{
						maxWidth: "600px",
						flexGrow: 1,
						color: theme.palette.background.paper,
					}}>
						<Typography variant={"h3"} sx={{
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>{VipTexts.basic.skybox.textTitle}</Typography>
						<Typography
							variant={"body1"}
							align={"justify"}
							sx={{
								pt: 2,
							}}
						>{VipTexts.basic.skybox.textBody}</Typography>
						<VipButton
							label={VipTexts.basic.skybox.buttonLabel}
							sx={{
								mt: "auto",
								mr: "auto",
							}}
							to={VipTexts.basic.skybox.link}
						/>
					</Stack>
				</Stack>
				{/*}*/}
			</Box>
			<Box sx={{
				height: {
					xs: 0,
					sm: "200px",
					md: "300px",
					lg: 0,
					xl: 0
				}
			}}>

			</Box>
		</>
	)
}