import {IconButton, SvgIcon, useTheme} from "@mui/material";
import {NkmbIconFb, NkmbIconIg, NkmbIconTikTok, NkmbIconTwitter, NkmbIconYt} from "@nitra/nkmb-theme";
import React from "react";


function SocialIcon(props: { component: React.FC, onClick?: () => void }) {
	const theme = useTheme()

	return (
		<IconButton onClick={props.onClick}>
			<SvgIcon
				component={props.component}
				sx={{width: 32, height: 32, color: theme.palette.text.primary}}
			/>
		</IconButton>
	);
}

export function Facebook() {
	return (
		<SocialIcon
			component={NkmbIconFb}
			onClick={() => window.open("https://www.facebook.com/nkmaribor", "_blank")}
		/>
	);
}

export function Instagram() {
	return (
		<SocialIcon
			component={NkmbIconIg}
			onClick={() => window.open("https://www.instagram.com/nkmaribor/", "_blank")}
		/>
	);
}

export function Twitter() {
	return (
		<SocialIcon
			component={NkmbIconTwitter}
			onClick={() => window.open("https://twitter.com/nkmaribor", "_blank")}
		/>
	);
}

export function Youtube() {
	return (
		<SocialIcon
			component={NkmbIconYt}
			onClick={() => window.open("https://www.youtube.com/@nkmaribor4696", "_blank")}
		/>
	);
}

export function TikTok() {
	return (
		<SocialIcon
			component={NkmbIconTikTok}
			onClick={() => window.open("https://www.tiktok.com/@nkmaribor1960", "_blank")}
		/>
	)
}
