import {Stack} from "@mui/material";
import {EPlayerSidebarStatType} from "@nitra/nkmb-card-components";
import React from "react";
import {SideCardWithPlayerStats} from "views/competitions/playerStatSidebar/SideCardWithPlayerStats";
import {useCompetitionViewContext} from "./CompetitionContext";

/**
 * Right stack for competitions desktop view, displaying shooters and assists
 * @constructor
 */
export function CompetitionsRightStack() {
	const {season} = useCompetitionViewContext()

	return (
		<Stack
			direction={"column"}
			spacing={3}
			sx={{
				pt: 8
			}}
		>
			<SideCardWithPlayerStats type={EPlayerSidebarStatType.GOAL}/>
			{
				(parseInt(season.split("/")[0]) > 2006) &&
                <SideCardWithPlayerStats type={EPlayerSidebarStatType.ASSIST}/>
			}
		</Stack>
	)
}
