import {alpha, Box, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {CardFooter, CardOverImageTitle, CardWithShadow} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NkmbIconFax, NkmbIconLokacija, NkmbIconMail, NkmbIconTel} from "@nitra/nkmb-theme";
import React from "react";
import {IconAndTextLineDesktop} from "./IconAndTextLineDesktop";
import {OpeningTimeDesktopBox} from "./OpeningTimeDesktopBox";

export function MoreContactMobile() {
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Stack sx={{pt: 5}}>
			<Typography variant={"h5"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>NOGOMETNI KLUB MARIBOR</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"info@nkmaribor.com"}/>
			<IconAndTextLineDesktop icon={NkmbIconLokacija}
			                        text={"Mladinska ulica 29, 2000 Maribor"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 00"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 11"}/>
			<IconAndTextLineDesktop icon={NkmbIconFax} text={"02 / 228 47 01"}/>
			<Box sx={{
				width: "90%",
				backgroundColor: alpha("#676767", 0.2),
				my: 3,
				height: "1px",
			}}/>
			<Typography variant={"h5"} sx={{
				pb: 2,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>VIJOL'ČNA BAJTA</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"trgovina@nkmaribor.com"}/>
			<Typography sx={{pb: 1, pt: 2}} variant={"podnaslovS"}>Mercator center Tabor II</Typography>
			<IconAndTextLineDesktop icon={NkmbIconLokacija} text={"Ulica Eve Lovše 1"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 66"}/>
			<OpeningTimeDesktopBox days={[
				"PON - SOB",
				"NEDELJA, PRAZNIKI"
			]} times={[
				"8:00 - 20:00",
				"ZAPRTO"
			]}/>
			<Typography sx={{pb: 1, pt: 2}} variant={"podnaslovS"}>Center mesta</Typography>
			<IconAndTextLineDesktop icon={NkmbIconLokacija} text={"Tyrševa ulica 1"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 86 96"}/>
			<OpeningTimeDesktopBox days={[
				"PON - PET",
				"SOB",
				"NED, PRAZNIKI"
			]} times={[
				"9:00 - 19:00",
				"9:00 - 14:00",
				"ZAPRTO"
			]}/>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column", position: "relative", width: "calc(100%-32px)", mt: 2}}
				to={"https://www.vijolcnabajta.si/si.html"}
				cardHeightOverride={230}
				fullSizeImageUrl={"/images/more/contact/bajta.png"}
			>
				<CardContent sx={{zIndex: 1, mt: "auto"}}>
					<CardOverImageTitle title={"Vijol'čna bajta"} variant={"podnaslovM"}/>
				</CardContent>
				<CardFooter brightText={true} shareUrl={"https://www.vijolcnabajta.si/si.html"}/>

			</CardWithShadow>
			<Box sx={{
				width: "90%",
				backgroundColor: alpha("#676767", 0.2),
				my: 3,
				height: "1px",
			}}/>
			<Typography variant={"h5"} sx={{
				pb: 1,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>FRIZERSKA BAJTA</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"salon@nkmaribor.com"}/>
			<IconAndTextLineDesktop icon={NkmbIconLokacija}
			                        text={"Mercator center Tabor II, Ulica Eve Lovše 1"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 67"}/>
			<Stack sx={{flexGrow: 1}}>
				<OpeningTimeDesktopBox days={[
					"PON - SOB",
					"NED, PRAZNIKI"
				]} times={[
					"8:00 - 20:00",
					"ZAPRTO"
				]}/>
			</Stack>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column", position: "relative", width: "calc(100%-32px)", mt: 2}}

				cardHeightOverride={230}
				to={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}
				fullSizeImageUrl={"/images/more/contact/frizer.png"}
			>
				<CardContent sx={{zIndex: 1, mt: "auto"}}>
					<CardOverImageTitle title={"Frizerska bajta"} variant={"podnaslovM"}/>
				</CardContent>
				<CardFooter brightText={true} shareUrl={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}/>
			</CardWithShadow>
		</Stack>
	);
}