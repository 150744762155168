import {ArticleMatchMetaDataFirestoreDocument} from "../../../../models";
import {Box, Stack, useTheme} from "@mui/material";
import {ArticleMatchStripTeamLineup} from "./ArticleMatchStripTeamLineup";

export function ArticleMatchStripBothTeamLineup(props: {
	matchMetaData: ArticleMatchMetaDataFirestoreDocument,
	isFirstEleven: boolean
}) {
	const {matchMetaData, isFirstEleven} = props;
	const theme = useTheme();
	return (
		<Stack direction={"row"} sx={{
			width: "100%",
			backgroundColor: theme.palette.text.primary,
			color: theme.palette.background.paper
		}}
		>
			<ArticleMatchStripTeamLineup
				players={matchMetaData.homeTeamLineup}
				teamName={matchMetaData.homeTeamName}
				isFirstEleven={isFirstEleven}
				isHome={true}/>
			<Box sx={{width: "24px"}}/>
			<ArticleMatchStripTeamLineup
				players={matchMetaData.awayTeamLineup}
				teamName={matchMetaData.awayTeamName}
				isFirstEleven={isFirstEleven}
				isHome={false}
			/>
		</Stack>
	)
}