import React from "react";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {VipCommonMobile} from "./VipCommonMobile";
import {VipCommonDesktop} from "./VipCommonDesktop";

function VipCommon() {
	const isMobile = useIsMobileContext()

	return isMobile ? <VipCommonMobile/> : <VipCommonDesktop/>
}

export default VipCommon

