import {NationFlagRound} from "@nitra/nkmb-theme";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBannerNationalFlagMobile(props: {
	forceRight?: boolean
}) {
	const {forceRight} = props
	const player = usePlayerDetailsContext()

	return (
		<NationFlagRound
			countryCode={player!.nationality}
			sx={{
				position: "absolute",
				bottom: "0px",
				...(forceRight ? {
					right: 0,
					mb: -3
				} : {
					left: 0,
				})
			}}
			svgProps={{
				height: "100px",
				width: "100px",
			}}
		/>
	)
}
